import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  Input,
  QueryList,
  ViewChildren,
  AfterViewInit,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AppComponent } from "../../../../app.component";
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";
import { Inject } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
// import * as CryptoJS from 'crypto-js';
// import { EmitType } from '@syncfusion/ej2-base';
import {
  menuSection,
  redeem,
  itemDetails,
  variantDetails,
  cartItems,
  orderDetails,
  deliveryAddress,
  newOrderDetails,
  templateItemDetails,
  templateVariantDetails,
  templateCartItems,
  openItems,
} from "./pos.model";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
// import { BehaviorSubject } from "rxjs";
import { Query } from '@syncfusion/ej2-data';
import { EmitType } from '@syncfusion/ej2-base';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
// import { LayoutConfigService } from "../../../../core/services/layout-config.service";
// import { ClassInitService } from "../../../../core/services/class-init.service";
import { FilterPipe } from "ngx-filter-pipe";
import { formatDate, Location } from '@angular/common';
import Swal from "sweetalert2";
import { LayoutRefService } from "src/app/core/_base/layout";
import { DomSanitizer } from "@angular/platform-browser";
import { SetStorageServiceService } from "src/app/core/_base/layout/services/set-storage-service.service";
import { ThrowStmt } from "@angular/compiler";
import { ToastrService } from "ngx-toastr";
import { cpuUsage } from "process";
import { DropDownListComponent } from "@syncfusion/ej2-angular-dropdowns";
// import fi_FI from "dist/assets/plugins/formvalidation/src/js/locales/fi_FI";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { Mode, Transaction } from "../../advance-payments/advance-payments";
import { AdvancePaymentsService } from "../../advance-payments/advance-payments.service";
import { ApiService } from "src/app/api.service";
import { event } from "jquery";
// import { GetWeightServiceService } from "../../../../get-weight-service.service";
// import { ChildProcessService } from "ngx-childprocess";

// import {PagesComponent} from '../../pages.component';
// import { setContextDirty } from '@angular/core/src/render3/styling';

var process: any;
@Component({
  selector: "m-pos",
  templateUrl: "./pos.component.html",
  styleUrls: ["./pos.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class POSComponent implements OnInit {
  @ViewChildren('div') divs: QueryList<ElementRef>
  @ViewChild('ktHeader', { static: true }) ktHeader: ElementRef;
  @ViewChild('ddlelement')
  public dropDownListObject: DropDownListComponent;
  menu: any = [];
  electron: boolean = false;
  packageItems: any = [];
  serviceCharges: number = 0;
  templates: any = [];
  onCall: number = 0;
  public onlineOffline: boolean = navigator.onLine;
  alertMessage: String = "";
  totalAmount: number = 0;
  totalTax: number = 0;
  cartIndex: number = 0;
  clicked = 0
  invoiceMax = formatDate(new Date(), 'yyyy-MM-ddThh:mm', 'en');
  draftOrders = [];
  errorMessage: String = "";
  selectedItem2 = {};
  finalArr: any[]
  specialInstruction = [];
  addStaffShow: boolean = false;
  noOfOrderMarkComplimentary: Number = 0;
  searchText = ''
  sections: menuSection = new menuSection();
  sectionArray: Array<menuSection> = new Array<menuSection>();
  itemDetail: itemDetails = new itemDetails();
  showCustomizations: boolean[] = [];
  openItems: openItems = new openItems();
  templateItemDetail: templateItemDetails = new templateItemDetails();
  orders: orderDetails = new orderDetails();
  ordersArray: Array<orderDetails> = new Array<orderDetails>();
  variantDetail: variantDetails = new variantDetails();
  templateVariantDetail: templateVariantDetails = new templateVariantDetails();
  cart: cartItems = new cartItems();
  templateCart: templateCartItems = new templateCartItems();
  cartList: Array<cartItems> = new Array<cartItems>();
  newOrder: newOrderDetails = new newOrderDetails();
  discountAlertMessage: String = "";
  parentId: number = 0;
  payment = 0
  discLimit = 0
  paymentType = ''
  ifPackingChargesApplicable = 0;
  discountApplicableOn = 3;
  itemParentDetailArray: Array<itemDetails> = new Array<itemDetails>();
  templateItemParentDetailArray: Array<templateItemDetails> = new Array<
    templateItemDetails
  >();
  deliveryAdd: deliveryAddress = new deliveryAddress();
  deliveryAddArray: Array<deliveryAddress> = new Array<deliveryAddress>();
  orderDate: String = "";
  memUsed = false
  thirdPartyData = []
  redeem: redeem = new redeem();
  redeemArr: Array<redeem> = new Array<redeem>();
  sectionFilter: any = { sectionName: "" };
  itemsFilter: any = { itemName: "" };
  channels = [];
  supportStaff = [];
  selectedItem = [];
  quantityModel: Number = 0;
  selectedItemTax = "";
  selectedItemDetails = {};
  existingOrderArray = [];
  category_id = 0;
  maxDiscountAllowed = 0;
  selectedItemIndex = "";
  cartIndexArray = [];
  showDineIn = 0;
  thirdPartyType = ''
  showTakeAway = 0;
  parentBusinessId: number = 0;
  showDelivery = 0;
  openItemVisible = 0;
  partialAmount: any
  getWeightLoader = false;
  filterPlaceholder = 'Search'
  watermark = 'Select Staff'
  text: string = "Select Service/Product";
  textpos: string = "Select Item";
  paymenttext: string = "Select Payment Type";
  textQty: string = "Qty";
  disctext = 'Select Discount Remark';
  paymentList = []
  customtext = 'Select'
  paymentData: { [key: string]: Object; }[] = [];
  public paymentFields: Object = { text: 'paymentType', value: 'id' };
  customData: { [key: string]: Object; }[] = [];
  public customFields: Object = { text: 'customName', value: 'id' };
  public fields: Object = { groupBy: 'Category', text: 'item', value: 'itemId' };
  // public query: Query = new Query().from('Customers').select(['ContactName', 'CustomerID']).take(7);
  public stylistfield: Object = { text: 'stylistName', value: 'id' };
  public discfield: Object = { text: 'discName', value: 'discName' };
  // public query: Query;
  public popoverTitle: string = "Delete Order";
  public popoverMessage: string = "Do you want to delete the order?";
  source = "";
  stylistName = ''
  sourceId: number = 0;
  showOrderType = "false";
  @Input() selfLayout: any = "blank";
  @Input() asideLeftDisplay: any;
  @Input() asideRightDisplay: any;
  @Input() asideLeftCloseClass: any;
  // class for the header container
  pageBodyClass$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  userName: any;
  flag: number;
  brandsData: any;
  changeHeight = 0;
  id: any;
  arr: any[];
  stylistData: any;
  showMem = 0;
  membershipData: any;
  walletLeft: number;
  amountPaid: number;
  sectionShow: number;
  membershipUsed = 0;
  avatarBg: any;
  avatar: string;
  businessName: any;
  locality: any;
  city: any;
  layoutConfigService: any;
  mobileMandatory: number;
  orderData: any;
  showPrint: number;
  amountLeft: number;
  moduleName = "";
  moduleIcon = "";
  disableMode: number;
  elementType = 'image';
  orderId: any;
  oId: any;
  staffdata: { [key: string]: Object; }[] = [];
  menuData: { [key: string]: Object; }[] = [];
  qtyData: Object[] = [
    { Id: 1, Name: '1' },
    { Id: 2, Name: '2' },
    { Id: 3, Name: '3' },
    { Id: 4, Name: '4' },
    { Id: 5, Name: '5' },
    { Id: 6, Name: '6' },
    { Id: 7, Name: '7' },
    { Id: 8, Name: '8' },
    { Id: 9, Name: '9' },
    { Id: 10, Name: '10' }
  ];
  public fieldsDis: Object = { text: 'Name', value: 'Id' };
  discData: any[];
  width = '300%'
  widthQty = '50%'
  itemarr: any[];
  productData: any;
  unit: any;
  discountData: any;
  quantityFlow: number;
  disIndex: any;
  disData: any[];
  selectedItemCart: cartItems;
  paymentModeSelect = Array<string>();
  pMode: string = '';
  adjustPayment: any;
  paymentOption: any[];
  paymentMode = ['Cash', 'Card', 'UPI', 'Third Party', 'Membership']
  pendingAmount: number;
  packageData: any[];
  packageDetailsArr: any[];
  plength: number;
  showThirdParty: number;
  paymentFlag: boolean = false;
  taxFlag: boolean = true;
  itemTaxFlag: boolean = true
  isIncluseTax: boolean = false;
  loyaltyFlag: boolean = false;
  partialPayments: any;
  pendingPayoutAmount: any;
  showPartial: number;
  splitAmountLeft: number = 0;
  sameAmount: number;
  pastOrderData = [];
  customAmount: number;
  customIndex: any;
  customDetail: any;
  customList: any;
  customerId: any;
  customName: any;
  otp: string;
  splitArea: number;
  detailForm: FormGroup
  submitted1 = false
  customernumberData = [];
  customerData = [];
  jobCard: any[];
  loyaltyCard = [];
  businessId: any;
  qrString: string;
  selectedBusiness: any;
  sectionIndex: any;
  addedItem: any[];
  notShowDraftId: number;
  menuTemplates: any[];
  itemTemplates: any[];
  variantTemplates: any[];
  variantIndex = -1;
  withVariant: number;
  gstNum: string;
  gstFlag: boolean;
  totalQty: number;
  customerName = ''
  customerNumber = ''
  loyaltyRules: any;
  pointFlag: boolean;
  promoData: any;
  promoId = '';
  promoDetails = [];
  cardId = '';
  totalDiscount: number = 0;
  pendingAmountLeft: number;
  designType: any
  customIndex2: any;
  customWorkerName: any;
  customWorkerId: any;
  customPackingMappingId: number;
  selectedStaff = '';
  lto: any;
  ltv: any;
  lastorder: any;
  notes: any;
  note: string;
  voidOrderId: any;
  advancePaymentDetails: string;
  advancePaymentForm: FormGroup;
  paymentTypeList: Mode[] = [{ id: 'cash', name: 'Cash' }, { id: 'upi', name: 'UPI' }, { id: 'card', name: 'Card' }]
  dropdownSingleSettings = {
    singleSelection: true,
    idField: "id",
    textField: "name",
    enableCheckAll: false,
    allowSearchFilter: true,
  };
  isPrint: boolean = false;
  deviceType: string;
  isloading: boolean = false;
  tipErrorMsg: string = "";
  isOtpValid: boolean = false;
  memOtp: string = '';
  membershipOtpRedemtion = 0;
  payoutId: any;
  settleAmount: any;
  settleVia: string;

  // customData: any;
  // roundDiff: number;
  constructor(
    private filterPipe: FilterPipe,
    // private configService: LayoutConfigService,
    private router: Router,
    //private page: PagesComponent,
    private appComp: AppComponent,
    public httpClient: HttpClient,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private layoutRefService: LayoutRefService,
    private modalRef: NgbActiveModal,
    private activateRoute: ActivatedRoute,
    // public classInitService: ClassInitService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private sanitizer: DomSanitizer,
    private location: Location,
    private toastr: ToastrService,
    private _storageService: SetStorageServiceService,
    public fb: FormBuilder,
    private advancePaymentService: AdvancePaymentsService,
    private apiService: ApiService,
    // private childProcessService: ChildProcessService,
    // private getWeightServiceService: GetWeightServiceService,
    @ViewChild("closeVendorModal") closeAddExpenseModal: ElementRef
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.createAdvancePayForm()
  }
  public onFiltering: EmitType<any> = (e: FilteringEventArgs) => {
    let query = new Query();
    //frame the query based on search string with filter type.
    query = (e.text != "") ? query.where("item", "contains", e.text, true) : query;
    //pass the filter data source, filter query to updateData method.
    e.updateData(this.menuData, query);
  };
  // ngOnInit() {
  //     this.data=this.apiService.getUsers();
  //   }
  get mName() {
    // let tempName = this._storageService.localItem
    //   ? this._storageService.localItem[0]["title"]
    //   : "";
    // return (this.moduleName = tempName);
    let tempName =
      this._storageService.localItem != undefined &&
        this._storageService.localItem.length > 0
        ? this._storageService.localItem[0]["title"]
        : "";
    return (this.moduleName = tempName);
  }
  get mIcon() {
    // let tempName = this._storageService.localItem
    //   ? this._storageService.localItem[0]["icon"]
    //   : "";
    // return (this.moduleIcon = tempName);

    let tempName =
      this._storageService.localItem != undefined &&
        this._storageService.localItem.length > 0
        ? this._storageService.localItem[0]["icon"]
        : "";
    return (this.moduleIcon = tempName);

  }

  ngOnInit() {
    this.userName = this.storage.get("userName");
    this.id = this.storage.get("selectedBusinessId");
    this.businessId = this.storage.get("selectedBusinessId");
    // this.cd.detectChanges()
    // console.log(this.id)
    this.cd.detectChanges();
    this.selectedBusiness = this.storage
      .get("AssignedBusiness")
      .filter((e) => e.businessId == this.storage.get("selectedBusinessId"));
    this.avatar =
      "https://api.uengage.in/images/addo/logos/" + this.selectedBusiness[0].logo;
    this.businessName = this.selectedBusiness[0].name;
    this.locality = this.selectedBusiness[0].locality;
    this.city = this.selectedBusiness[0].city;
    let flag = this.selectedBusiness[0].inventory_mapping
    // this.parentBusinessId = selectedBusiness[0]["parentId"];
    if (flag == 0 || flag == undefined) {
      this.quantityFlow = 0
    } else {
      this.quantityFlow = 1
    }
    window.scroll(0, 0);
    if (
      typeof navigator === "object" &&
      typeof navigator.userAgent === "string" &&
      navigator.userAgent.indexOf("Electron") >= 0
    ) {
      this.electron = true;
    } else {
      this.electron = false;
    }
    //  console.log(this.maxDiscountAllowed)
    let orderId = 0;
    let selectedBusinessDetails = this.storage
      .get("AssignedBusiness")
      .filter((e) => e.businessId == this.storage.get("selectedBusinessId"));
    let orderFrom = "";
    this.activateRoute.params.subscribe((params) => {
      orderId = params["orderId"];
      this.notShowDraftId = params["orderId"];
      orderFrom = params["orderFrom"];
    });
    this.viewDraftOrders2()
    this.oId = orderId;

    if (orderFrom == "call") {
      this.onCall = 1;
    }
    this.category_id = selectedBusinessDetails[0]["category_id"];
    // console.log(this.category_id)
    this.parentBusinessId = selectedBusinessDetails[0]["parentId"];
    // console.log(this.parentBusinessId)
    this.serviceCharges = selectedBusinessDetails[0]["serviceCharges"];
    if (this.category_id == 4) {
      // this.taxFlag = this.storage.get("tax_flag")
      // this.paymentType = this.storage.get("mem_flag") 
      // console.log(this.paymentType)
      // this.paymentFlag = this.storage.get("mem_flag") 
      this.getPosType()
      this.createDetailForm()
      this.paymentData = [
        { id: "cash", paymentType: "Cash" },
        { id: "card", paymentType: "Card" },
        { id: "upi", paymentType: "UPI" },
        { id: "third_party", paymentType: "Third Party" },
      ]
      this.paymentOption = []
      for (let i = 0; i < this.paymentMode.length; i++) {
        this.paymentOption.push({
          id: i,
          name: this.paymentMode[i],
          checked: false,
          amount: '',
          memAmount: ''
        })
      }
      this.cd.detectChanges()
      // console.log(this.paymentOption)
      this.getStylistData()
      this.getProducts()
      this.getRemarks()
      this.getCustomisation()
      // this.getCustomerId()
    }
    // this.paymentType = 'cash'
    if (this.onlineOffline) {
      this.getChannelList();
    } else {
      if (this.storage.get("channels")) {
        this.channels = this.storage.get("channels");
        let channels = this.channels.filter(
          (e) => e.channelId == this.orders.sourceId
        );
        this.openItemVisible = channels["openItems"];
      }
    }
    this.getMenu();
    //  console.log(this.parentBusinessId)
    if (this.parentBusinessId == 0) {
      this.changeHeight = 1;
      let params =
        "userId=" +
        this.storage.get("userId") +
        "&password=" +
        this.storage.get("password") +
        "&businessId=" +
        this.storage.get("selectedBusinessId");
      this.httpClient
        .get("https://api.uengage.in/pos/getPosBusiness?" + params, {})
        .subscribe((res: any) => {
          this.brandsData = res.list;
          this.showSection(this.brandsData[0])
          // this.scrollToSection(0)
          // console.log(this.brandsData)
          this.cd.detectChanges()
        });
    }
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
      // Take the user to a different screen here.
      this.deviceType = 'mobile';
    } else {
      this.deviceType = 'web';
      // if (navigator.userAgent.toLowerCase().indexOf('safari/') > -1) {
      //   this.deviceType = 'mobile';
      // }
    }
    // console.log(this.orders.cartDetails)
  }


  checkMembershipRedemtion() {
    // console.log(this.orders["otp_validated"])
    let params = new HttpParams({
      fromObject: {
        userId: this.storage.get("userId"),
        password: this.storage.get("password"),
        business_id: this.storage.get("selectedBusinessId"),
      }
    });
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };

    let url = "https://crm.uengage.in/crm_api/check/membership/redemption";

    this.httpClient.post(url, params, httpOptions).subscribe(
      (res: any) => {
        if (res.status) {
          this.membershipOtpRedemtion = res.membership_otp_redemtion;
          // console.log(this.membershipOtpRedemtion)
        }
      }
    );
  }
  sendOtp() {

    let params = new HttpParams({
      fromObject: {
        userId: this.storage.get("userId"),
        password: this.storage.get("password"),
        business_id: this.storage.get("selectedBusinessId"),
        order_id: this.orders.orderId.toString(),
        customer_name: this.orders.customerName.toString(),
        customer_number: this.orders.mobileNo.toString(),
        parent_business_id: this.parentBusinessId.toString(),
      }
    });

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };
    let url = "https://crm.uengage.in/crm_api/send/otp";

    this.httpClient.post(url, params, httpOptions).subscribe(
      (res: any) => {
      }
    );
  }

  validateOpt() {
    console.log(this.orders["otp_validated"])
    let params = new HttpParams({
      fromObject: {
        userId: this.storage.get("userId"),
        password: this.storage.get("password"),
        business_id: this.storage.get("selectedBusinessId"),
        order_id: this.orders.orderId.toString(),
        customer_number: this.orders.mobileNo.toString(),
        otp: this.memOtp
      }
    });

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };
    let url = "https://crm.uengage.in/crm_api/validate/otp";

    this.httpClient.post(url, params, httpOptions).subscribe(
      (res: any) => {
        if (res.status) {
          Swal.fire('', res.msg, "success")
          this.isOtpValid = true;
          // this.membershipOtpRedemtion = 0;
          this.orders["otp_validated"] = 1;
          let orderIndex = this.ordersArray.findIndex(
            (e) => e.orderId == this.orders.orderId
          );
          if (orderIndex > -1) {
            this.ordersArray[orderIndex] = this.orders;
          } else {
            this.ordersArray.push(this.orders);
          }
          this.storage.set("existingOrders", this.ordersArray);
          this.memUsed = false
          this.cd.detectChanges();
          this.modalRef.close();
        } else {
          Swal.fire('', res.msg, 'error')
        }
      },
      (error) => {
      }
    );
  }


  getLoyalty() {
    this.loyaltyRules = []
    let params = new HttpParams({
      fromObject: {
        userId: this.storage.get("userId"),
        password: this.storage.get("password"),
        business_id: this.storage.get("selectedBusinessId"),
        parent_business_id: this.parentBusinessId.toString(),
        mobileNo: this.orders.mobileNo.toString()
      }
    });
    let httpOptions = {
      headers: new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded")
    };
    let url = "https://crm.uengage.in/crm_api/get/wallet/points"
    this.httpClient.post(url, params, httpOptions).subscribe((res: any) => {
      if (res.status) {
        this.loyaltyRules = res.data
        this.cd.detectChanges()
        // console.log(this.loyaltyRules)
      } else {
        this.loyaltyRules = []
      }
    });
  }
  getRemarks() {
    this.discData = []
    let params =
      "userId=" +
      this.storage.get("userId") +
      "&password=" +
      this.storage.get("password") +
      "&businessId=" +
      this.storage.get("selectedBusinessId");
    this.httpClient
      .get("https://api.uengage.in/pos/discount_source?" + params, {})
      .subscribe((res: any) => {
        if (res.status == 1) {
          this.discountData = res.discount_source
          for (let i = 0; i < this.discountData.length; i++) {
            this.discData.push({
              discName: this.discountData[i].name,
            })
          }
          //  console.log(this.staffdata)
          this.cd.detectChanges()
        } else if (res.status == 0) {
          Swal.fire('', res.msg, 'error')
          return false;
        }
      });
  }
  getLogo() {
    let result = 'logo-light.png';
    const headerSelfTheme = this.layoutConfigService.getConfig('header.self.theme') || '';
    if (headerSelfTheme === 'light') {
      result = 'logo-dark.png';
    } else {
      if (headerSelfTheme === 'dark') {
        result = 'logo-light.png';
      }
    }
    return `./assets/media/logos/${result}`;
  }
  listClick(newValue, index) {
    this.sectionIndex = index
    this.selectedItem = []
    // console.log(newValue)
    for (let i = 0; i < newValue.items.length; i++) {
      if (newValue.items[i].variants == undefined) {
        this.selectedItem.push({
          id: newValue.items[i].id,
          name: newValue.items[i].itemName,
          tax: newValue.items[i].tax,
          price: newValue.items[i].sp_without_taxes,
          qty: 0,
          vegNonvegBoth: newValue.items[i].vegNonvegBoth,
          viewType: newValue.items[i].viewType,
          wizardSteps: newValue.items[i].wizardSteps,
          templateId: newValue.items[i].templateId,
          templates: newValue.items[i].templates
        })
      } else {
        for (let j = 0; j < newValue.items[i].variants.length; j++) {
          this.selectedItem.push({
            id: newValue.items[i].variants[j].id,
            name: newValue.items[i].variants[j].itemName,
            tax: newValue.items[i].variants[j].tax,
            price: newValue.items[i].variants[j].sp_without_taxes,
            qty: 0,
            vegNonvegBoth: newValue.items[i].variants[j].vegNonvegBoth,
            viewType: newValue.items[i].variants[j].viewType,
            wizardSteps: newValue.items[i].variants[j].wizardSteps,
            templateId: newValue.items[i].variants[j].templateId,
            templates: newValue.items[i].variants[j].templates,
            variant: newValue.items[i].variants[j].variants,
          })
        }
      }
    }
    if (this.cartList.length != 0) {
      for (let i = 0; i < this.cartList.length; i++) {
        for (let j = 0; j < this.selectedItem.length; j++) {
          if (this.selectedItem[j].id == this.cartList[i].itemId) {
            this.selectedItem[j].qty = this.cartList[i].quantity
          }
        }
      }
    }
    this.cd.detectChanges()
    // console.log(this.selectedItem)
  }
  decQty(data, i) {
    this.selectedItem[i].qty = this.selectedItem[i].qty - 1
    if (this.cartList.length == 0 || this.cartList == undefined || this.cartList == null) {
      this.cart.itemId = this.selectedItem[i].id;
      this.cart.cost = 0;
      this.cart.quantity = this.selectedItem[i].qty;
      this.cart.itemName = this.selectedItem[i].name;
      this.cart.item = '';
      this.cart.costPerItem = this.selectedItem[i].price;
      this.cart.tax = this.selectedItem[i].tax;
      this.cart.exciseApplicable = 0;
      this.cart.taxPer = 0;
      this.cart.type = 0;
      this.cart.workerName = '';
      this.cart.discount = 0;
      this.cart.specialInstruction = '';
      this.cart.bId = this.id;
      this.cartList.push(this.cart);
      this.orders.cartDetails.push(this.cart);
    } else {
      let index = this.cartList.findIndex(e => e.itemId == this.selectedItem[i].id)
      if (index > -1) {
        this.cartList[index].quantity = this.selectedItem[i].qty
      } else {
        this.cart.itemId = this.selectedItem[i].id;
        this.cart.cost = 0;
        this.cart.quantity = this.selectedItem[i].qty;
        this.cart.itemName = this.selectedItem[i].name;
        this.cart.item = '';
        this.cart.costPerItem = this.selectedItem[i].price;
        this.cart.tax = this.selectedItem[i].tax;
        this.cart.exciseApplicable = 0;
        this.cart.taxPer = 0;
        this.cart.bId = this.id;
        this.cart.type = 0;
        this.cart.workerName = '';
        this.cart.discount = 0;
        this.cart.specialInstruction = '';
        this.cartList.push(this.cart);
        this.orders.cartDetails.push(this.cart);
      }
    }
    this.cd.detectChanges()
    this.updatePrice()
    // console.log(this.cartList)
  }
  setQty(data, i) {
    // console.log(this.selectedItem[i])
    if (this.cartList.length == 0 || this.cartList == undefined || this.cartList == null) {
      this.cart.itemId = this.selectedItem[i].id;
      this.cart.cost = 0;
      this.cart.quantity = this.selectedItem[i].qty;
      this.cart.itemName = this.selectedItem[i].name;
      this.cart.item = '';
      this.cart.costPerItem = this.selectedItem[i].price;
      this.cart.tax = this.selectedItem[i].tax;
      this.cart.exciseApplicable = 0;
      this.cart.taxPer = 0;
      this.cart.bId = this.id;
      this.cart.type = 0;
      this.cart.workerName = '';
      this.cart.discount = 0;
      this.cart.specialInstruction = '';
      this.cartList.push(this.cart);
      this.orders.cartDetails.push(this.cart);
    } else {
      let index = this.cartList.findIndex(e => e.itemId == this.selectedItem[i].id)
      if (index > -1) {
        this.cartList[index].quantity = this.selectedItem[i].qty
      } else {
        this.cart.itemId = this.selectedItem[i].id;
        this.cart.cost = 0;
        this.cart.quantity = this.selectedItem[i].qty;
        this.cart.itemName = this.selectedItem[i].name;
        this.cart.item = '';
        this.cart.costPerItem = this.selectedItem[i].price;
        this.cart.tax = this.selectedItem[i].tax;
        this.cart.exciseApplicable = 0;
        this.cart.taxPer = 0;
        this.cart.type = 0;
        this.cart.bId = this.id;
        this.cart.workerName = '';
        this.cart.discount = 0;
        this.cart.specialInstruction = '';
        this.cartList.push(this.cart);
        this.orders.cartDetails.push(this.cart);
      }
    }
    this.cd.detectChanges()
    if (this.category_id != 4) {
      this.updatePrice()
    } else {
      if (this.pointFlag == true) {
        this.applyPoints(true)
      } else {
        this.applyPoints(false)
      }
    }
  }
  incQty(data, i) {
    this.selectedItem[i].qty = this.selectedItem[i].qty + 1
    // console.log(this.selectedItem[i])
    // console.log(this.cartList)
    if (this.cartList.length == 0 || this.cartList == undefined || this.cartList == null) {
      this.cart.itemId = this.selectedItem[i].id;
      this.cart.cost = 0;
      this.cart.quantity = this.selectedItem[i].qty;
      this.cart.itemName = this.selectedItem[i].name;
      this.cart.item = '';
      this.cart.costPerItem = this.selectedItem[i].price;
      this.cart.tax = this.selectedItem[i].tax;
      this.cart.exciseApplicable = 0;
      this.cart.taxPer = 0;
      this.cart.type = 0;
      this.cart.bId = this.id;
      this.cart.workerName = '';
      this.cart.discount = 0;
      this.cart.specialInstruction = '';
      this.cartList.push(this.cart);
      this.orders.cartDetails.push(this.cart);
    } else {
      let index = this.cartList.findIndex(e => e.itemId == this.selectedItem[i].id)
      if (index > -1) {
        this.cartList[index].quantity = this.selectedItem[i].qty
      } else {
        this.cart.itemId = this.selectedItem[i].id;
        this.cart.cost = 0;
        this.cart.quantity = this.selectedItem[i].qty;
        this.cart.itemName = this.selectedItem[i].name;
        this.cart.item = '';
        this.cart.bId = this.id;
        this.cart.costPerItem = this.selectedItem[i].price;
        this.cart.tax = this.selectedItem[i].tax;
        this.cart.exciseApplicable = 0;
        this.cart.taxPer = 0;
        this.cart.type = 0;
        this.cart.workerName = '';
        this.cart.discount = 0;
        this.cart.specialInstruction = '';
        this.cartList.push(this.cart);
        this.orders.cartDetails.push(this.cart);
      }
    }
    this.cd.detectChanges()
    this.updatePrice()
    // console.log(this.cartList)
  }
  openModal(content, modalSize) {
    // console.log("hey")
    if (this.category_id == 4) {
      this.detailForm.reset()
      this.submitted1 = false
      const currentDate = new Date();
      const localISOTime = new Date(currentDate.getTime() - (currentDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 16);
      this.detailForm.get('billDate')?.setValue(localISOTime);
    }
    this.modalRef = this.modalService.open(content, {
      size: modalSize,
    });
  }
  // viewDraftOrders()
  // {
  //   this.draftOrders = []
  //   let draftOrders = this.storage.get('existingOrders');
  //   if (draftOrders != null) {
  //     for (let i = 0; i < draftOrders.length; i++) {
  //       if (draftOrders[i].businessId == this.businessId) {
  //         this.draftOrders.push(draftOrders[i])
  //       }
  //     }
  //     console.log(this.draftOrders)
  //   }
  // }
  showDraftData(content, modalSize) {
    this.viewDraftOrders2()
    this.modalRef = this.modalService.open(content, {
      size: modalSize,
    });
  }
  viewDraftOrders2() {
    this.draftOrders = []
    let draftOrders = this.storage.get('existingOrders');
    if (draftOrders != null) {
      for (let i = 0; i < draftOrders.length; i++) {
        if (draftOrders[i].businessId == this.businessId) {
          if (draftOrders[i].orderId != this.notShowDraftId) {
            this.draftOrders.push(draftOrders[i])
          }
        }
      }
    }
  }

  openPosOrder(orderId) {
    this.modalRef.close()
    let path = "pos/order/" + orderId;
    this.router.navigate([path]);
  }
  getCustomisation() {
    let params =
      "userId=" +
      this.storage.get("userId") +
      "&password=" +
      this.storage.get("password") +
      "&businessId=" +
      this.storage.get("selectedBusinessId");
    this.httpClient
      .get("https://api.uengage.in/pos/getCustomisationList?" + params, {})
      .subscribe((res: any) => {
        if (res.status == 1) {
          if (res.templates.length != 0) {
            let data = res.templates[0].template
            for (let i = 0; i < data.length; i++) {
              if (data[i].items.length == 0) {
                this.customData.push({
                  customName: data[i].itemName,
                  sp: data[i].sp,
                  id: data[i].id
                })
              } else {
                for (let j = 0; j < data[i].items.length; j++) {
                  this.customData.push({
                    customName: data[j].items.itemName,
                    sp: data[j].items.sp,
                    id: data[j].items.id
                  })
                }
              }
            }
            // console.log(this.customData)
            this.cd.detectChanges()
          } else {

          }
        } else if (res.status == 0) {
          Swal.fire('', res.msg, 'error')
          return false;
        }
      });
  }
  getStylistData() {
    /*
  Created By: Prerna Kakria
  Created At: 2021-08-18
  Reason: Common function to get stylist data
  Input Params: userId,password,businessId
  Output Params: status,msg,data
  */
    let params =
      "userId=" +
      this.storage.get("userId") +
      "&password=" +
      this.storage.get("password") +
      "&businessId=" +
      this.storage.get("selectedBusinessId");
    this.httpClient
      .get("https://api.uengage.in/pos/getSupportStaff?" + params, {})
      .subscribe((res: any) => {
        if (res.status == 1) {
          this.stylistData = res.data
          for (let i = 0; i < this.stylistData.length; i++) {
            this.staffdata.push({
              stylistName: this.stylistData[i].staff_name,
              id: this.stylistData[i].id
            })
          }
          //  console.log(this.staffdata)
          this.cd.detectChanges()
        } else if (res.status == 0) {
          Swal.fire('', res.msg, 'error')
          return false;
        }
      });
  }

  getChannelList() {
    let params =
      "userId=" +
      this.storage.get("userId") +
      "&password=" +
      this.storage.get("password") +
      "&businessId=" +
      this.storage.get("selectedBusinessId");
    this.httpClient
      .get(this.appComp.base_url + "pos/channels?" + params, {})
      .subscribe((res: any) => {
        this.channels = res.channels;
        let channelIndex = this.channels.findIndex(
          (e) => e.channelId == this.orders.sourceId
        );
        if (channelIndex > -1) {
          this.openItemVisible = this.channels[channelIndex]["openItems"];
          if (this.channels.length == 1) {
            this.source = this.channels[0].channel_name;
            this.sourceId = this.channels[0].channelId;
            let orderTypeCount = 0;
            if (this.channels[0].dine_in == 1) {
              orderTypeCount = orderTypeCount + 1;
              this.showDineIn = 1;
            }
            if (this.channels[0].delivery == 1) {
              orderTypeCount = orderTypeCount + 1;
              this.showDelivery = 1;
            }
            if (this.channels[0].take_away == 1) {
              orderTypeCount = orderTypeCount + 1;
              this.showTakeAway = 1;
            }
            if (orderTypeCount > 1) {
              this.showOrderType = "true";
            }
          }
        }

      });
  }
  getMenu() {
    // console.log("hey")
    if (!this.onlineOffline) {
      let menu = this.storage.get("menu");
      this.menu = menu.rows.menu;
      this.templates = menu.rows.templates;
      // this.getMenuItems(this.menu[0].sectionId);
    } else {
      let businessId = this.storage.get("selectedBusinessId");
      let parentId = this.parentBusinessId;
      if (this.onCall == 1) {
        businessId = this.orders.businessId;
        parentId = this.storage.get("selectedBusinessId");
      }
      let url = ''
      if (this.category_id == 4) {
        url =
          "https://api.uengage.in/petPooja/getBusinessFeed_new/" + businessId + "/" + parentId + "?pos=1";
      } else {
        url = "https://api.uengage.in/petPooja/getBusinessFeed_new/" + businessId + "/" + parentId;
      }
      // console.log("inside else")

      // console.log(url)
      this.httpClient.get(url, {}).subscribe(
        (res: any) => {
          if (res.rows != undefined) {
            this.storage.set("menu", res);
            this.menu = res.rows.menu;
          }
          // console.log(this.menu)
          if (this.category_id == 4) {
            this.voidOrderId = this.storage.get("void_order_id")
            for (let i = 0; i < this.menu.length; i++) {
              for (let j = 0; j < this.menu[i].items.length; j++) {
                // console.log(this.menu[i].items[j].variants)
                if (this.menu[i].items[j].variants == undefined) {
                  this.menuData.push({
                    item: this.menu[i].items[j].itemName + "     -     " + "₹" + this.menu[i].items[j].sp,
                    itemName: this.menu[i].items[j].itemName,
                    Category: this.menu[i].sectionName,
                    itemId: Number(this.menu[i].items[j].id),
                    cost: this.menu[i].items[j].sp,
                    tax: this.menu[i].items[j].tax,
                    type: this.menu[i].items[j].type,
                    partPayment: this.menu[i].items[j].part_payment,
                    quantity: this.menu[i].items[j].quantity,
                    sType: this.menu[i].items[j].sType
                  })
                } else {
                  this.menu[i].items[j].variants.forEach(element => {
                    this.menuData.push({
                      item: this.menu[i].items[j].itemName + "  -  " + element.itemName + "     -     " + "₹" + element.sp,
                      itemName: element.itemName,
                      Category: this.menu[i].sectionName,
                      itemId: Number(element.id),
                      cost: element.sp,
                      tax: element.tax,
                      type: element.type,
                      partPayment: element.part_payment,
                      quantity: element.quantity,
                      sType: element.sType
                    })
                  });
                  // for(let k=0;k<this.menu[i].items[j].variants.length;k++){
                  //   console.log(this.menu[i].items[j].variants)

                  // }
                }
              }
            }
            // console.log(this.menuData)
            //  this.query = new Query().from('menuData').select(['ServiceName', 'Category','id']).take(this.menuData.length);
          }
          else if (this.category_id != 4 && this.parentBusinessId != 0) {
            this.menuTemplates = []
            for (let i = 0; i < this.menu.length; i++) {
              for (let j = 0; j < this.menu[i].items.length; j++) {
                // console.log(this.menu[i].items[j].variants)
                if (this.menu[i].items[j].variants == undefined) {
                  this.menuData.push({
                    item: this.menu[i].items[j].itemName + "     -     " + "₹" + this.menu[i].items[j].sp,
                    itemName: this.menu[i].items[j].itemName,
                    Category: this.menu[i].sectionName,
                    itemId: Number(this.menu[i].items[j].id),
                    cost: this.menu[i].items[j].sp,
                    tax: this.menu[i].items[j].tax,
                    type: this.menu[i].items[j].type,
                    partPayment: this.menu[i].items[j].part_payment,
                    quantity: this.menu[i].items[j].quantity,
                    sType: this.menu[i].items[j].sType
                  })
                } else {
                  this.menu[i].items[j].variants.forEach(element => {
                    this.menuData.push({
                      item: this.menu[i].items[j].itemName + "  -  " + element.itemName + "     -     " + "₹" + element.sp,
                      itemName: element.itemName,
                      Category: this.menu[i].sectionName,
                      itemId: Number(element.id),
                      cost: element.sp,
                      tax: element.tax,
                      type: element.type,
                      partPayment: element.part_payment,
                      quantity: element.quantity,
                      sType: element.sType
                    })
                  });
                  // for(let k=0;k<this.menu[i].items[j].variants.length;k++){
                  //   console.log(this.menu[i].items[j].variants)

                  // }
                }
              }
            }
            this.menuTemplates = res.rows.templates
          }
          this.cd.detectChanges()
          if (this.storage.get("existingOrders")) {
            this.ordersArray = this.storage.get("existingOrders");
            this.existingOrderArray = this.ordersArray.filter(
              (e) => e.businessId == this.storage.get("selectedBusinessId")
            );
            this.orders = this.ordersArray.filter((e) => e.orderId == this.oId)[0];
            if (this.orders["otp_validated"] == 1) {
              this.isOtpValid = true
              this.membershipOtpRedemtion = 1
            } else {
              this.isOtpValid = false
              this.checkMembershipRedemtion()
            }
            this.getDisc()
            this.taxFlag = this.orders.isTaxFlag ? this.orders.isTaxFlag : false
            if (this.orders.cartDetails != undefined && this.orders.cartDetails.length > 0) {
              this.orders.cartDetails.forEach((element: any) => {
                element.templateCartDetails.forEach((ele) => {
                  this.templateCart.itemId = ele.itemId;
                  this.templateCart.cost = ele.cost;
                  this.templateCart.quantity = ele.quantity;
                  this.templateCart.itemName = ele.itemName;
                  this.templateCart.costPerItem = ele.costPerItem;
                  this.templateCart.tax = ele.tax;
                  this.templateCart.taxPer = ele.taxPer;
                  this.cart.templateCartDetails.push(this.templateCart);
                  this.templateCart = new cartItems();
                });
                // console.log(element)
                this.cart.itemId = element.itemId;
                this.cart.item = element.item;
                this.cart.cost = element.cost;
                this.cart.quantity = Number(element.quantity);
                this.cart.itemName = element.itemName;
                this.cart.costPerItem = element.costPerItem;
                this.cart.tax = element.tax;
                this.cart.exciseApplicable = element.exciseApplicable;
                this.cart.taxPer = element.taxPer;
                this.cart.type = element.type;
                this.cart.workerName = element.workerName;
                this.cart.workerId = element.workerId;
                this.cart.discount = element.discount;
                this.cart.bId = this.id;
                this.cart.disType = element.disType
                this.cart.discRemark = element.discRemark
                this.cart.disValue = element.disValue
                this.cart.specialInstruction = element.specialInstruction;
                this.cart.packingMappingId = element.packingMappingId;
                this.cart.packageName = element.packageName;
                this.specialInstruction.push(element.specialInstruction);
                this.isIncluseTax = element.isGSTInclusive ? element.isGSTInclusive : false

                this.cartList.push(this.cart);
                // console.log(this.cartList)
                this.cart = new cartItems();
              });
            }
            else if (this.orders.cartDetails.length == 0 && this.category_id == 4 && this.designType != 1) {
              // console.log("here")
              if (this.orders.serviceList.length != 0) {
                let data = this.orders.serviceList
                for (let i = 0; i < data.length; i++) {
                  let discount = 0
                  if (data[i].package_id) {
                    discount = Number(data[i].sp)
                  } else {
                    discount = 0
                  }
                  this.cart.itemId = Number(data[i].service_id);
                  this.cart.cost = data[i].sp;
                  this.cart.quantity = 1;
                  this.cart.itemName = data[i].itemName;
                  this.cart.costPerItem = data[i].sp;
                  this.cart.tax = data[i].tax;
                  this.cart.exciseApplicable = 0;
                  this.cart.taxPer = 0;
                  this.cart.type = 0;
                  this.cart.workerName = data[i].staff_id.split(",");
                  this.cart.workerId = data[i].staff_id.split(",");
                  this.cart.discount = discount;
                  this.cart.bId = this.id;
                  this.cart.disType = ''
                  this.cart.discRemark = ''
                  this.cart.disValue = 0
                  this.cart.specialInstruction = '';
                  this.cart.packingMappingId = data[i].package_id ?? data[i].package_id, '';
                  this.cart.packageName = data[i].package_name ?? data[i].package_name, '';
                  this.cartList.push(this.cart);
                  this.orders.cartDetails.push(this.cart)
                  // console.log(this.orders.cartDetails)
                  this.cart = new cartItems();
                }
                // console.log(this.orders)
                this.updatePrice()
              } else {
                this.cart.itemId = 0;
                this.cart.cost = 0;
                this.cart.quantity = 1;
                this.cart.itemName = '';
                this.cart.bId = this.id;
                this.cart.costPerItem = 0;
                this.cart.tax = 0;
                this.cart.exciseApplicable = 0;
                this.cart.taxPer = 0;
                this.cart.type = 0;
                this.cart.workerName = '';
                this.cart.workerId = '';
                this.cart.discount = 0;
                this.cart.specialInstruction = '';
                this.cart.disType = ''
                this.cart.discRemark = ''
                this.cart.disValue = 0
                this.cart.packingMappingId = 0;
                this.cart.packageName = '';
                this.cartList.push(this.cart);
                this.orders.cartDetails.push(this.cart)
                this.cart = new cartItems();
              }
            }
            else if (this.orders.cartDetails.length == 0 && this.category_id != 4 && this.businessId == 6327) {
              if (this.orders.serviceList.length != 0) {
                let data = this.orders.serviceList
                // console.log(data)
                for (let i = 0; i < data.length; i++) {
                  this.cart.itemId = Number(data[i].service_id);
                  // this.cart.item = element.item;
                  this.cart.cost = data[i].sp;
                  this.cart.quantity = 1;
                  this.cart.itemName = data[i].itemName;
                  this.cart.costPerItem = data[i].sp;
                  this.cart.tax = data[i].tax;
                  this.cart.exciseApplicable = 0;
                  this.cart.taxPer = 0;
                  this.cart.type = 0;
                  this.cart.workerName = '';
                  this.cart.workerId = '';
                  this.cart.discount = 0;
                  this.cart.bId = this.id;
                  this.cart.disType = ''
                  this.cart.discRemark = ''
                  this.cart.disValue = 0
                  this.cart.specialInstruction = '';
                  this.cart.packingMappingId = 0;
                  this.cart.packageName = '';
                  this.cartList.push(this.cart);
                  this.orders.cartDetails.push(this.cart)
                  // console.log(this.cartList)
                  this.cart = new cartItems();
                }
                //  console.log(this.orders)
                this.updatePrice()
              } else {
                this.cart.itemId = 0;
                this.cart.cost = 0;
                this.cart.quantity = 1;
                this.cart.itemName = '';
                this.cart.bId = this.id;
                this.cart.costPerItem = 0;
                this.cart.tax = 0;
                this.cart.exciseApplicable = 0;
                this.cart.taxPer = 0;
                this.cart.type = 0;
                // this.cart.workerName = '';
                // this.cart.workerId = '';
                this.cart.discount = 0;
                this.cart.specialInstruction = '';
                this.cart.disType = ''
                this.cart.discRemark = ''
                this.cart.disValue = 0
                // this.cart.packingMappingId = 0;
                // this.cart.packageName = '';
                this.cartList.push(this.cart);
                this.orders.cartDetails.push(this.cart)
                this.cart = new cartItems();
              }
            }
          }
          else {
            this.ordersArray = [];
          }
          // console.log(this.cartList);
          if (this.category_id == 4) {
            this.membershipData = []
            this.packageData = []
            this.packageDetailsArr = []

            let params =
              "userId=" +
              this.storage.get("userId") +
              "&password=" +
              this.storage.get("password") +
              "&businessId=" +
              this.storage.get("selectedBusinessId") +
              "&mobileNo=" + this.orders.mobileNo;
            this.getCustomerAdvancePayments(params)
            this.httpClient
              .get(this.appComp.base_url + "pos/getBillingDetails?" + params, {})
              .subscribe((res: any) => {
                if (res.status == 1) {
                  this.membershipData = res.membership
                  if (this.membershipData.length == 0) {
                    this.showMem = 0
                  } else {
                    if (this.membershipData[0].membershipAmount == 0) {
                      this.showMem = 0
                    } else {
                      this.showMem = 1
                    }
                  }
                  this.packageData = res.package
                  this.plength = this.packageData.length
                  // console.log(this.packageData)
                  // for(let i=0;i<this.paymentOption.length;i++){
                  //   if(this.paymentOption[i].name=='Membership'){
                  //     this.paymentOption[i].memAmount = this.membershipData[0].membershipAmount
                  //   }
                  // }
                  for (let i = 0; i < this.packageData.length; i++) {
                    // console.log("hey")
                    if (this.packageDetailsArr.length == 0) {
                      this.packageDetailsArr.push({
                        pId: this.packageData[i].packageid,
                        pName: this.packageData[i].packageName,
                        items: []
                      });
                      this.packageDetailsArr[0]['items'].push({
                        itemName: this.packageData[i].itemName,
                        itemId: this.packageData[i].itemId,
                        // packageId: this.packageData[i].packageid,
                        packageName: this.packageData[i].packageName,
                        packingMappingId: this.packageData[i].packingMappingId,
                        redeemed: 0
                      })
                    } else {
                      let index = this.packageDetailsArr.findIndex(e => e.pName == this.packageData[i].packageName);
                      if (index > -1) {
                        this.packageDetailsArr[index]['items'].push({
                          itemName: this.packageData[i].itemName,
                          itemId: this.packageData[i].itemId,
                          // packageId: this.packageData[i].packageid,
                          packageName: this.packageData[i].packageName,
                          packingMappingId: this.packageData[i].packingMappingId,
                          redeemed: 0
                        })
                      } else {
                        this.packageDetailsArr.push({
                          pId: this.packageData[i].packageid,
                          pName: this.packageData[i].packageName,
                          items: []
                        });
                        this.packageDetailsArr[this.packageDetailsArr.length - 1]['items'].push({
                          itemName: this.packageData[i].itemName,
                          itemId: this.packageData[i].itemId,
                          // packageId: this.packageData[i].packageid,
                          packageName: this.packageData[i].packageName,
                          packingMappingId: this.packageData[i].packingMappingId,
                          redeemed: 0
                        })
                      }
                    }

                    // this.packageDetailsArr.push({
                    //   itemName: this.packageData[i].itemName,
                    //   itemId: this.packageData[i].itemId,
                    //   // packageId: this.packageData[i].packageid,
                    //   packageName: this.packageData[i].packageName,
                    //   packingMappingId: this.packageData[i].packingMappingId,
                    //   redeemed: 0
                    // })
                  }
                  // console.log(this.packageDetailsArr)
                  // console.log(this.cartList)
                  for (let i = 0; i < this.cartList.length; i++) {
                    for (let j = 0; j < this.packageDetailsArr.length; j++) {
                      for (let k = 0; k < this.packageDetailsArr[j].items.length; k++) {
                        if (this.cartList[i].packingMappingId == this.packageDetailsArr[j].items[k].packingMappingId) {
                          this.packageDetailsArr[j].items[k].redeemed = 1;
                        }
                      }
                    }
                  }
                  // console.log(this.packageDetailsArr)
                  this.cd.detectChanges()
                } else {
                  Swal.fire('', res.msg, 'error')
                }
              });
            var formData = new FormData();
            formData.append("userId", this.storage.get("userId"));
            formData.append("businessId", this.storage.get("selectedBusinessId"));
            formData.append("password", this.storage.get("password"));
            formData.append("parentBusinessId", this.parentBusinessId.toString());
            formData.append("mobile_no", this.orders.mobileNo.toString());
            this.httpClient.post('https://api.uengage.in/membershipCards/customerMembershipCardListing', formData)
              .subscribe((res: any) => {
                if (res.status) {
                  if (res.data.length != 0) {
                    this.loyaltyCard = res.data
                  }
                } else {
                  // Swal.fire('', res.message, 'error')
                }
              });
            if (this.orders.mobileNo != null && this.orders.mobileNo != undefined && this.orders.mobileNo != '') {
              let param =
                "userId=" +
                this.storage.get("userId") +
                "&password=" +
                this.storage.get("password") +
                "&businessId=" +
                this.storage.get("selectedBusinessId") +
                "&customerMobileNo=" + this.orders.mobileNo;
              this.httpClient
                .get(this.appComp.base_url + "addoapi/getPendingPayments?" + param, {})
                .subscribe((res: any) => {
                  if (res.status == 1) {
                    if (res.list.length != 0) {
                      this.pendingPayoutAmount = res.list[0].pending_amount
                      if (this.pendingPayoutAmount != 0) {
                        // let element: HTMLElement = document.getElementById("pending_payout") as HTMLElement;
                        // element.click()
                        this.payoutId = res.list[0].id
                        Swal.fire({
                          // title:'',
                          title: `An amount of ₹${this.pendingPayoutAmount} is pending. Would you like to settle it now?`,
                          showCancelButton: true,
                          confirmButtonText: 'Settle Amount',
                          cancelButtonText: 'Cancel',
                          showCloseButton: true,
                          customClass: {
                            confirmButton: 'swal2-confirm-custom',
                            cancelButton: 'swal2-cancel-custom'
                          }
                        }).then((result) => {
                          if (result.isConfirmed) {
                            // Action for the 'Show Details' button
                            let element: HTMLElement = document.getElementById("pending_payout2") as HTMLElement;
                            element.click()
                          }
                        });

                      }
                      this.cd.detectChanges()
                    } else {
                      this.pendingPayoutAmount = 0
                    }
                  } else {
                    Swal.fire('', res.msg, 'error')
                  }
                });
              this.getCustomerId()
              this.getLoyalty()
            }
            // this.getOffers()
            if (this.orders.walletPoints != 0 && this.orders.walletPoints != undefined && this.orders.walletPoints != null) {
              this.pointFlag = true
            }
          }
          this.cd.detectChanges();
          if (this.businessId != 6327) {
            this.listClick(this.menu[0], 0)
          }
          // console.log(this.menuData)
          if (res.rows != undefined) {
            this.templates = res.rows.templates;
          }

        },
        (error) => {
        }
      );

    }

    // this.checkMembership()
    // this.listClick(this.menu[0])
    // this.selectedItem=this.menu[0]

  }
  getPosType() {
    let params = new HttpParams({
      fromObject: {
        userId: this.storage.get("userId"),
        password: this.storage.get("password"),
        business_id: this.storage.get("selectedBusinessId"),
        flag: '1'
      }
    });
    let httpOptions = {
      headers: new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded")
    };
    let url = "https://crm.uengage.in/crm_api/get/pos/config"
    this.httpClient.post(url, params, httpOptions).subscribe((res: any) => {
      if (res.status) {
        this.designType = res.data[0].pos_view
        this.cd.detectChanges()
      }
    });
  }
  applyPromo() {
    this.orders.promoCode = ''
    this.orders.promoDiscount = 0
    Swal.fire({
      title: "Are you sure you want to apply the promo?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes! Apply",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value) {
        if (this.promoDetails['discount_type'] == 10) { // fixed discount
          if (Number(this.promoDetails['min_order_value']) > Number(this.orders.subtotal)) {
            Swal.fire('', `Min bill value to apply promo code is ${this.promoDetails['min_order_value']}`, 'error')
          } else {
            // let discount = Number(this.promoDetails['max_discount_amount']) / this.orders.cartDetails.length
            // for (let i = 0; i < this.orders.cartDetails.length; i++) {
            //   this.orders.cartDetails[i].discount = discount
            //   //  this.orders.cartDetails[i].discountedAmount = discount
            // }
            let disPer = (Number(this.promoDetails['max_discount_amount']) / this.orders.subtotal) * 100
            // console.log(disPer)
            for (let i = 0; i < this.orders.cartDetails.length; i++) {
              this.orders.cartDetails[i].disValue = Number(((Number(this.orders.cartDetails[i]["costPerItem"]) * disPer) / 100).toFixed(2))
              this.orders.cartDetails[i].discount = Number(((Number(this.orders.cartDetails[i]["costPerItem"]) * disPer) / 100).toFixed(2))
            }
            this.orders.promoCode = this.promoDetails['code']
            this.orders.promoDiscount = Number(this.promoDetails['max_discount_amount'])
            if (this.pointFlag == true) {
              this.applyPoints(true)
            } else {
              this.applyPoints(false)
            }
            Swal.fire('', `Promo applied!`, 'success')
            this.modalRef.close()
            this.promoId = ''

          }
        } else if (this.promoDetails['discount_type'] == 9) { // percentage discount
          if (Number(this.promoDetails['min_order_value']) > Number(this.orders.subtotal)) {
            Swal.fire('', `Min bill value to apply promo code is ${this.promoDetails['min_order_value']}`, 'error')
          } else {
            this.orders.promoCode = this.promoDetails['code']
            let disAmount = (Number(this.promoDetails['discount_value']) * this.orders.subtotal) / 100
            // console.log(disAmount)
            // let discount
            if (disAmount > this.promoDetails['max_discount_amount']) {
              this.orders.promoDiscount = this.promoDetails['max_discount_amount']
              // discount = this.promoDetails['max_discount_amount']
            } else {
              this.orders.promoDiscount = disAmount
              // discount = disAmount
            }
            let disPer = (this.orders.promoDiscount / this.orders.subtotal) * 100
            // console.log(disPer)
            for (let i = 0; i < this.orders.cartDetails.length; i++) {
              this.orders.cartDetails[i].disValue = Number(((Number(this.orders.cartDetails[i]["costPerItem"]) * disPer) / 100).toFixed(2))
              this.orders.cartDetails[i].discount = Number(((Number(this.orders.cartDetails[i]["costPerItem"]) * disPer) / 100).toFixed(2))
            }
            if (this.pointFlag == true) {
              this.applyPoints(true)
            } else {
              this.applyPoints(false)
            }
            Swal.fire('', `Promo applied!`, 'success')
            this.modalRef.close()
            this.promoId = '';
          }
        }
      } else {
        this.modalRef.close()
      }
    });
  }
  openPromoModal(modal) {
    this.getOffers()
    this.promoDetails = []
    this.modalRef = this.modalService.open(modal, {
      size: 'md'
    })
  }
  getPromoDetail() {
    this.promoDetails = []
    let index = this.promoData.findIndex(e => e.promoCodeId == this.promoId)
    if (index >= 0) {
      this.promoDetails = this.promoData[index]
      this.cd.detectChanges()
      // console.log(this.promoDetails)
    }
  }
  getOffers() {
    this.promoData = []
    let params =
      "userId=" +
      this.storage.get("userId") +
      "&password=" +
      this.storage.get("password") +
      "&businessId=" +
      this.storage.get("selectedBusinessId") +
      "&parentId=" +
      this.parentBusinessId +
      "&page=0";
    this.httpClient
      .get(this.appComp.base_url + "addoapi/getPromoCodes?" + params, {})
      .subscribe((res: any) => {
        if (res.status == 1) {
          let fltData = res.promos.filter(e => e.max_allowed > e.orderCompletedCount)
          this.promoData = fltData;
          this.cd.detectChanges();
        } else {
        }
      });
  }
  getCustomerId() {
    let params = new HttpParams({
      fromObject: {
        userId: this.storage.get("userId"),
        password: this.storage.get("password"),
        business_id: this.storage.get("selectedBusinessId"),
        parent_business_id: this.parentBusinessId.toString(),
        mobile_number: this.orders.mobileNo.toString()
      }
    });
    let httpOptions = {
      headers: new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded")
    };
    let url = "https://crm.uengage.in/crm_api/get/customer/id"
    this.httpClient.post(url, params, httpOptions).subscribe((res: any) => {
      if (res.status) {
        this.customerId = res.result._id
        if (this.customerId != undefined && this.customerId != null && this.customerId != '') {
          this.getDetails()
        }
        //  console.log(this.customerId)
      }
    });
  }
  getDetails() {
    var params = new HttpParams()
      .set("userId", this.storage.get("userId"))
      .set("password", this.storage.get("password"))
      .set("business_id", this.storage.get("selectedBusinessId"))
      .set("parent_business_id", this.parentBusinessId.toString())
      .set("customer_id", this.customerId)
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let url = "https://crm.uengage.in/crm_api/get/customer/detail";
    this.httpClient.post(url, params, httpOptions).subscribe((data: any) => {
      this.lto = data.result.LTO ? data.result.LTO : 0
      this.ltv = data.result.LTV ? data.result.LTV : 0;
      this.lastorder = data.result.lastOrderDate ? data.result.lastOrderDate : '';
      this.notes = data.result.notes ? data.result.notes : []
      this.cd.detectChanges()
    })
  }
  openNotesModel(modal) {
    this.modalRef = this.modalService.open(modal, {
      size: 'lg'
    })
  }
  viewProfile() {
    let path = "prism/crm-customers/customer_review/" + this.customerId;
    // window.open(path, '_blank')
    this.router.navigateByUrl(path);
  }
  calculateAmount() {

  }
  openPackageModal(modal, size) {
    this.openModal(modal, size);
  }
  getMenuItems(sectionId) {
    this.itemParentDetailArray = new Array<itemDetails>();
    let index = this.menu.findIndex((e) => e.sectionId == sectionId);
    let items = this.menu[index]["items"];
    items.forEach((element) => {
      if (element.parentId == 0) {
        let variant = element.variants.filter((e) => e.parentId == element.id);
        if (variant.length > 0) {
          variant.forEach((element1) => {
            this.variantDetail.id = element1.id;
            this.variantDetail.itemName = element1.itemName;
            this.variantDetail.description = element1.description;
            this.variantDetail.sp = element1.sp;
            this.variantDetail.status = element1.status;
            this.variantDetail.mrp = element1.mrp;
            this.variantDetail.actualCost = element1.actualCost;
            this.variantDetail.unit = element1.unit;
            this.variantDetail.costPerUnit = element1.costPerUnit;
            this.variantDetail.quantity = element1.quantity;
            this.variantDetail.parentId = element1.parentId;
            this.variantDetail.tax = element1.tax;
            this.variantDetail.type = element1.type;
            this.variantDetail.exciseApplicable = element1.exciseApplicable;
            this.variantDetail.templateId = element1.templateId;
            this.variantDetail.inventoryAmount = element1.inventoryAmount;
            this.itemDetail.variantDetails.push(this.variantDetail);
            this.variantDetail = new variantDetails();
          });
        }

        this.itemDetail.id = element.id;
        this.itemDetail.itemName = element.itemName;
        this.itemDetail.description = element.description;
        this.itemDetail.sp = element.sp;
        this.itemDetail.status = element.status;
        this.itemDetail.mrp = element.mrp;
        this.itemDetail.actualCost = element.actualCost;
        this.itemDetail.unit = element.unit;
        this.itemDetail.costPerUnit = element.costPerUnit;
        this.itemDetail.tax = element.tax;
        this.itemDetail.type = element.type;
        this.itemDetail.quantity = element.quantity;
        this.itemDetail.parentId = element.parentId;
        this.itemDetail.exciseApplicable = element.exciseApplicable;
        this.itemDetail.templateId = element.templateId;

        this.variantDetail.inventoryAmount = element.inventoryAmount;
        this.itemParentDetailArray.push(this.itemDetail);
        this.itemDetail = new itemDetails();
      }
    });
    this.cd.markForCheck();
  }
  checkTemplateExist(items, templateModal) {
    if (items.templateId != null && items.templateId != 0) {
      this.addToCart(items);
      this.getTemplateItems(items.templateId, templateModal, items.id);
    } else {
      this.addToCart(items);
    }
  }

  addToCart(items) {
    // console.log(items)
    if (items.parentItemId != null && items.parentItemId != 0) {
      let id = this.cartList.length - 1;
      this.templateCart.itemId = items.id;
      this.templateCart.itemName = items.itemName;
      this.templateCart.cost = items.sp;
      this.templateCart.quantity = 1;
      this.templateCart.costPerItem = items.sp;
      this.templateCart.tax = Math.round(Number((items.sp * items.tax) / 100));
      this.templateCart.taxPer = Number(items.tax);

      // this.templateCart.templateCartDetails.push(this.templateCart);
      this.cartList[id].templateCartDetails.push(this.templateCart);
      this.orders.cartDetails[id].templateCartDetails = this.cartList[
        id
      ].templateCartDetails;
      this.templateCart = new templateCartItems();
      this.updateTotalDetails();
    } else {
      let alreadyExist = this.cartList.filter((e) => e.itemId == items.id);
      // console.log(alreadyExist)
      this.cart.itemId = items.id;
      this.cart.itemName = items.itemName;
      this.cart.cost = items.sp;
      this.cart.quantity = 1;
      this.cart.type = items.type;
      this.cart.exciseApplicable = items.exciseApplicable;
      this.cart.costPerItem = items.sp;
      this.cart.foodCategory = items.foodCategory;
      this.cart.unit = items.unit;
      this.cart.costPerUnit = items.costPerUnit;
      if (this.category_id == 4) {
        // console.log(this.stylistData)
        this.cart.workerName = '';
      }
      this.cart.tax = Math.round(Number((items.sp * items.tax) / 100));
      this.cart.taxPer = Number(items.tax);
      if (this.id == undefined) {
        this.cart.bId = this.storage.get("selectedBusinessId");
      }
      else {
        this.cart.bId = this.id
      }
      if (alreadyExist.length == 0) {
        this.cartList.push(this.cart);
        this.orders.cartDetails.push(this.cart);
      }
      else {
        let index = this.cartList.filter((e) => e.itemId == alreadyExist[0].itemId);
        let id = index[0].itemId
        // console.log(id)
        for (let j = 0; j < this.cartList.length; j++) {
          if (this.cartList[j].itemId == id) {
            this.flag = j;
            break;
          }
        }
        this.cartList[this.flag].quantity = alreadyExist[0].quantity + 1;
        this.cartList[this.flag].cost = items.sp * this.cartList[this.flag].quantity;
        this.cd.detectChanges();
      }
      // console.log(this.cart)
      // console.log(this.orders.cartDetails)
      // console.log(this.finalArr)
      this.cart = new cartItems();
      this.updateTotalDetails();
    }
  }
  qtyChange1(items, evt, index) {
    let itemIndex
    // console.log(items)
    for (let i = 0; i < this.cartList.length; i++) {
      itemIndex = this.menuData.findIndex(e => e.itemId == this.cartList[i].itemId)
      // console.log(index)
    }
    let qty = this.menuData[itemIndex].quantity
    let sType = this.menuData[itemIndex].sType
    // console.log(sType)
    // console.log(qty)
    if (this.category_id == 4) {
      if (sType == 1) {
        if (qty != null && qty != undefined) {
          if (evt.value > qty) {
            Swal.fire('', 'Available quantity of product in inventory is ' + qty, 'error')
            // this.dropDownListObject.value = 1; 
            this.updateCart(1, items, index);
            // this.cartList[index].quantity = 1
            // this.orders.cartDetails[index].quantity = 1
            // console.log(this.cartList)
          } else {
            this.updateCart(parseInt(evt.value), items, index);
          }
        } else {
          this.updateCart(parseInt(evt.value), items, index);
        }
      } else {
        this.updateCart(parseInt(evt.value), items, index);
      }
    } else {
      this.updateCart(parseInt(evt.value), items, index);
    }
    // console.log(this.cartList[index])
    // this.updateCart(parseInt(evt.value), items, index);
  }
  qtyChange(items, index) {
    this.updateCart(items.quantity, items, index);
  }
  updateCart(qty, items, index) {
    // console.log(items)
    if (qty == 0) {
      this.cartList.splice(index, 1);
      this.orders.cartDetails.splice(index, 1)
      if (this.pointFlag == true) {
        this.applyPoints(true)
      } else {
        this.applyPoints(false)
      }
    } else {
      this.orders.subtotal = 0
      this.orders.totalAmt = 0
      this.orders.tax = 0
      let cost = 0
      let discount = 0
      let costPerItem = Number(items.costPerItem);
      if (this.totalDiscount == 1) {
        this.cartList[index].quantity = qty;
        this.orders.cartDetails[index].quantity = qty;
        for (let i = 0; i < this.orders.cartDetails.length; i++) {
          let itemValue = 0
          let tax = 0
          itemValue = Number(this.orders.cartDetails[i].costPerItem) * Number(this.orders.cartDetails[i].quantity)
          tax = (Number(this.orders.cartDetails[i].cost) * Number(this.orders.cartDetails[i].tax)) / 100
          this.orders.subtotal += Number(itemValue)
        }
        this.cd.detectChanges()
        this.addTotalDiscount()
      } else {
        this.orders.discount = 0
        if (items.disType == 'value') {
          cost = (costPerItem * Number(qty)) - Number(items.discount);
          // console.log(cost)
        } else if (items.disType == 'percentage') {
          discount = (costPerItem * Number(qty) * items.disValue) / 100
          cost = (costPerItem * Number(qty)) - Number(discount);
          this.cartList[index].discount = discount;
          this.orders.cartDetails[index].discount = discount;
        } else {
          cost = (costPerItem * Number(qty)) - Number(items.discount);
        }
        this.cartList[index].quantity = qty;
        this.cartList[index].cost = cost;
        this.cd.detectChanges();
        this.orders.cartDetails[index].quantity = qty;
        this.orders.cartDetails[index].cost = cost;
        // this.orders.cartDetails[index].discount = discount;
        for (let i = 0; i < this.orders.cartDetails.length; i++) {
          // console.log(this.orders.cartDetails[i])
          let itemValue = 0
          let tax = 0
          itemValue = Number(this.orders.cartDetails[i].costPerItem) * Number(this.orders.cartDetails[i].quantity)
          tax = (Number(this.orders.cartDetails[i].cost) * Number(this.orders.cartDetails[i].tax)) / 100
          this.orders.subtotal += Number(itemValue)
          this.orders.tax += tax
          this.orders.discount += Number(this.orders.cartDetails[i].discount)
        }
        // console.log(this.orders.subtotal)
        // console.log(this.orders.discount)
        // console.log(this.orders.tax)
        this.orders.totalAmt = (Number(this.orders.subtotal) + Number(this.orders.tax)) - Number(this.orders.discount)
        this.orders.roundedTotalAmt = Math.round(this.orders.totalAmt)
        if (this.orders.totalAmt > this.orders.roundedTotalAmt) {
          this.orders.roundDiff = this.orders.totalAmt - this.orders.roundedTotalAmt
        } else {
          this.orders.roundDiff = this.orders.roundedTotalAmt - this.orders.totalAmt
        }
        this.orders.discountedAmount = this.orders.discount
        // this.adjustPayment=this.orders.totalAmt.toString()
        let orderIndex = this.ordersArray.findIndex(
          (e) => e.orderId == this.orders.orderId
        );
        this.orders.updatedAt = this.getDate();
        if (orderIndex > -1) {
          this.ordersArray[orderIndex] = this.orders;
        } else {
          this.ordersArray.push(this.orders);
        }
        // console.log(this.ordersArray)
        this.storage.set("existingOrders", this.ordersArray);
        // if (this.paymentType == "membership") {
        //   this.completeExistingOrder('MS', '', true)
        // }
        this.cd.detectChanges();
        // this.updateSalonCart()
        if (this.pointFlag == true) {
          this.applyPoints(true)
        } else {
          this.applyPoints(false)
        }
        // console.log(this.cartList[0]['quantity']); 
      }
    }
    this.cd.detectChanges();
  }
  randomNumber() {
    let random = Math.floor(Math.random() * (999999 - 100000)) + 100000;
    return random;
  }
  newOrdersCreate() {
    this.newOrder = new newOrderDetails();
    this.newOrder.updatedAt = this.getDate();

    this.newOrder.invoicedAt = this.getDate();
    this.newOrder.updatedAt = this.getDate();
    this.newOrder.orderId = this.randomNumber();
    this.newOrder.businessId = this.storage.get("selectedBusinessId");
    this.cartList = new Array<cartItems>();
    this.newOrder.cartDetails = new Array<cartItems>();
    this.ordersArray.push(this.newOrder);
    this.storage.set("existingOrders", this.ordersArray);
    this.modalRef.close();
    this.orders = this.newOrder;
    this.newOrder = new newOrderDetails();
  }
  packingCharges() {
    this.updateTotalDetails();
  }
  closeOrderModal(modalName) {
    // this.cd.detectChanges();
    let channelArray = this.channels.filter(
      (e) => e.channelId == this.orders.sourceId
    )[0];
    if (channelArray != undefined) {
      this.ifPackingChargesApplicable = channelArray.packing_charges;
    } else {
      this.ifPackingChargesApplicable = 0;
    }

    this.openModal(modalName, "lg");
    this.paymentType = ''
    this.sectionShow = 0
    this.memUsed = false
    this.checkMembership()
  }
  openNewOrderModel(modalName) {
    this.orders.invoicedAt = this.getDate();
    this.orders.orderId = this.randomNumber();
    this.openModal(modalName, "lg");
  }
  completeExistingOrder(mode: String, modal, check) {
    const value = mode == 'MS' ? check.target.checked : check
    if (value == true) {
      if (mode == 'MS') {
        // let packagePrice = 0
        // for(let i=0;i<this.orders.cartDetails.length;i++){
        //   if(this.orders.cartDetails[i].type==3){
        //     packagePrice+= this.orders.cartDetails[i].cost
        //   }
        // }
        if (this.orders.netAmt <= this.membershipData[0].membershipAmount) {
          this.memUsed = true
          // this.membershipUsed = this.orders.subtotal - this.orders.walletPoints - this.orders.discount - (this.orders.promoDiscount ?? 0)
          this.membershipUsed = this.orders.netAmt
          // this.walletLeft = this.membershipData[0].membershipAmount - this.membershipUsed
          this.walletLeft = this.membershipData[0].membershipAmount - this.membershipUsed
          // console.log(this.walletLeft)
          this.orders.roundedTotalAmt = 0
          this.orders.totalAmt = 0
          this.paymentType = "membership"
          this.disableMode = 1
          this.taxFlag = false
          this.isIncluseTax = false
          const isGST = null;
          this.afterCalculationData(isGST);
          this.cd.detectChanges()
        } else {
          this.disableMode = 0
          this.taxFlag = true
          const isGST = null;
          this.afterCalculationData(isGST);
          Swal.fire('', 'Amount exceeds available wallet balance.', 'info')
          check.preventDefault();
          return;
          this.disableMode = 0
          this.taxFlag = true
          this.membershipUsed = this.membershipData[0].membershipAmount
          let disPer = (this.membershipUsed / this.orders.subtotal) * 100
          // console.log(disPer)
          for (let i = 0; i < this.orders.cartDetails.length; i++) {
            this.orders.cartDetails[i].membershipAmount = Number(((Number(this.orders.cartDetails[i]["costPerItem"]) * disPer) / 100).toFixed(2))
          }
          if (this.pointFlag == true) {
            this.applyPoints(true)
          } else {
            this.applyPoints(false)
          }
        }
      }
    }
    else {
      this.memUsed = false
      this.walletLeft = 0
      this.membershipUsed = 0
      this.paymentType = ''
      this.disableMode = 0
      this.sectionShow = 0
      this.walletLeft = this.membershipData[0].membershipAmount
      for (let i = 0; i < this.orders.cartDetails.length; i++) {
        this.orders.cartDetails[i].membershipAmount = 0
      }
      this.checkTax(true)
    }
  }
  getDate() {
    let event = new Date();
    let date =
      event.getFullYear() +
      "-" +
      (event.getMonth() + 1) +
      "-" +
      event.getDate();
    let month =
      event.getHours() + ":" + event.getMinutes() + ":" + event.getSeconds();
    return date + " " + month;
  }
  discountCart() {
    if (this.orders.discount > this.maxDiscountAllowed) {
      this.discountAlertMessage =
        "Max Discount limit is " + this.maxDiscountAllowed;
      return false;
    }
    let discountApplicableAmount = 0;
    this.totalAmount = 0;
    // this.discountApplicableOn=2
    // console.log(this.discountApplicableOn)

    // console.log(this.discountApplicableOn,"hey")
    if (this.discountApplicableOn == 3) {
      // console.log("inside")
      this.orders.cartDetails.forEach((element) => {
        // console.log(element)
        this.totalAmount += Number(element.cost);
        discountApplicableAmount += Number(element.cost);
        this.orders.totalAmt = this.totalAmount;
      });
    } else if (this.discountApplicableOn == 2) {
      // console.log("2")
      this.orders.cartDetails.forEach((element) => {
        this.totalAmount += Number(element.cost);
        if (element.foodCategory == 2) {
          discountApplicableAmount += Number(element.cost);
          this.orders.totalAmt = this.totalAmount;
        }
      });
    } else if (this.discountApplicableOn == 1) {
      this.orders.cartDetails.forEach((element) => {
        this.totalAmount += Number(element.cost);
        if (element.foodCategory == 1) {
          discountApplicableAmount += Number(element.cost);
          this.orders.totalAmt = this.totalAmount;
        }
      });
    }
    let discountType = this.orders.discountType;
    // console.log(discountType) 0
    let discountPer = this.orders.discount;
    //  console.log(discountPer)
    let taxes = this.orders.tax;
    let newAmountAfterDiscount = 0;
    let discountAmt = 0;

    if (discountType == 1) {
      discountAmt = discountPer;
      if (discountAmt > discountApplicableAmount) {
        newAmountAfterDiscount =
          this.totalAmount - discountApplicableAmount + taxes;
      } else {
        newAmountAfterDiscount = this.totalAmount - discountAmt + taxes;
      }
    } else {
      discountAmt = (discountApplicableAmount * discountPer) / 100;
      if (discountAmt > discountApplicableAmount) {
        newAmountAfterDiscount =
          this.totalAmount - discountApplicableAmount + taxes;
      } else {
        newAmountAfterDiscount = this.totalAmount - discountAmt + taxes;
      }
    }

    this.orders.discountedAmount = discountAmt;
    this.totalAmount = newAmountAfterDiscount;
    this.orders.totalAmt = newAmountAfterDiscount;
    this.updateSalonCart();
    // const isGST = this.isIncluseTax ? this.isIncluseTax : null
    // this.afterCalculationData(isGST)
    // console.log(this.orders)
    this.modalRef.close();
  }
  applyDiscount() {
    let discountApplicableAmount = 0;
    this.totalAmount = 0;
    this.orders.cartDetails.forEach((element) => {
      // console.log(element)
      this.totalAmount += Number(element.cost);
      discountApplicableAmount += Number(element.cost);
      this.orders.totalAmt = this.totalAmount;
    });
    // let discountType = this.orders.discountType;
    // console.log(discountType) 0
    let discountPer = this.orders.discount;
    //  console.log(discountPer)
    let taxes = this.orders.tax;
    let newAmountAfterDiscount = 0;
    let discountAmt = 0;
    discountAmt = discountPer;
    if (discountAmt > discountApplicableAmount) {
      newAmountAfterDiscount =
        this.totalAmount - discountApplicableAmount + taxes;
    } else {
      newAmountAfterDiscount = this.totalAmount - discountAmt + taxes;
    }

    this.orders.discountedAmount = discountAmt;
    this.totalAmount = newAmountAfterDiscount;
    this.orders.totalAmt = newAmountAfterDiscount;
    //  console.log(this.orders)
    // this.modalRef.close();
  }
  placeOrder() {
    // console.log(this.orders)
    // let params =
    //   "userId=" +
    //   this.storage.get("userId") +
    //   "&password=" +
    //   this.storage.get("password") +
    //   "&businessId=" +
    //   this.storage.get("selectedBusinessId") +
    //   "&orderDetails=" +
    //   JSON.stringify(this.orders);

    // this.httpClient
    //   .get(this.appComp.base_url + "pos/placeOrder?" + params, {})
    //   .subscribe((res: any) => {
    //     if (res.status == 1) {
    //       this.removeOrder(this.orders);
    //       let path = "Dashboard/onCallOrders";
    //       this.router.navigate([path]);
    //     } else {
    //       this.errorMessage = res.msg;
    //     }
    //   });
  }
  closeOrder(type) {
    // console.log(this.orders)
    this.finalArr = []
    for (let i = 0; i < this.orders.cartDetails.length; i++) {
      if (this.finalArr.length == 0) {
        this.finalArr.push({
          bId: this.orders.cartDetails[i].bId,
          items: []
        });
        if (this.category_id == 4) {
          this.finalArr[0]['items'].push({
            itemId: this.orders.cartDetails[i].itemId,
            qty: this.orders.cartDetails[i].quantity,
            stylistName: this.orders.cartDetails[i].workerName
          })
        } else {
          this.finalArr[0]['items'].push({
            itemId: this.orders.cartDetails[i].itemId,
            qty: this.orders.cartDetails[i].quantity
          })
        }
      } else {
        let index = this.finalArr.findIndex(e => e.bId == this.orders.cartDetails[i].bId);
        if (index > -1) {
          this.finalArr[index]['items'].push({
            itemId: this.orders.cartDetails[i].itemId,
            qty: this.orders.cartDetails[i].quantity,
            stylistName: this.orders.cartDetails[i].workerName
          })
        } else {
          this.finalArr.push({
            bId: this.orders.cartDetails[i].bId,
            items: []
          });
          // console.log(this.finalArr.length)
          if (this.category_id == 4) {
            this.finalArr[0]['items'].push({
              itemId: this.orders.cartDetails[i].itemId,
              qty: this.orders.cartDetails[i].quantity,
              stylistName: this.orders.cartDetails[i].workerName
            })
          } else {
            this.finalArr[0]['items'].push({
              itemId: this.orders.cartDetails[i].itemId,
              qty: this.orders.cartDetails[i].quantity
            })
          }
        }
      }
    }
    let params = new HttpParams({
      fromObject: {
        userId: this.storage.get("userId"),
        password: this.storage.get("password"),
        businessId: this.storage.get("selectedBusinessId"),
        json_pos: JSON.stringify(this.finalArr),
        socket_id: "",
        total: this.orders.totalAmt.toString(),
        customerMobileNo: this.orders.mobileNo.toString(),
        membership_used: this.membershipUsed.toString(),
        mode: 'web'
      }
    });
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    let url = "https://api.uengage.in/pos/insertPOSData"
    this.httpClient.post(url, params, httpOptions).subscribe((res: any) => {
      // this.payment=1
    });


    // console.log(this.finalArr)

    // this.errorMessage = "";
    // let typeCheckFilter = this.cartList.filter(
    //   (e) => e.type == 3 || e.type == 4
    // );
    // if (typeCheckFilter.length > 0 && this.orders.mobileNo == "") {
    //   this.errorMessage = "Kindly enter the Mobile No";
    //   return false;
    // } else if (this.orders.supportStaff == "" && this.category_id == 4) {
    //   this.errorMessage = "Kindly Select the Support Staff name";
    // } else if (this.orders.paymentMode == "") {
    //   this.errorMessage = "Kindly Select the Payment Mode";
    // } else {
    //   if (this.onlineOffline) {
    //     let params =
    //       "userId=" +
    //       this.storage.get("userId") +
    //       "&password=" +
    //       this.storage.get("password") +
    //       "&businessId=" +
    //       this.storage.get("selectedBusinessId") +
    //       "&orderDetails=" +
    //       JSON.stringify(this.orders);
    //     if (this.category_id == 4) {
    //       params =
    //         params + "&packageItemRedeem=" + JSON.stringify(this.redeemArr);
    //     }
    //     this.httpClient
    //       .get(this.appComp.base_url + "pos/cartApi?" + params, {})
    //       .subscribe((res: any) => {
    //         if (res.status == 1) {
    //           this.storage.set("lastInvoiceId", res.invoiceId);
    //           this.removeOrder(this.orders);

    //           this.modalRef.close();
    //           if (type == 2) {
    //             if (this.electron) {
    //               let path = "/printBill_CD/" + res.orderId;
    //               this.router.navigate([path]);
    //             } else {
    //               if (this.parentBusinessId == 11) {
    //                 window.open("/printBill_CD/" + res.orderId);
    //               } else if (this.parentBusinessId == 188) {
    //                 window.open("/printBill_NF/" + res.orderId);
    //               } else {
    //                 let orderId = res.orderId.split(",");
    //                 if (orderId.length == 1) {
    //                   window.open("/printOnlineOrder/" + orderId[0]);
    //                 } else {
    //                   window.open("/printOnlineOrder/" + orderId[0]);
    //                   window.open("/printOnlineOrder/" + orderId[1]);
    //                 }
    //               }

    //               this.movedToOrderScreen();
    //             }
    //           } else {
    //             this.movedToOrderScreen();
    //           }
    //         } else {
    //           this.errorMessage = res.msg;
    //         }
    //       });
    //   } else {
    //     this.orders.asyncOrder = 1;
    //     let invoiceId = Number(this.storage.get("lastInvoiceId")) + 1;
    //     this.orders.invoiceId = invoiceId;
    //     if (type == 2) {
    //       if (this.parentBusinessId == 11) {
    //         window.open("/printBill_CD/" + this.orders.orderId, "_blank");
    //       } else {
    //         window.open("/printBill/" + this.orders.orderId, "_blank");
    //       }
    //     }

    //     this.storage.set("lastInvoiceId", invoiceId);
    //     let asyncOrders = [];
    //     if (this.storage.get("asyncOrders")) {
    //       asyncOrders = this.storage.get("asyncOrders");
    //     }
    //     asyncOrders.push(this.orders);
    //     this.storage.set("asyncOrders", asyncOrders);
    //     this.removeOrder(this.orders);
    //     this.modalRef.close();
    //     this.movedToOrderScreen();
    //   }
    // }
  }
  removeOrder(ordersDet) {
    let orderId = ordersDet.orderId;
    let index = this.ordersArray.findIndex((e) => e.orderId == orderId);
    this.ordersArray.splice(index, 1);
    let allocatedTables = this.storage.get("allocatedTable");
    if (allocatedTables != null) {
      index = allocatedTables.indexOf(ordersDet.tableId, 0);
      if (index > -1) {
        allocatedTables.splice(index, 1);
        this.storage.set("allocatedTable", allocatedTables);
      }
    }

    this.storage.set("existingOrders", this.ordersArray);
  }
  deleteOrder(id) {
    this.ordersArray.splice(id, 1);
    this.storage.set("existingOrders", this.ordersArray);
    this.modalRef.close();
    this.movedToOrderScreen();
  }
  movedToOrderScreen() {
    let path = "edge/order";
    this.router.navigate([path]);
  }
  sourceNavigation(source, navigationId) {
    // navigationId=1 for source navigation
    // navigationId=2 for type navigation

    if (navigationId == 1) {
      this.showOrderType = "true";
      this.newOrder.orderType = "0";
      this.newOrder.sourceId = 0;
      this.showDineIn = 0;
      this.showTakeAway = 0;
      this.showDelivery = 0;
      this.source = source.channel_name;
      this.sourceId = source.channelId;
      if (this.category_id == 4) {
        this.createNewOrders(4);
      }
      if (source.dine_in == 1) {
        if (source.take_away != 1 && source.delivery != 1) {
          this.createNewOrders(3);
        } else {
          this.showDineIn = 1;
        }
      } else {
        this.showDineIn = 0;
      }
      if (source.take_away == 1) {
        if (source.dine_in != 1 && source.delivery != 1) {
          this.createNewOrders(2);
        } else {
          this.showTakeAway = 1;
        }
      } else {
        this.showTakeAway = 0;
      }
      if (source.delivery == 1) {
        if (source.dine_in != 1 && source.take_away != 1) {
          this.orders.orderType = "1";
          // this.createNewOrders(1);
        } else {
          this.showDelivery = 1;
        }
      } else {
        this.showDelivery = 0;
      }
    } else if (navigationId == 2) {
      let sourceId = source;
      if (sourceId == 1) {
        this.createNewOrders(3);
      } else if (sourceId == 2) {
        this.createNewOrders(2);
      } else if (sourceId == 3) {
        this.newOrder.orderType = "1";
        this.newOrder.sourceId = this.sourceId;
        if (this.newOrder.mobileNo == "") {
          this.alertMessage = "Please Enter the Mobile No.";
          return false;
        } else {
          this.alertMessage = "";
        }
        this.getDeliveryAddress();
      }
    }
  }
  getDeliveryAddress() {
    if (this.orders.orderType == "1") {
      if (this.orders.mobileNo.length == 10) {
        let params =
          "userId=" +
          this.storage.get("userId") +
          "&password=" +
          this.storage.get("password") +
          "&businessId=" +
          this.storage.get("selectedBusinessId") +
          "&mobileNo=" +
          this.orders.mobileNo;
        this.httpClient
          .get(this.appComp.base_url + "pos/getAddress?" + params, {})
          .subscribe((res: any) => {
            if (res.status == "1") {
              res.data.forEach((element) => {
                this.deliveryAdd.deliveryAddressId = element.deliveryAddressId;
                this.deliveryAdd.addressLine1 = element.addressLine1;
                this.deliveryAdd.addressLine2 = element.addressLine2;
                this.deliveryAdd.city = element.city;
                this.deliveryAdd.state = element.state;
                this.deliveryAdd.pincode = element.pincode;
                this.deliveryAddArray.push(this.deliveryAdd);
                this.cd.detectChanges();
                this.deliveryAdd = new deliveryAddress();
              });
            }
            this.alertMessage = "";
            this.cd.markForCheck();
          });
      } else {
        this.alertMessage = "Please Enter the 10 digit Mobile No";
      }
    }
  }
  createNewOrders(type) {
    if (this.category_id == 4) {
      this.newOrder.orderType = "4";
    } else {
      this.newOrder.orderType = type;
    }

    this.newOrder.source = this.source;
    this.newOrder.sourceId = this.sourceId;

    this.newOrder.invoicedAt = this.getDate();
    this.newOrder.status = "0";
    this.newOrder.businessId = this.storage.get("selectedBusinessId");
    this.newOrder.orderId = this.randomNumber();

    this.cartList = Array<cartItems>();
    this.newOrder.cartDetails = this.cartList;

    this.ordersArray.push(this.newOrder);
    this.existingOrderArray.push(this.newOrder);
    this.storage.set("existingOrders", this.ordersArray);
    this.orders = this.newOrder;
    this.orders.cartDetails = [];
    this.newOrder = new newOrderDetails();
    this.modalRef.close();
    Swal.fire('', 'Order Created Successfully!', 'success')
    this.cd.markForCheck();
  }
  getTemplateItems(sectionId, templateModal, itemId) {
    this.templateItemParentDetailArray = new Array<templateItemDetails>();
    let index = this.templates.findIndex((e) => e.templateId == sectionId);
    let items = this.templates[index].template;
    items.forEach((element) => {
      if (element.parentId == 0) {
        let variant = element.items.filter((e) => e.parentId == element.id);
        if (variant.length > 0) {
          variant.forEach((element1) => {
            this.templateVariantDetail.id = element1.id;
            this.templateVariantDetail.itemName = element1.itemName;
            this.templateVariantDetail.description = element1.description;
            this.templateVariantDetail.sp = element1.sp;
            this.templateVariantDetail.status = element1.status;
            this.templateVariantDetail.mrp = element1.mrp;
            this.templateVariantDetail.actualCost = element1.actualCost;
            this.templateVariantDetail.unit = element1.unit;
            this.templateVariantDetail.costPerUnit = element1.costPerUnit;
            this.templateVariantDetail.quantity = element1.quantity;
            this.templateVariantDetail.parentId = element1.parentId;
            this.templateVariantDetail.type = element1.type;
            this.templateVariantDetail.tax = element1.tax;
            this.templateVariantDetail.templateId = element1.templateId;
            this.templateVariantDetail.inventoryAmount =
              element1.inventoryAmount;
            this.templateVariantDetail.parentItemId = itemId;
            this.templateItemDetail.variantDetails.push(
              this.templateVariantDetail
            );
            this.templateVariantDetail = new templateVariantDetails();
          });
        } else {
          this.templateVariantDetail.id = element.id;
          this.templateVariantDetail.itemName = element.itemName;
          this.templateVariantDetail.description = element.description;
          this.templateVariantDetail.sp = element.sp;
          this.templateVariantDetail.status = element.status;
          this.templateVariantDetail.mrp = element.mrp;
          this.templateVariantDetail.actualCost = element.actualCost;
          this.templateVariantDetail.unit = element.unit;
          this.templateVariantDetail.costPerUnit = element.costPerUnit;
          this.templateVariantDetail.quantity = element.quantity;
          this.templateVariantDetail.parentId = element.parentId;
          this.templateVariantDetail.tax = element.tax;
          this.templateVariantDetail.type = element.type;
          this.templateVariantDetail.templateId = element.templateId;
          this.templateVariantDetail.inventoryAmount = element.inventoryAmount;
          this.templateVariantDetail.parentItemId = itemId;
          this.templateItemDetail.variantDetails.push(
            this.templateVariantDetail
          );
          this.templateVariantDetail = new templateVariantDetails();
        }

        this.templateItemDetail.id = element.id;
        this.templateItemDetail.itemName = element.itemName;
        this.templateItemDetail.description = element.description;
        this.templateItemDetail.sp = element.sp;
        this.templateItemDetail.status = element.status;
        this.templateItemDetail.mrp = element.mrp;
        this.templateItemDetail.actualCost = element.actualCost;
        this.templateItemDetail.unit = element.unit;
        this.templateVariantDetail.costPerUnit = element.costPerUnit;
        this.templateItemDetail.tax = element.tax;
        this.templateItemDetail.type = element.type;
        this.templateItemDetail.quantity = element.quantity;
        this.templateItemDetail.parentId = element.parentId;
        this.templateItemDetail.templateId = element.templateId;
        this.templateItemDetail.parentItemId = itemId;
        this.templateVariantDetail.inventoryAmount = element.inventoryAmount;
        this.templateItemParentDetailArray.push(this.templateItemDetail);
        this.templateItemDetail = new templateItemDetails();
      }
    });
    this.cd.markForCheck();
    // let params ="userId=" + this.storage.get('userId') + "&password=" + this.storage.get('password')
    //   + "&businessId=" + this.storage.get('selectedBusinessId')+"&sectionId="+sectionId;
    //   this.httpClient.get(this.appComp.base_url+"ueapi/addoMenuItems/view?"+params,
    //   {}).subscribe(
    //   (res: any)=>{

    //      });
    //
    //   });
    this.openModal(templateModal, "lg");
  }
  addItemDiscount() {
    let i = this.disIndex
    // console.log(this.orders.cartDetails[i])
    this.disData = []
    this.disData.push({
      discountType: this.selectedItemCart.disType,
      discountValue: this.selectedItemCart.disValue,
      discountRemarks: this.selectedItemCart.discRemark,
    })
    // console.log(this.disData)
    if (this.disData[0].discountType == 'value') {
      let item = 0
      let maxDiscValue = 0
      // console.log(this.maxDiscountAllowed)
      if (this.cartList[i].quantity == 1) {
        maxDiscValue = (Number(this.cartList[i].costPerItem) * this.maxDiscountAllowed) / 100;
      } else {
        let cost = Number(this.cartList[i].costPerItem) * this.cartList[i].quantity
        maxDiscValue = (Number(cost) * this.maxDiscountAllowed) / 100;
      }
      // console.log(maxDiscValue)
      if (Number(this.disData[0].discountValue) > Number(maxDiscValue)) {
        Swal.fire('', 'Discount value cannot be greater than ' + this.maxDiscountAllowed + '% of item value.', 'error')
        return false;
      }
      // else if(Number(this.disData[0].discountValue) > this.cartList[i].cost){
      //   Swal.fire('', 'Discount value cannot be greater than the item value', 'error')
      //   return false;
      // }
      else {
        this.orders.cartDetails[i].discRemark = this.disData[0].discountRemarks
        this.orders.cartDetails[i].disType = this.disData[0].discountType
        this.orders.cartDetails[i].disValue = this.disData[0].discountValue
        item = Number(this.disData[0].discountValue)
        // console.log(item)
        this.orders.tax = 0
        let totalAmt = this.orders.totalAmt
        let subTotal = this.orders.subtotal
        this.orders.cartDetails[i].discount = item
        this.cartList[i].discount = item
        let newprice = (Number(this.orders.cartDetails[i].costPerItem) * Number(this.orders.cartDetails[i].quantity)) - item
        // console.log(newprice)
        this.orders.cartDetails[i].cost = newprice
        this.cartList[i].cost = newprice
        // let taxes = this.orders.tax;
        let totalDiscount = 0
        let newAmountAfterDiscount
        this.orders.totalAmt = (this.orders.subtotal + this.orders.tax) - this.orders.discount
        this.orders.discountedAmount = this.orders.discount
        this.orders.cartDetails.forEach((element) => {
          // console.log(element)
          let tax = 0
          totalDiscount += Number(element.discount)
          // itemValue = Number(this.orders.cartDetails[i].costPerItem) * Number(this.orders.cartDetails[i].quantity)
          tax = (element.cost * element.tax) / 100
          this.orders.tax += tax
          // this.orders.discount += Number(this.orders.cartDetails[i].discount)
        })
        // console.log(Number(taxes))
        this.orders.discountedAmount = totalDiscount
        this.orders.discount = totalDiscount
        newAmountAfterDiscount = subTotal - totalDiscount + Number(this.orders.tax);
        // console.log(newAmountAfterDiscount)
        this.orders.totalAmt = newAmountAfterDiscount;
        this.orders.roundedTotalAmt = Math.round(this.orders.totalAmt)
        if (this.orders.totalAmt > this.orders.roundedTotalAmt) {
          this.orders.roundDiff = this.orders.totalAmt - this.orders.roundedTotalAmt
        } else {
          this.orders.roundDiff = this.orders.roundedTotalAmt - this.orders.totalAmt
        }
        // this.adjustPayment = this.orders.totalAmt.toString()
        // console.log(this.orders.roundedTotalAmt)
        // console.log(this.orders.roundDiff)
        let orderIndex = this.ordersArray.findIndex(
          (e) => e.orderId == this.orders.orderId
        );
        // this.orders.updatedAt = this.getDate();
        if (orderIndex > -1) {
          this.ordersArray[orderIndex] = this.orders;
        } else {
          this.ordersArray.push(this.orders);
        }
        // console.log(this.ordersArray)
        this.storage.set("existingOrders", this.ordersArray);
        this.cart = new cartItems();
        this.modalRef.close()
        // console.log(this.orders.cartDetails[i])
      }
    }
    else if (this.disData[0].discountType == 'percentage') {
      let price = 0
      if (Number(this.disData[0].discountValue) > this.maxDiscountAllowed) {
        Swal.fire('', 'Discount percentage cannot be greater than ' + this.maxDiscountAllowed + "%", 'error')
        return false;
      }
      if (this.cartList[i].quantity == 1) {
        price = (this.disData[0].discountValue * this.cartList[i].costPerItem) / 100
      }
      else {
        let cost = Number(this.cartList[i].costPerItem) * this.cartList[i].quantity
        price = (this.disData[0].discountValue * cost) / 100
      }
      // if (price > this.cartList[i].cost) {
      //   Swal.fire('', 'Discount value cannot be greater than the item value.', 'error')
      //   return false;
      // } 
      this.orders.cartDetails[i].discRemark = this.disData[0].discountRemarks
      this.orders.cartDetails[i].disType = this.disData[0].discountType
      this.orders.cartDetails[i].disValue = this.disData[0].discountValue
      let totalAmt = this.orders.totalAmt
      let subTotal = this.orders.subtotal
      this.orders.cartDetails[i].discount = price
      this.cartList[i].discount = price
      let newprice = (Number(this.orders.cartDetails[i].costPerItem) * Number(this.orders.cartDetails[i].quantity)) - price
      // console.log(newprice)
      this.orders.cartDetails[i].cost = newprice
      this.cartList[i].cost = newprice
      this.orders.tax = 0
      let taxes = this.orders.tax;
      let totalDiscount = 0
      let newAmountAfterDiscount
      this.orders.cartDetails.forEach((element) => {
        // console.log(element)
        let tax = 0
        totalDiscount += Number(element.discount)
        // itemValue = Number(this.orders.cartDetails[i].costPerItem) * Number(this.orders.cartDetails[i].quantity)
        tax = (element.cost * element.tax) / 100
        this.orders.tax += tax
        // this.orders.discount += Number(this.orders.cartDetails[i].discount)
      })
      // console.log(Number(taxes))
      this.orders.discountedAmount = totalDiscount
      this.orders.discount = totalDiscount
      newAmountAfterDiscount = subTotal - totalDiscount + Number(this.orders.tax);
      // console.log(newAmountAfterDiscount)
      this.orders.totalAmt = newAmountAfterDiscount;
      this.orders.roundedTotalAmt = Math.round(this.orders.totalAmt)
      if (this.orders.totalAmt > this.orders.roundedTotalAmt) {
        this.orders.roundDiff = this.orders.totalAmt - this.orders.roundedTotalAmt
      } else {
        this.orders.roundDiff = this.orders.roundedTotalAmt - this.orders.totalAmt
      }
      // this.adjustPayment = this.orders.totalAmt.toString()
      // console.log(this.orders)
      let orderIndex = this.ordersArray.findIndex(
        (e) => e.orderId == this.orders.orderId
      );
      // this.orders.updatedAt = this.getDate();
      if (orderIndex > -1) {
        this.ordersArray[orderIndex] = this.orders;
      } else {
        this.ordersArray.push(this.orders);
      }
      // console.log(this.ordersArray)
      this.storage.set("existingOrders", this.ordersArray);
      this.cart = new cartItems();
      this.modalRef.close()

    }
    // if (this.paymentType == "membership") {
    //   this.completeExistingOrder('MS', '', true)
    // }
    // this.updateSalonCart()

    if (this.pointFlag == true) {
      this.applyPoints(true)
    } else {
      this.applyPoints(false)
    }
    // console.log(this.disData)
  }
  addDiscount(item, i) {
    //  console.log(item)
    if (Number(item) > this.cartList[i].costPerItem) {
      this.toastr.error("Discount value cannot be greater than the item value!", 'Error', {
        positionClass: 'toast-bottom-right'
      })
      return false;
    } else {
      //  console.log(this.orders)
      let totalAmt = this.orders.totalAmt
      let subTotal = this.orders.subtotal
      this.orders.cartDetails[i].discount = item
      this.cartList[i].discount = item
      let newprice = (Number(this.orders.cartDetails[i].costPerItem) * Number(this.orders.cartDetails[i].quantity)) - item
      // console.log(newprice)
      this.orders.cartDetails[i].cost = newprice
      this.cartList[i].cost = newprice
      let taxes = this.orders.tax;
      let totalDiscount = 0
      let newAmountAfterDiscount
      this.orders.cartDetails.forEach((element) => {
        // console.log(element)
        totalDiscount += Number(element.discount)
      })
      // console.log(Number(taxes))
      this.orders.discountedAmount = totalDiscount
      this.orders.discount = totalDiscount
      newAmountAfterDiscount = subTotal - totalDiscount + Number(taxes);
      // console.log(newAmountAfterDiscount)
      this.orders.totalAmt = newAmountAfterDiscount;
      // this.orders.totalAmt = Math.round(this.orders.totalAmt)
      // console.log(this.orders)
      let orderIndex = this.ordersArray.findIndex(
        (e) => e.orderId == this.orders.orderId
      );
      // this.orders.updatedAt = this.getDate();
      if (orderIndex > -1) {
        this.ordersArray[orderIndex] = this.orders;
      } else {
        this.ordersArray.push(this.orders);
      }
      // console.log(this.ordersArray)
      this.storage.set("existingOrders", this.ordersArray);
      this.cart = new cartItems();
    }
  }
  // totalDisc(){

  // }
  // opentotalDiscModal(modal){
  //   this.openModal(modal,'md')
  // }
  removeItem(id) {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes! Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value) {
        this.orderId = id
        if (this.category_id == 4) {
          // console.log(this.orders.cartDetails[id])
          if (this.orders.cartDetails[id].packingMappingId != 0) {
            for (let i = 0; i < this.packageDetailsArr.length; i++) {
              for (let j = 0; j < this.packageDetailsArr[i].items.length; j++) {
                if (this.orders.cartDetails[id].packingMappingId == this.packageDetailsArr[i].items[j].packingMappingId) {
                  this.packageDetailsArr[i].items[j].redeemed = 0;
                }
              }
            }
            // let index = this.packageDetailsArr.findIndex(e => e.packingMappingId == this.orders.cartDetails[id].packingMappingId)
            // this.packageDetailsArr[index].redeemed = 0;
          }
        }
        let notApp
        this.orders.cartDetails.splice(id, 1);
        this.cartList.splice(id, 1);
        if (this.cartList.length == 0) {
          this.orders.walletPoints = 0
          this.showPartial = 0
          this.pointFlag = false
        } else {
          for (let i = 0; i < this.orders.cartDetails.length; i++) {
            if (this.orders.cartDetails[i].type != 4) {
              notApp = 1
              break;
            }
          }
          if (notApp == 1) {
            this.showPartial = 0
          }
          else {
            //  this.partialPayment=1
            this.showPartial = 1
          }
        }
        if (this.category_id == 4) {
          // this.updateSalonCart()
          if (this.pointFlag == true) {
            this.applyPoints(true)
          } else {
            this.applyPoints(false)
          }
        } else if (this.category_id != 4 && this.businessId != 6327) {
          this.orders.totalAmt = 0
          this.orders.subtotal = 0
          this.orders.discount = 0
          this.orders.tax = 0
          // console.log(this.orders.cartDetails)
          for (let i = 0; i < this.orders.cartDetails.length; i++) {
            let itemValue = 0
            let tax = 0
            itemValue = Number(this.orders.cartDetails[i].costPerItem) * Number(this.orders.cartDetails[i].quantity)
            tax = (Number(this.orders.cartDetails[i].cost) * Number(this.orders.cartDetails[i].tax)) / 100;
            this.orders.subtotal += Number(itemValue)
            this.orders.tax += tax
            this.orders.discount += Number(this.orders.cartDetails[i].discount)
          }
          this.orders.totalAmt = (Number(this.orders.subtotal) + Number(this.orders.tax)) - Number(this.orders.discount)
          this.orders.roundedTotalAmt = Math.round(this.orders.totalAmt)
          if (this.orders.totalAmt > this.orders.roundedTotalAmt) {
            this.orders.roundDiff = this.orders.totalAmt - this.orders.roundedTotalAmt
          } else {
            this.orders.roundDiff = this.orders.roundedTotalAmt - this.orders.totalAmt
          }
          // this.orders.totalAmt = Math.round(this.orders.totalAmt)
          this.orders.discountedAmount = this.orders.discount
          this.orders.itemCount = this.cartList.length;

          let orderIndex = this.ordersArray.findIndex(
            (e) => e.orderId == this.orders.orderId
          );
          this.orders.updatedAt = this.getDate();
          if (orderIndex > -1) {
            this.ordersArray[orderIndex] = this.orders;
          } else {
            this.ordersArray.push(this.orders);
          }
          // console.log(this.ordersArray)
          this.storage.set("existingOrders", this.ordersArray);
          this.updateSalonCart()
          // this.applyPoints('')
          // if (this.paymentType == "membership") {
          //   this.completeExistingOrder('MS', '', true)
          // }
          this.cd.detectChanges();
        }
        else {
          this.updateTotalDetails();
        }
        this.cd.markForCheck();
      }
    });
  }

  updateTotalDetails() {
    this.totalAmount = 0;
    this.totalTax = 0;
    let discount = 0;
    // this.orders.cartDetails[this.orderId].discount=0
    // console.log(this.cartList)
    if (this.cartList.length > 0) {
      this.cartList.forEach((element) => {
        if (element.templateCartDetails.length > 0) {
          element.templateCartDetails.forEach((element1) => {
            this.totalAmount += Number(element1.cost);
            this.totalTax += Number(element1.tax);
          });
        } else {
          // console.log(element)
          if (this.category_id == 4) {
            this.totalAmount += Number(element.cost);
            discount += Number(element.discount)
            this.totalTax += Number(element.tax);
          } else {
            this.totalAmount += Number(element.costPerItem);
            discount += Number(element.discount)
            this.totalTax += Number(element.tax);
          }
        }
        // console.log(discount)
        this.orders.subtotal = this.totalAmount;
        if (this.serviceCharges > 0) {
          this.orders.serviceCharges =
            (Number(this.orders.subtotal) * this.serviceCharges) / 100;
        }

        this.orders.discountedAmount = discount;
        this.orders.tax = Math.round(this.totalTax);

        this.orders.totalAmt =
          Math.round(Number(this.orders.tax + this.orders.subtotal)) +
          Number(this.orders.packingCharges) +
          Number(this.orders.serviceCharges) - discount;
      });
    }
    else {
      this.orders.subtotal = 0;
      this.orders.tax = 0;
      this.orders.discountedAmount = 0;
      this.orders.totalAmt = 0;
      this.orders.serviceCharges = 0;
    }

    this.orders.itemCount = this.cartList.length;

    let orderIndex = this.ordersArray.findIndex(
      (e) => e.orderId == this.orders.orderId
    );
    this.orders.updatedAt = this.getDate();
    if (orderIndex > -1) {
      this.ordersArray[orderIndex] = this.orders;
    } else {
      this.ordersArray.push(this.orders);
    }
    // console.log(this.ordersArray)
    this.storage.set("existingOrders", this.ordersArray);
    this.cd.detectChanges();
  }
  removeTemplateItem(itemIndex, templateIndex) {
    this.cartList[itemIndex].templateCartDetails.splice(templateIndex, 1);
    this.updateTotalDetails();
    this.cd.markForCheck();
  }

  updateText(num) {
    this.quantityModel = this.quantityModel + num;
  }
  updateQtyModal(modalName, size, item, index, taxPer) {
    this.selectedItemDetails = item;
    this.selectedItemIndex = index;
    this.quantityModel = Number(this.orders.cartDetails[index].quantity);
    this.openModal(modalName, size);
  }
  updateTriggerModal() {
    let qty = this.quantityModel;
    this.updateCart(qty, this.selectedItemDetails, this.selectedItemIndex);
    // this.markItemComplimentary(this.selectedItemIndex);
    this.modalRef.close();
    this.quantityModel = 0;
    this.noOfOrderMarkComplimentary = 0;
    this.selectedItemDetails = [];
    this.selectedItemIndex = "";
    this.selectedItemTax = "";
  }
  scrollToSection(sectionId) {
    let id = "section" + sectionId;
    // console.log(document.getElementById(id))
    document.getElementById(id).scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
  }
  specialIns(id, tagName) {
    if (tagName == "specialInstruction") {
      let specialInstruction = this.specialInstruction[id];
      this.orders.cartDetails[id].specialInstruction = specialInstruction;
      this.cartList[id].specialInstruction = specialInstruction;
    }
    let orderIndex = this.ordersArray.findIndex(
      (e) => e.orderId == this.orders.orderId
    );
    this.orders.updatedAt = this.getDate();
    if (orderIndex > -1) {
      this.ordersArray[orderIndex] = this.orders;
    } else {
      this.ordersArray.push(this.orders);
    }
    this.storage.set("existingOrders", this.ordersArray);
  }

  printKOT(orderId) {
    window.open("/printKOT/" + orderId);
  }
  addOpenItems() {
    this.cart.itemId = this.openItems.itemId;
    this.cart.itemName = this.openItems.itemName;
    this.cart.cost = this.openItems.sp;
    this.cart.quantity = 1;
    this.cart.type = 1;
    this.cart.exciseApplicable = 0;
    this.cart.costPerItem = this.openItems.sp;
    this.cart.foodCategory = this.openItems.foodCategory;
    this.cart.tax = Math.round(
      Number((this.openItems.sp * this.openItems.tax) / 100)
    );
    this.cart.taxPer = Number(this.openItems.tax);
    this.cartList.push(this.cart);

    this.orders.cartDetails.push(this.cart);
    this.cart = new cartItems();
    this.updateTotalDetails();
  }
  openComplimentaryModal(index, modalName) {
    this.selectedItemIndex = index;
    this.openModal(modalName, "md");
  }
  markItemComplimentary() {
    let index = this.selectedItemIndex;
    if (this.noOfOrderMarkComplimentary > this.cartList[index].quantity) {
      alert("Complimentary items should be less than actual quantity");
      return false;
    }
    let discount =
      Number(this.cartList[index].costPerItem) *
      Number(this.noOfOrderMarkComplimentary);
    let cost = this.cartList[index].cost - discount;
    this.cartList[index].cost = cost;
    this.cartList[index].complimentaryMarked = 1;
    this.cartList[index].tax = Math.round(
      Number((cost * this.cartList[index].taxPer) / 100)
    );
    this.orders.cartDetails[index].cost = cost;
    this.orders.cartDetails[index].complimentaryMarked = 1;
    this.orders.cartDetails[index].comments =
      this.noOfOrderMarkComplimentary + " Marked Complimentary";
    this.orders.cartDetails[index].tax = Math.round(
      Number((cost * this.orders.cartDetails[index].taxPer) / 100)
    );
    this.updateTotalDetails();
    this.modalRef.close();
  }
  counter(i: number) {
    return new Array(i);
  }

  openOrder(orderId) {
    this.orders = this.ordersArray.filter((e) => e.orderId == orderId)[0];
    if (
      this.orders.cartDetails != undefined &&
      this.orders.cartDetails.length > 0
    ) {
      this.orders.cartDetails.forEach((element) => {
        element.templateCartDetails.forEach((ele) => {
          this.templateCart.itemId = ele.itemId;
          this.templateCart.cost = ele.cost;
          this.templateCart.quantity = ele.quantity;
          this.templateCart.itemName = ele.itemName;
          this.templateCart.costPerItem = ele.costPerItem;
          this.templateCart.tax = ele.tax;
          this.templateCart.taxPer = ele.taxPer;
          this.cart.templateCartDetails.push(this.templateCart);
          this.templateCart = new cartItems();
        });
        this.cart.itemId = element.itemId;
        this.cart.cost = element.cost;
        this.cart.quantity = Number(element.quantity);
        this.cart.itemName = element.itemName;
        this.cart.costPerItem = element.costPerItem;
        this.cart.tax = element.tax;
        this.cart.exciseApplicable = element.exciseApplicable;
        this.cart.taxPer = element.taxPer;
        this.cart.workerName = element.workerName;
        this.cart.workerId = element.workerId;
        this.cart.discount = element.discount;
        this.cart.type = element.type;
        this.cart.specialInstruction = element.specialInstruction;
        this.specialInstruction.push(element.specialInstruction);
        this.cartList.push(this.cart);
        this.cart = new cartItems();
      });
    }

    this.modalRef.close();
  }
  customCloseNodel() {
    this.modalRef.close();
  }
  showWeight(item, i) {

    // var abc;

    // if (this.childProcessService.isElectronApp == true) {
    //   this.getWeightLoader = true;
    //   this.getWeightServiceService.getFiles().then((res) => {
    //     abc = res;
    //     let arr = new TextDecoder().decode(abc);

    //     item.quantity = Number(arr);

    //     this.getWeightLoader = false;
    //     this.cd.detectChanges();
    //     this.qtyChange(item, i);
    //   });
    // } else {
    //   alert("You are not using electron. Please Enter the Quantity manually");
    // }
    // let arr = new TextDecoder().decode(abc);
    // electron.ipcRenderer.send("navigateDirectory", {});
  }

  getSubtotal(sub) {
    return Math.round((sub + Number.EPSILON) * 100) / 100;
  }
  brandClick(evt) {
    // console.log(evt)
  }
  public logout() {
    // this.storage.remove("userName");
    // this.storage.remove("password");
    // this.storage.remove("userId");
    // this.storage.remove("selectedBusinessId");
    let isBuzz = this.storage.get("isBuzz");
    let isGuide = this.storage.get("isGuide");
    localStorage.clear();
    this.storage.set("isBuzz", isBuzz);
    this.storage.set("isGuide", isGuide);
    let path = "";
    this.router.navigate([path]);
  }
  routeBack() {
    let path = "businessSelector";
    this.router.navigate([path]);
  }
  back() {
    this.router.navigate(['/edge/order']).then(() => {
      this.storage.remove('void_order_id');
    });
  }
  showSection(data) {
    this.itemsFilter = { itemName: "" };
    this.id = data.businessId
    this.clicked = 1;
    let parentId = this.storage.get("selectedBusinessId");
    this.menu = []
    this.templates = []
    let url =
      "https://api.uengage.in/petPooja/getBusinessFeed_new/" + data.businessId + "/" + parentId;
    this.httpClient.get(url, {}).subscribe(
      (res: any) => {
        this.storage.set("menu", res);
        this.menu = res.rows.menu;
        // this.listClick(this.menu[0])
        this.templates = res.rows.templates;
        // console.log(this.templates)
      },
      (error) => {
      }
    );
    // console.log(this.templates)

  }
  checkMembership() {
    this.membershipData = []
    // console.log(this.orders.mobileNo)
    // if (this.orders.mobileNo.toString().length == 10) {
    // console.log("valid")
    this.showMem = 1
    let params =
      "userId=" +
      this.storage.get("userId") +
      "&password=" +
      this.storage.get("password") +
      "&businessId=" +
      this.storage.get("selectedBusinessId") +
      "&mobileNo=" + this.orders.mobileNo;
    this.httpClient
      .get(this.appComp.base_url + "pos/getBillingDetails?" + params, {})
      .subscribe((res: any) => {
        if (res.status == 1) {
          this.membershipData = res.membership
          this.cd.detectChanges()
        } else {
          Swal.fire('', res.msg, 'error')
        }
      });
    // } else {
    //   this.showMem = 0
    //   // console.log("invalid")
    // }
  }
  stylistChange(item, i) {
    this.cartList[i].workerName = item
    this.orders.cartDetails[i].workerName = item

    let orderIndex = this.ordersArray.findIndex(
      (e) => e.orderId == this.orders.orderId
    );
    // this.orders.updatedAt = this.getDate();
    if (orderIndex > -1) {
      this.ordersArray[orderIndex] = this.orders;
    } else {
      this.ordersArray.push(this.orders);
    }
    // console.log(this.ordersArray)
    this.storage.set("existingOrders", this.ordersArray);
    this.cart = new cartItems();
    // console.log(this.cartList)
  }
  closePOSOrder() {
    this.finalArr = []
    // console.log(this.orders.cartDetails)
    // return
    for (let i = 0; i < this.orders.cartDetails.length; i++) {
      if (this.finalArr.length == 0) {
        this.finalArr.push({
          bId: this.orders.cartDetails[i].bId,
          items: []
        });
        this.finalArr[0]['items'].push({
          itemId: this.orders.cartDetails[i].itemId,
          qty: this.orders.cartDetails[i].quantity,
          discount: this.orders.cartDetails[i].discount,
          subItems: []
        })
        if (this.orders.cartDetails[i].templateCartDetails.length != 0 && this.orders.cartDetails[i].templateCartDetails != undefined) {
          let itemIndex = this.finalArr[0].items.findIndex(e => e.itemId == this.orders.cartDetails[i].itemId)
          for (let j = 0; j < this.orders.cartDetails[i].templateCartDetails.length; j++) {
            this.finalArr[0]['items'][itemIndex]['subItems'].push({
              itemId: this.orders.cartDetails[i].templateCartDetails[j].itemId,
              qty: this.orders.cartDetails[i].templateCartDetails[j].quantity,
              sp: this.orders.cartDetails[i].templateCartDetails[j].costPerItem,
            })
          }
        }
      }
      else {
        let index = this.finalArr.findIndex(e => e.bId == this.orders.cartDetails[i].bId);
        if (index > -1) {
          this.finalArr[index]['items'].push({
            itemId: this.orders.cartDetails[i].itemId,
            qty: this.orders.cartDetails[i].quantity,
            discount: this.orders.cartDetails[i].discount,
            subItems: []
          })
          // console.log(this.finalArr[index])
          if (this.orders.cartDetails[i].templateCartDetails.length != 0 && this.orders.cartDetails[i].templateCartDetails != undefined) {
            let itemIndex = this.finalArr[0].items.findIndex(e => e.itemId == this.orders.cartDetails[i].itemId)
            for (let j = 0; j < this.orders.cartDetails[i].templateCartDetails.length; j++) {
              this.finalArr[index]['items'][itemIndex]['subItems'].push({
                itemId: this.orders.cartDetails[i].templateCartDetails[j].itemId,
                qty: this.orders.cartDetails[i].templateCartDetails[j].quantity,
                sp: this.orders.cartDetails[i].templateCartDetails[j].costPerItem,
              })
            }
          }
        }
        else {
          this.finalArr.push({
            bId: this.orders.cartDetails[i].bId,
            // bName: this.orders.cartDetails[i].bName,
            items: []
          });
          this.finalArr[this.finalArr.length - 1]['items'].push({
            itemId: this.orders.cartDetails[i].itemId,
            qty: this.orders.cartDetails[i].quantity,
            discount: this.orders.cartDetails[i].discount,
          })
        }
      }
    }
    for (let i = 0; i < this.finalArr.length; i++) {
      for (let j = 0; j < this.finalArr[i].items.length; j++) {
        if (this.finalArr[i].items[j].itemId == 0) {
          this.toastr.error("Please provide all detials!", 'Error', {
            positionClass: 'toast-bottom-right'
          })
          return false;
        }
      }
    }
    // console.log(this.finalArr)
    // return
    if (this.paymentType == '') {
      this.toastr.error("Please select payment mode !", 'Error', {
        positionClass: 'toast-bottom-right'
      })
      // Swal.fire('','Please select payment mode','error')
      return false;
    } else {
      let mobile
      let name
      if (this.orders.mobileNo == null || this.orders.mobileNo == undefined) {
        mobile = ''
      } else {
        mobile = this.orders.mobileNo
      }
      if (this.orders.customerName == null || this.orders.customerName == undefined) {
        name = ''
      } else {
        name = this.orders.customerName
      }
      let params = new HttpParams({
        fromObject: {
          userId: this.storage.get("userId"),
          password: this.storage.get("password"),
          businessId: this.storage.get("selectedBusinessId"),
          json_pos: JSON.stringify(this.finalArr),
          total: this.orders.totalAmt.toString(),
          customerMobileNo: mobile,
          customerName: name,
          mode: this.paymentType,
          isGSTInclusive: this.orders.isGstInclusive ? '1' : '0',
        }
      });
      // console.log(this.finalArr)
      let httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      };
      let url = "https://api.uengage.in/pos/closeOrder"
      // this.spinnerService.show();
      this.httpClient.post(url, params, httpOptions).subscribe((res: any) => {
        // console.log(res.msg)
        if (res.status == 0) {
          // this.spinnerService.hide();
          Swal.fire('', res.msg, 'error')
          return false;
        }
        else if (res.status == 1) {
          // this.spinnerService.hide();
          // this.modalRef.close()
          this.orderData = res.orders
          // if(this.category_id == 4){
          this.orderData[0].orderDetails[0].netAmt = Number(this.orderData[0].orderDetails[0].subTotal)
          this.orderData[0].orderDetails[0].totalAmt = Number(this.orderData[0].orderDetails[0].subTotal) + Number(this.orderData[0].orderDetails[0].taxes)
          this.orderData[0].orderDetails[0].subTotal = Number(this.orderData[0].orderDetails[0].subTotal) + Number(this.orderData[0].orderDetails[0].taxes) + Number(this.orderData[0].orderDetails[0].discount)
          // }
          this.qrString = this.orderData[0].orderDetails[0].orderId + "||" + this.orderData[0].orderDetails[0].contactMappingId
          // console.log(this.qrString)
          this.cd.detectChanges()
          // console.log(this.qrString)
          // this.showPrint = 1
          this.print()
        }
      });
    }
  }
  getPayment(value) {
    if (this.paymentType == 'split') {
      Swal.fire({
        title: "Are you sure ?",
        text: "Split Payment Data Will Be Lost.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          this.paymentType = value
          this.paymentList = []
          this.splitArea = 0
          this.cd.detectChanges()
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.paymentType = "split"
          this.cd.detectChanges()
          Swal.fire('', "Cancelled", "error");
        }
      });
    }
    else {
      this.paymentType = value
      this.cd.detectChanges()
    }
  }
  close() {
    this.finalArr = []
    this.mobileMandatory = 0
    // console.log(this.orders.cartDetails)
    for (let i = 0; i < this.orders.cartDetails.length; i++) {
      if (this.finalArr.length == 0) {
        this.finalArr.push({
          bId: this.orders.cartDetails[i].bId,
          // bName: this.orders.cartDetails[i].bName,
          items: []
        });
        if (this.category_id == 4) {
          this.finalArr[0]['items'].push({
            itemId: this.orders.cartDetails[i].itemId,
            type: this.orders.cartDetails[i].type,
            qty: this.orders.cartDetails[i].quantity,
            stylistName: this.orders.cartDetails[i].workerId,
            discount: this.orders.cartDetails[i].discount
          })
        } else {
          this.finalArr[0]['items'].push({
            itemId: this.orders.cartDetails[i].itemId,
            qty: this.orders.cartDetails[i].quantity
          })
        }
      } else {
        // for (let i = 1; i < this.orders.cartDetails.length; i++) {

        let index = this.finalArr.findIndex(e => e.bId == this.orders.cartDetails[i].bId);
        if (index > -1) {
          if (this.category_id == 4) {
            this.finalArr[index]['items'].push({
              itemId: this.orders.cartDetails[i].itemId,
              qty: this.orders.cartDetails[i].quantity,
              type: this.orders.cartDetails[i].type,
              stylistName: this.orders.cartDetails[i].workerId,
              discount: this.orders.cartDetails[i].discount
            })
          } else {
            this.finalArr[index]['items'].push({
              itemId: this.orders.cartDetails[i].itemId,
              qty: this.orders.cartDetails[i].quantity
            })
          }
        } else {
          this.finalArr.push({
            bId: this.orders.cartDetails[i].bId,
            // bName: this.orders.cartDetails[i].bName,
            items: []
          });
          if (this.category_id == 4) {
            this.finalArr[this.finalArr.length - 1]['items'].push({
              itemId: this.orders.cartDetails[i].itemId,
              qty: this.orders.cartDetails[i].quantity,
              type: this.orders.cartDetails[i].type,
              stylistName: this.orders.cartDetails[i].workerId,
              discount: this.orders.cartDetails[i].discount
            })
          } else {
            this.finalArr[this.finalArr.length - 1]['items'].push({
              itemId: this.orders.cartDetails[i].itemId,
              qty: this.orders.cartDetails[i].quantity
            })
          }
        }

      }
    }
    if (this.category_id == 4) {
      for (let i = 0; i < this.finalArr.length; i++) {
        for (let j = 0; j < this.finalArr[i].items.length; j++) {
          if (this.finalArr[i].items[j].itemId == 0 || this.finalArr[i].items[j].stylistName == '') {
            this.toastr.error("Please provide all detials!", 'Error', {
              positionClass: 'toast-bottom-right'
            })
            return false;
          }
        }
      }
      // for(let i=0;i<this.finalArr.length;i++){
      //   for(let j=0;j<this.finalArr[i].items.length;j++){
      //     if(this.finalArr[i].items[j].type==3 || this.finalArr[i].items[j].type==4){
      //       this.mobileMandatory=1
      //       break;
      //     }
      //   }
      // }
    }
    // console.log(this.finalArr)
    // if(this.mobileMandatory==1 && this.orders.mobileNo==''){
    //     Swal.fire('','Please enter customer mobile number to continue!','error')
    //     return false;
    // }
    if (this.paymentType == '') {
      this.toastr.error("Please select payment mode !", 'Error', {
        positionClass: 'toast-bottom-right'
      })
      // Swal.fire('','Please select payment mode','error')
      return false;
    } else {
      let params = new HttpParams({
        fromObject: {
          userId: this.storage.get("userId"),
          password: this.storage.get("password"),
          businessId: this.storage.get("selectedBusinessId"),
          json_pos: JSON.stringify(this.finalArr),
          total: this.orders.totalAmt.toString(),
          roundedAmount: this.orders.roundedTotalAmt.toString(),
          customerMobileNo: this.orders.mobileNo.toString(),
          customerName: this.orders.customerName.toString(),
          membership_used: this.membershipUsed.toString(),
          mode: this.paymentType
        }
      });
      // console.log(this.finalArr)
      let httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      };
      let url = "https://api.uengage.in/pos/closeOrder"
      // this.spinnerService.show();
      this.httpClient.post(url, params, httpOptions).subscribe((res: any) => {
        // console.log(res.msg)
        if (res.status == 0) {
          // this.spinnerService.hide();
          Swal.fire('', res.msg, 'error')
          return false;
        }
        else if (res.status == 1) {
          // this.spinnerService.hide();
          this.modalRef.close()
          this.orderData = res.orders
          // console.log("manual")
          this.showPrint = 1

          this.print()
        }
      });
    }
    // console.log(this.finalArr)

    // this.cd.detectChanges()

  }
  print() {
    if (this.deviceType == 'web') {
      let element: HTMLElement = document.getElementById("print_div") as HTMLElement;
      element.click()
    } else {
      let printContents = document.getElementById('printInvoice').innerHTML;
      // console.log(printContents)
      const popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
            <title>Print Page</title>
            <style>
              /* Custom styles for printing */
            </style>
          </head>
          <body onload="window.print();">
            ${printContents}
          </body>
        </html>`
      );
      popupWin.document.close();
    }
    this.removeOrder(this.orders);
    this.orderData = []
    this.showPrint = 0
  }
  openDisModal(data, modal) {
    this.openModal(modal, 'sm')
  }
  addService(event, index) {
    let length = this.cartList.length
    // console.log(event)
    // console.log(this.orders.cartDetails)
    if (length == 1) {
      if (event.itemData.type == 4) {
        this.showPartial = 1
      } else {
        this.showPartial = 0
      }
    } else {
      this.showPartial = 0
    }
    if (this.cartList[index].packingMappingId == 0) {
      if (length == 1) {
        // console.log(this.orders.cartDetails)
        this.cartList[0].cost = event.itemData.cost
        this.cartList[0].costPerItem = event.itemData.cost
        // this.cartList[0].discount = 0
        this.cartList[0].itemId = event.itemData.itemId
        this.cartList[0].itemName = event.itemData.itemName
        this.cartList[0].quantity = 1
        this.cartList[0].tax = event.itemData.tax
        this.cartList[0].workerName = ''
        this.cartList[0].workerId = ''
        this.cartList[0].item = event.itemData.item;
        this.cartList[0].bId = this.id;
        this.cartList[0].type = event.itemData.type;
        this.cartList[0].packingMappingId = 0;
        this.cartList[0].packageName = ''
        this.cartList[0].isGSTInclusive = event.itemData.isGSTInclusive ? event.itemData.isGSTInclusive : false
        // this.cartList[0].packageName = ''

        this.orders.cartDetails[0].cost = event.itemData.cost
        this.orders.cartDetails[0].costPerItem = event.itemData.cost
        // this.orders.cartDetails[0].discount = 0
        this.orders.cartDetails[0].itemId = event.itemData.itemId
        this.orders.cartDetails[0].itemName = event.itemData.itemName
        this.orders.cartDetails[0].quantity = 1
        this.orders.cartDetails[0].tax = event.itemData.tax
        this.orders.cartDetails[0].workerName = ''
        this.orders.cartDetails[0].workerId = ''
        this.orders.cartDetails[0].item = event.itemData.item;
        this.orders.cartDetails[0].type = event.itemData.type;
        this.orders.cartDetails[0].packingMappingId = 0
        this.orders.cartDetails[0].packageName = ''
        this.orders.cartDetails[0].isGSTInclusive = event.itemData.isGSTInclusive ? event.itemData.isGSTInclusive : false
        // console.log(this.orders.cartDetails)
        // this.orders.cartDetails.push(this.cartList[0])
        this.orders.subtotal = event.itemData.cost
        this.orders.tax = (this.orders.subtotal * event.itemData.tax) / 100
        this.orders.totalAmt = Number(event.itemData.cost) + Number(this.orders.tax) //change
        this.orders.roundedTotalAmt = Math.round(this.orders.totalAmt)
        if (this.orders.totalAmt > this.orders.roundedTotalAmt) {
          this.orders.roundDiff = this.orders.totalAmt - this.orders.roundedTotalAmt
        } else {
          this.orders.roundDiff = this.orders.roundedTotalAmt - this.orders.totalAmt
        }
        this.orders.discount = this.orders.discount ? this.orders.discount : 0
        this.orders.discountedAmount = this.orders.discountedAmount ? this.orders.discountedAmount : 0
        // this.adjustPayment=this.orders.totalAmt.toString()
      }
      else {
        this.orders.subtotal = 0
        this.orders.tax = 0
        this.orders.totalAmt = 0
        this.orders.discount = this.orders.discount ? this.orders.discount : 0
        this.cartList[index].cost = event.itemData.cost
        this.cartList[index].bId = this.id;
        this.cartList[index].costPerItem = event.itemData.cost
        // this.cartList[index].discount = 0
        this.cartList[index].itemId = event.itemData.itemId
        this.cartList[index].itemName = event.itemData.itemName
        this.cartList[index].quantity = 1
        this.cartList[index].tax = event.itemData.tax
        this.cartList[index].workerName = ''
        this.cartList[index].workerId = ''
        this.cartList[index].item = event.itemData.item;
        this.cartList[index].type = event.itemData.type;
        this.cartList[index].packingMappingId = 0;
        this.cartList[index].packageName = ''
        this.cartList[index].isGSTInclusive = event.itemData.isGSTInclusive ? event.itemData.isGSTInclusive : false

        this.orders.cartDetails[index].cost = event.itemData.cost
        this.orders.cartDetails[index].costPerItem = event.itemData.cost
        // this.orders.cartDetails[index].discount = 0
        this.orders.cartDetails[index].itemId = event.itemData.itemId
        this.orders.cartDetails[index].itemName = event.itemData.itemName
        this.orders.cartDetails[index].quantity = 1
        this.orders.cartDetails[index].tax = event.itemData.tax
        this.orders.cartDetails[index].workerName = ''
        this.orders.cartDetails[index].workerId = ''
        this.orders.cartDetails[index].item = event.itemData.item;
        this.orders.cartDetails[index].type = event.itemData.type;
        this.orders.cartDetails[index].packingMappingId = 0
        this.orders.cartDetails[index].packageName = ''
        this.orders.cartDetails[index].isGSTInclusive = event.itemData.isGSTInclusive ? event.itemData.isGSTInclusive : false
        // this.orders.cartDetails.push(this.cartList[0])
        // for (let i = 0; i < this.orders.cartDetails.length; i++) {
        //   let itemValue = 0
        //   let tax = 0
        //   itemValue = Number(this.orders.cartDetails[i].costPerItem) * Number(this.orders.cartDetails[i].quantity)
        //   this.orders.subtotal += itemValue
        //   tax = ((itemValue - this.orders.cartDetails[i].discount) * this.orders.cartDetails[i].tax) / 100
        //   this.orders.tax += tax
        // }
        this.orders.totalAmt = (this.orders.subtotal + this.orders.tax) - this.orders.discount
        this.orders.roundedTotalAmt = Math.round(this.orders.totalAmt)
        if (this.orders.totalAmt > this.orders.roundedTotalAmt) {
          this.orders.roundDiff = this.orders.totalAmt - this.orders.roundedTotalAmt
        } else {
          this.orders.roundDiff = this.orders.roundedTotalAmt - this.orders.totalAmt
        }
        // this.orders.totalAmt = Math.round(this.orders.totalAmt)
        this.orders.discountedAmount = this.orders.discount
        // this.adjustPayment=this.orders.totalAmt.toString()
        // console.log(this.orders)
      }
      // console.log(this.orders.cartDetails)
      // console.log(this.cartList)
      this.cart = new cartItems();
      this.orders.itemCount = this.cartList.length;
      // console.log(this.orders)




      // console.log(this.cartList)
      // console.log(this.orders)
    }
    // const isGST = this.isIncluseTax ? this.isIncluseTax : null;
    // this.afterCalculationData(isGST);
    // this.updateSalonCart()
    // console.log(this.pointFlag)
    let itemIndex
    for (let i = 0; i < this.cartList.length; i++) {
      itemIndex = this.menuData.findIndex(e => e.itemId == this.cartList[i].itemId)
      // console.log(index)
    }
    let qty = this.menuData[itemIndex].quantity
    let sType = this.menuData[itemIndex].sType
    // console.log(sType)
    // console.log(qty)
    if (this.category_id == 4) {
      if (sType == 1) {
        if (qty != null && qty != undefined) {
          if (this.orders.cartDetails[index].quantity > (+qty)) {
            Swal.fire('', 'Available quantity of product in inventory is ' + qty, 'error')
            // this.dropDownListObject.value = 1; 
            this.updateCart(1, this.orders.cartDetails[index], index);
            // this.cartList[index].quantity = 1
            // this.orders.cartDetails[index].quantity = 1
            // console.log(this.cartList)
          } else {
            this.updateCart(parseInt("1"), this.orders.cartDetails[index], index);
          }
        } else {
          this.updateCart(parseInt("1"), this.orders.cartDetails[index], index);
        }
      } else {
        this.updateCart(parseInt("1"), this.orders.cartDetails[index], index);
      }
    } else {
      this.updateCart(parseInt("1"), this.orders.cartDetails[index], index);
    }
    if (this.pointFlag == true) {
      this.applyPoints(true)
    } else {
      this.applyPoints(false)
    }
    // if (this.paymentType == "membership") {
    //   this.completeExistingOrder('MS', '', true)
    // }
  }
  addItem(itemId: any, i: any, j) {
    // let index=0
    let index = this.menuData.findIndex(e => e.itemId == itemId);
    if (index >= 0) {
      this.packageDetailsArr[i].items[j].redeemed = 1;
      let event = this.menuData[index]
      // console.log(event)
      let length = this.cartList.length
      if (length == 0) {
        this.cart.itemId = 0;
        this.cart.cost = 0;
        this.cart.quantity = 1;
        this.cart.itemName = '';
        this.cart.item = '';
        this.cart.costPerItem = 0;
        this.cart.tax = 0;
        this.cart.exciseApplicable = 0;
        this.cart.taxPer = 0;
        this.cart.type = 0;
        this.cart.workerName = '';
        this.cart.discount = 0;
        this.cart.specialInstruction = '';
        this.cartList.push(this.cart);
        this.orders.cartDetails.push(this.cart);
        this.cartList[0].cost = Number(event.cost) - Number(event.cost)
        this.cartList[0].costPerItem = Number(event.cost)
        this.cartList[0].discount = Number(event.cost)
        this.cartList[0].itemId = Number(event.itemId);
        this.cartList[0].itemName = event.itemName.toString()
        this.cartList[0].quantity = 1
        this.cartList[0].tax = 0
        this.cartList[0].workerName = ''
        this.cartList[0].workerId = ''
        this.cartList[0].item = event.item.toString();
        this.cartList[0].bId = this.id;
        this.cartList[0].type = Number(event.type);
        this.cartList[0].packingMappingId = this.packageDetailsArr[i].items[j].packingMappingId;
        this.cartList[0].packageName = this.packageDetailsArr[i].items[j].packageName;

        this.orders.cartDetails[0].cost = Number(event.cost) - Number(event.cost)
        this.orders.cartDetails[0].costPerItem = Number(event.cost)
        this.orders.cartDetails[0].discount = Number(event.cost)
        this.orders.cartDetails[0].itemId = Number(event.itemId)
        this.orders.cartDetails[0].itemName = event.itemName.toString()
        this.orders.cartDetails[0].quantity = 1
        this.orders.cartDetails[0].tax = 0
        this.orders.cartDetails[0].workerName = ''
        this.orders.cartDetails[0].workerId = ''
        this.orders.cartDetails[0].item = event.item.toString();
        this.orders.cartDetails[0].type = Number(event.type);
        this.orders.cartDetails[0].packingMappingId = this.packageDetailsArr[i].items[j].packingMappingId;
        this.orders.cartDetails[0].packageName = this.packageDetailsArr[i].items[j].packageName;

        this.orders.subtotal = Number(event.cost)
        this.orders.tax = 0
        this.orders.totalAmt = (Number(event.cost) + Number(this.orders.tax)) - Number(event.cost) //change
        this.orders.roundedTotalAmt = Math.round(this.orders.totalAmt)
        if (this.orders.totalAmt > this.orders.roundedTotalAmt) {
          this.orders.roundDiff = this.orders.totalAmt - this.orders.roundedTotalAmt
        } else {
          this.orders.roundDiff = this.orders.roundedTotalAmt - this.orders.totalAmt
        }
        this.orders.discount = this.orders.subtotal
        this.orders.discountedAmount = this.orders.subtotal
      }
      else if (length == 1) {
        this.orders.subtotal = 0
        this.orders.tax = 0
        this.orders.totalAmt = 0
        this.orders.discount = 0
        if (this.orders.cartDetails[0].itemName == '') {
          this.cartList[0].cost = Number(event.cost) - Number(event.cost)
          this.cartList[0].costPerItem = Number(event.cost)
          this.cartList[0].discount = Number(event.cost)
          this.cartList[0].itemId = Number(event.itemId);
          this.cartList[0].itemName = event.itemName.toString()
          this.cartList[0].quantity = 1
          this.cartList[0].tax = 0
          this.cartList[0].workerName = ''
          this.cartList[0].workerId = ''
          this.cartList[0].item = event.item.toString();
          this.cartList[0].bId = this.id;
          this.cartList[0].type = Number(event.type);
          this.cartList[0].packingMappingId = this.packageDetailsArr[i].items[j].packingMappingId;
          this.cartList[0].packageName = this.packageDetailsArr[i].items[j].packageName;

          this.orders.cartDetails[0].cost = Number(event.cost) - Number(event.cost)
          this.orders.cartDetails[0].costPerItem = Number(event.cost)
          this.orders.cartDetails[0].discount = Number(event.cost)
          this.orders.cartDetails[0].itemId = Number(event.itemId)
          this.orders.cartDetails[0].itemName = event.itemName.toString()
          this.orders.cartDetails[0].quantity = 1
          this.orders.cartDetails[0].tax = 0
          this.orders.cartDetails[0].workerName = ''
          this.orders.cartDetails[0].workerId = ''
          this.orders.cartDetails[0].item = event.item.toString();
          this.orders.cartDetails[0].type = Number(event.type);
          this.orders.cartDetails[0].packingMappingId = this.packageDetailsArr[i].items[j].packingMappingId;
          this.orders.cartDetails[0].packageName = this.packageDetailsArr[i].items[j].packageName;
        }
        else {
          this.cart.itemId = 0;
          this.cart.cost = 0;
          this.cart.quantity = 1;
          this.cart.itemName = '';
          this.cart.item = '';
          this.cart.costPerItem = 0;
          this.cart.tax = 0;
          this.cart.exciseApplicable = 0;
          this.cart.taxPer = 0;
          this.cart.type = 0;
          this.cart.workerName = '';
          this.cart.discount = 0;
          this.cart.specialInstruction = '';
          this.cartList.push(this.cart);
          this.orders.cartDetails.push(this.cart);
          this.cartList[1].cost = Number(event.cost) - Number(event.cost)
          this.cartList[1].costPerItem = Number(event.cost)
          this.cartList[1].discount = Number(event.cost)
          this.cartList[1].itemId = Number(event.itemId);
          this.cartList[1].itemName = event.itemName.toString()
          this.cartList[1].quantity = 1
          this.cartList[1].tax = 0
          this.cartList[1].workerName = ''
          this.cartList[1].workerId = ''
          this.cartList[1].item = event.item.toString();
          this.cartList[1].bId = this.id;
          this.cartList[1].type = Number(event.type);
          this.cartList[1].packingMappingId = this.packageDetailsArr[i].items[j].packingMappingId;
          this.cartList[1].packageName = this.packageDetailsArr[i].items[j].packageName;

          this.orders.cartDetails[1].cost = Number(event.cost) - Number(event.cost)
          this.orders.cartDetails[1].costPerItem = Number(event.cost)
          this.orders.cartDetails[1].discount = Number(event.cost)
          this.orders.cartDetails[1].itemId = Number(event.itemId)
          this.orders.cartDetails[1].itemName = event.itemName.toString()
          this.orders.cartDetails[1].quantity = 1
          this.orders.cartDetails[1].tax = 0
          this.orders.cartDetails[1].workerName = ''
          this.orders.cartDetails[1].workerId = ''
          this.orders.cartDetails[1].item = event.item.toString();
          this.orders.cartDetails[1].type = Number(event.type);
          this.orders.cartDetails[1].packingMappingId = this.packageDetailsArr[i].items[j].packingMappingId;
          this.orders.cartDetails[1].packageName = this.packageDetailsArr[i].items[j].packageName;
        }
        for (let i = 0; i < this.orders.cartDetails.length; i++) {
          let itemValue = 0
          let tax = 0
          itemValue = Number(this.orders.cartDetails[i].costPerItem) * Number(this.orders.cartDetails[i].quantity)
          this.orders.subtotal += itemValue
          tax = ((itemValue - this.orders.cartDetails[i].discount) * this.orders.cartDetails[i].tax) / 100
          this.orders.tax += tax
          this.orders.discount += Number(this.orders.cartDetails[i].discount)
        }
        this.orders.totalAmt = (this.orders.subtotal + this.orders.tax) - this.orders.discount
        this.orders.roundedTotalAmt = Math.round(this.orders.totalAmt)
        if (this.orders.totalAmt > this.orders.roundedTotalAmt) {
          this.orders.roundDiff = this.orders.totalAmt - this.orders.roundedTotalAmt
        } else {
          this.orders.roundDiff = this.orders.roundedTotalAmt - this.orders.totalAmt
        }
        // this.orders.totalAmt = Math.round(this.orders.totalAmt)
        this.orders.discountedAmount = this.orders.discount
        // this.adjustPayment=this.orders.totalAmt.toString()
      }
      else {
        this.orders.subtotal = 0
        this.orders.tax = 0
        this.orders.totalAmt = 0
        this.orders.discount = 0
        if (this.orders.cartDetails[length - 1].itemName == '') {
          this.cartList[length - 1].cost = Number(event.cost) - Number(event.cost)
          this.cartList[length - 1].bId = this.id;
          this.cartList[length - 1].costPerItem = Number(event.cost)
          this.cartList[length - 1].discount = Number(event.cost)
          this.cartList[length - 1].itemId = Number(event.itemId)
          this.cartList[length - 1].itemName = event.itemName.toString()
          this.cartList[length - 1].quantity = 1
          this.cartList[length - 1].tax = 0
          this.cartList[length - 1].workerName = ''
          this.cartList[length - 1].workerId = ''
          this.cartList[length - 1].item = event.item.toString();
          this.cartList[length - 1].type = Number(event.type);
          this.cartList[length - 1].packingMappingId = this.packageDetailsArr[i].items[j].packingMappingId;
          this.cartList[length - 1].packageName = this.packageDetailsArr[i].items[j].packageName;

          this.orders.cartDetails[length - 1].cost = Number(event.cost) - Number(event.cost)
          this.orders.cartDetails[length - 1].costPerItem = Number(event.cost)
          this.orders.cartDetails[length - 1].discount = Number(event.cost)
          this.orders.cartDetails[length - 1].itemId = Number(event.itemId)
          this.orders.cartDetails[length - 1].itemName = event.itemName.toString()
          this.orders.cartDetails[length - 1].quantity = 1
          this.orders.cartDetails[length - 1].tax = 0
          this.orders.cartDetails[length - 1].workerName = ''
          this.orders.cartDetails[length - 1].workerId = ''
          this.orders.cartDetails[length - 1].item = event.item.toString();
          this.orders.cartDetails[length - 1].type = Number(event.type);
          this.orders.cartDetails[length - 1].packingMappingId = this.packageDetailsArr[i].items[j].packingMappingId;
          this.orders.cartDetails[length - 1].packageName = this.packageDetailsArr[i].items[j].packageName;
        }
        else {
          this.cart.itemId = 0;
          this.cart.cost = 0;
          this.cart.quantity = 1;
          this.cart.itemName = '';
          this.cart.item = '';
          this.cart.costPerItem = 0;
          this.cart.tax = 0;
          this.cart.exciseApplicable = 0;
          this.cart.taxPer = 0;
          this.cart.type = 0;
          this.cart.workerName = '';
          this.cart.discount = 0;
          this.cart.specialInstruction = '';
          this.cartList.push(this.cart);
          this.orders.cartDetails.push(this.cart);
          let newLength = this.orders.cartDetails.length

          this.cartList[newLength - 1].cost = Number(event.cost) - Number(event.cost)
          this.cartList[newLength - 1].bId = this.id;
          this.cartList[newLength - 1].costPerItem = Number(event.cost)
          this.cartList[newLength - 1].discount = Number(event.cost)
          this.cartList[newLength - 1].itemId = Number(event.itemId)
          this.cartList[newLength - 1].itemName = event.itemName.toString()
          this.cartList[newLength - 1].quantity = 1
          this.cartList[newLength - 1].tax = 0
          this.cartList[newLength - 1].workerName = ''
          this.cartList[newLength - 1].workerId = ''
          this.cartList[newLength - 1].item = event.item.toString();
          this.cartList[newLength - 1].type = Number(event.type);
          this.cartList[newLength - 1].packingMappingId = this.packageDetailsArr[i].items[j].packingMappingId;
          this.cartList[newLength - 1].packageName = this.packageDetailsArr[i].items[j].packageName;

          this.orders.cartDetails[newLength - 1].cost = Number(event.cost) - Number(event.cost)
          this.orders.cartDetails[newLength - 1].costPerItem = Number(event.cost)
          this.orders.cartDetails[newLength - 1].discount = Number(event.cost)
          this.orders.cartDetails[newLength - 1].itemId = Number(event.itemId)
          this.orders.cartDetails[newLength - 1].itemName = event.itemName.toString()
          this.orders.cartDetails[newLength - 1].quantity = 1
          this.orders.cartDetails[newLength - 1].tax = 0
          this.orders.cartDetails[newLength - 1].workerName = ''
          this.orders.cartDetails[newLength - 1].workerId = ''
          this.orders.cartDetails[newLength - 1].item = event.item.toString();
          this.orders.cartDetails[newLength - 1].type = Number(event.type);
          this.orders.cartDetails[newLength - 1].packingMappingId = this.packageDetailsArr[i].items[j].packingMappingId;
          this.orders.cartDetails[newLength - 1].packageName = this.packageDetailsArr[i].items[j].packageName;
        }

        //  console.log(this.orders.cartDetails) 
        for (let i = 0; i < this.orders.cartDetails.length; i++) {
          let itemValue = 0
          let tax = 0
          itemValue = Number(this.orders.cartDetails[i].costPerItem) * Number(this.orders.cartDetails[i].quantity)
          this.orders.subtotal += itemValue
          tax = ((itemValue - this.orders.cartDetails[i].discount) * this.orders.cartDetails[i].tax) / 100
          this.orders.tax += tax
          this.orders.discount += Number(this.orders.cartDetails[i].discount)
        }
        this.orders.totalAmt = (this.orders.subtotal + this.orders.tax) - this.orders.discount
        this.orders.roundedTotalAmt = Math.round(this.orders.totalAmt)
        if (this.orders.totalAmt > this.orders.roundedTotalAmt) {
          this.orders.roundDiff = this.orders.totalAmt - this.orders.roundedTotalAmt
        } else {
          this.orders.roundDiff = this.orders.roundedTotalAmt - this.orders.totalAmt
        }
        // this.orders.totalAmt = Math.round(this.orders.totalAmt)
        this.orders.discountedAmount = this.orders.discount
        // this.adjustPayment=this.orders.totalAmt.toString()
        // console.log(this.orders)
      }
      // console.log(this.orders.cartDetails)
      this.cart = new cartItems();
      this.orders.itemCount = this.cartList.length;
      // console.log(this.orders)
      let orderIndex = this.ordersArray.findIndex(
        (e) => e.orderId == this.orders.orderId
      );
      this.orders.updatedAt = this.getDate();
      if (orderIndex > -1) {
        this.ordersArray[orderIndex] = this.orders;
      } else {
        this.ordersArray.push(this.orders);
      }
      // console.log(this.ordersArray)
      this.storage.set("existingOrders", this.ordersArray);

      this.modalRef.close()
      // console.log(this.cartList)
      this.cd.detectChanges();
    } else {
      Swal.fire('', 'This service does not exist in your catalog anymore', 'error')
    }
    // console.log(this.menuData[index].itemId)
  }
  addStylist(i, event) {
    this.cartList[i].workerName = event.value
    this.orders.cartDetails[i].workerName = event.value
    this.cartList[i].workerId = event.value
    this.orders.cartDetails[i].workerId = event.value
    // console.log(this.cartList[i].workerId)
    let orderIndex = this.ordersArray.findIndex(
      (e) => e.orderId == this.orders.orderId
    );
    this.orders.updatedAt = this.getDate();
    if (orderIndex > -1) {
      this.ordersArray[orderIndex] = this.orders;
    } else {
      this.ordersArray.push(this.orders);
    }
    // console.log(this.ordersArray)
    this.storage.set("existingOrders", this.ordersArray);
    this.cd.detectChanges();
    // console.log(this.orders)
  }
  addRemark(i, event) {
    // console.log(event)
    this.cartList[i].discRemark = event.value
    this.orders.cartDetails[i].discRemark = event.value
    // this.cartList[i].workerId = event.value
    // this.orders.cartDetails[i].workerId = event.value
    // console.log(this.cartList[i].workerId)
    let orderIndex = this.ordersArray.findIndex(
      (e) => e.orderId == this.orders.orderId
    );
    this.orders.updatedAt = this.getDate();
    if (orderIndex > -1) {
      this.ordersArray[orderIndex] = this.orders;
    } else {
      this.ordersArray.push(this.orders);
    }
    // console.log(this.ordersArray)
    this.storage.set("existingOrders", this.ordersArray);
    this.cd.detectChanges();
    // console.log(this.orders)
  }
  addNewItem() {

    const defaultCartItem = new cartItems();
    const newItem = Object.assign(defaultCartItem, {
      itemId: 0,
      cost: 0,
      quantity: 1,
      itemName: '',
      item: '',
      costPerItem: 0,
      tax: 0,
      exciseApplicable: 0,
      taxPer: 0,
      type: 0,
      workerName: '',
      discount: 0,
      specialInstruction: ''
    });

    this.cartList.push(newItem);
    this.orders.cartDetails.push(newItem);

  }
  openProductMapping(modal) {
    this.itemarr = []
    this.finalArr = []
    // console.log(this.orders.cartDetails)
    for (let i = 0; i < this.orders.cartDetails.length; i++) {
      if (this.finalArr.length == 0) {
        this.finalArr.push({
          bId: this.orders.cartDetails[i].bId,
          // bName: this.orders.cartDetails[i].bName,
          items: []
        });
        if (this.category_id == 4) {
          this.finalArr[0]['items'].push({
            itemId: this.orders.cartDetails[i].itemId,
            type: this.orders.cartDetails[i].type,
            qty: this.orders.cartDetails[i].quantity,
            stylistName: this.orders.cartDetails[i].workerId,
            discount: this.orders.cartDetails[i].discount
          })
        } else {
          this.finalArr[0]['items'].push({
            itemId: this.orders.cartDetails[i].itemId,
            qty: this.orders.cartDetails[i].quantity
          })
        }
      } else {
        // for (let i = 1; i < this.orders.cartDetails.length; i++) {
        let index = this.finalArr.findIndex(e => e.bId == this.orders.cartDetails[i].bId);
        if (index > -1) {
          if (this.category_id == 4) {
            this.finalArr[index]['items'].push({
              itemId: this.orders.cartDetails[i].itemId,
              qty: this.orders.cartDetails[i].quantity,
              type: this.orders.cartDetails[i].type,
              stylistName: this.orders.cartDetails[i].workerId,
              discount: this.orders.cartDetails[i].discount
            })
          } else {
            this.finalArr[index]['items'].push({
              itemId: this.orders.cartDetails[i].itemId,
              qty: this.orders.cartDetails[i].quantity
            })
          }
        } else {
          this.finalArr.push({
            bId: this.orders.cartDetails[i].bId,
            // bName: this.orders.cartDetails[i].bName,
            items: []
          });
          if (this.category_id == 4) {
            this.finalArr[this.finalArr.length - 1]['items'].push({
              itemId: this.orders.cartDetails[i].itemId,
              qty: this.orders.cartDetails[i].quantity,
              type: this.orders.cartDetails[i].type,
              stylistName: this.orders.cartDetails[i].workerId,
              discount: this.orders.cartDetails[i].discount
            })
          } else {
            this.finalArr[this.finalArr.length - 1]['items'].push({
              itemId: this.orders.cartDetails[i].itemId,
              qty: this.orders.cartDetails[i].quantity
            })
          }
        }

      }
    }
    if (this.category_id == 4) {
      for (let i = 0; i < this.finalArr.length; i++) {
        for (let j = 0; j < this.finalArr[i].items.length; j++) {
          if (this.finalArr[i].items[j].itemId == 0 || this.finalArr[i].items[j].stylistName == '') {
            this.toastr.error("Please provide all detials!", 'Error', {
              positionClass: 'toast-bottom-right'
            })
            return false;
          }
        }
      }
      // for(let i=0;i<this.finalArr.length;i++){
      //   for(let j=0;j<this.finalArr[i].items.length;j++){
      //     if(this.finalArr[i].items[j].type==3 || this.finalArr[i].items[j].type==4){
      //       this.mobileMandatory=1
      //       break;
      //     }
      //   }
      // }
    }
    // console.log(this.finalArr)
    // if(this.mobileMandatory==1 && this.orders.mobileNo==''){
    //     Swal.fire('','Please enter customer mobile number to continue!','error')
    //     return false;
    // }
    // console.log(this.pMode)
    if (this.paymentType == '') {
      this.toastr.error("Please select payment mode !", 'Error', {
        positionClass: 'toast-bottom-right'
      })
      // Swal.fire('','Please select payment mode','error')
      return false;
    } else {
      this.itemarr.push({
        orderId: this.orders.orderId,
        itemData: []
      })
      for (let i = 0; i < this.orders.cartDetails.length; i++) {
        this.itemarr[0].itemData.push({
          itemName: this.orders.cartDetails[i].itemName,
          productUsed: '',
          productQty: '',
          unit: ''
        })
      }

      this.openModal(modal, 'lg')
    }


  }
  getProducts() {
    let params =
      "userId=" +
      this.storage.get("userId") +
      "&password=" +
      this.storage.get("password") +
      "&businessId=" +
      this.storage.get("selectedBusinessId");
    this.httpClient
      .get("https://api.uengage.in/pos/getProductInventoryList?" + params, {})
      .subscribe((res: any) => {
        if (res.status == 1) {
          this.productData = res.list
          this.cd.detectChanges()
        } else {
          Swal.fire('', res.msg, 'error')
        }
      });
  }
  mapProduct(value, i) {
    let index = this.productData.findIndex(e => e.id == value);
    // console.log(index)
    let unit = this.productData[index].baseUnit
    this.itemarr[0].itemData[i].productUsed = value
    this.itemarr[0].itemData[i].unit = unit
    this.orders.cartDetails[i]['productUsed'] = value;
  }
  mapQuantity(value, i) {
    this.itemarr[0].itemData[i].productQty = value
    this.orders.cartDetails[i]['productQty'] = value;
    // console.log(this.itemarr)
  }
  closeSalonOrder() {
    // console.log(this.pMode)
    // console.log(this.orders.cartDetails)
    this.finalArr = []
    this.isloading = true
    for (let i = 0; i < this.orders.cartDetails.length; i++) {
      if (this.finalArr.length == 0) {
        this.finalArr.push({
          bId: this.orders.cartDetails[i].bId,
          // bName: this.orders.cartDetails[i].bName,
          items: []
        });
        if (this.category_id == 4) {
          this.finalArr[0]['items'].push({
            itemId: this.orders.cartDetails[i].itemId,
            type: this.orders.cartDetails[i].type,
            qty: this.orders.cartDetails[i].quantity,
            stylistName: this.orders.cartDetails[i].workerId.toString(),
            discount: this.orders.cartDetails[i].discount,
            productUsed: this.orders.cartDetails[i].productUsed,
            productQty: this.orders.cartDetails[i].productQty,
            discRemark: this.orders.cartDetails[i].discRemark,
            packingMappingId: this.orders.cartDetails[i].packingMappingId,
            packageName: this.orders.cartDetails[i].packageName,
            subItems: this.orders.cartDetails[i].subItems,
            price: this.itemTaxFlag ? this.orders.cartDetails[i].costPerItem : (+this.orders.cartDetails[i].costPerItem) + ((+this.orders.cartDetails[i].costPerItem) * (+this.orders.cartDetails[i].tax) / 100)

          })
        } else {
          this.finalArr[0]['items'].push({
            itemId: this.orders.cartDetails[i].itemId,
            qty: this.orders.cartDetails[i].quantity
          })
        }
      } else {
        // for (let i = 1; i < this.orders.cartDetails.length; i++) {

        let index = this.finalArr.findIndex(e => e.bId == this.orders.cartDetails[i].bId);
        if (index > -1) {
          if (this.category_id == 4) {
            this.finalArr[index]['items'].push({
              itemId: this.orders.cartDetails[i].itemId,
              qty: this.orders.cartDetails[i].quantity,
              type: this.orders.cartDetails[i].type,
              stylistName: this.orders.cartDetails[i].workerId.toString(),
              discount: this.orders.cartDetails[i].discount,
              productUsed: this.orders.cartDetails[i].productUsed,
              productQty: this.orders.cartDetails[i].productQty,
              discRemark: this.orders.cartDetails[i].discRemark,
              packingMappingId: this.orders.cartDetails[i].packingMappingId,
              packageName: this.orders.cartDetails[i].packageName,
              subItems: this.orders.cartDetails[i].subItems,
              price: this.itemTaxFlag ? this.orders.cartDetails[i].costPerItem : (+this.orders.cartDetails[i].costPerItem) + ((+this.orders.cartDetails[i].costPerItem) * (+this.orders.cartDetails[i].tax) / 100)
            })
          } else {
            this.finalArr[index]['items'].push({
              itemId: this.orders.cartDetails[i].itemId,
              qty: this.orders.cartDetails[i].quantity
            })
          }
        } else {
          this.finalArr.push({
            bId: this.orders.cartDetails[i].bId,
            // bName: this.orders.cartDetails[i].bName,
            items: []
          });
          if (this.category_id == 4) {
            this.finalArr[this.finalArr.length - 1]['items'].push({
              itemId: this.orders.cartDetails[i].itemId,
              qty: this.orders.cartDetails[i].quantity,
              type: this.orders.cartDetails[i].type,
              stylistName: this.orders.cartDetails[i].workerId.toString(),
              discount: this.orders.cartDetails[i].discount,
              productUsed: this.orders.cartDetails[i].productUsed,
              productQty: this.orders.cartDetails[i].productQty,
              discRemark: this.orders.cartDetails[i].discRemark,
              packingMappingId: this.orders.cartDetails[i].packingMappingId,
              packageName: this.orders.cartDetails[i].packageName,
              subItems: this.orders.cartDetails[i].subItems,
              price: this.itemTaxFlag ? this.orders.cartDetails[i].costPerItem : (+this.orders.cartDetails[i].costPerItem) + ((+this.orders.cartDetails[i].costPerItem) * (+this.orders.cartDetails[i].tax) / 100)
            })
          } else {
            this.finalArr[this.finalArr.length - 1]['items'].push({
              itemId: this.orders.cartDetails[i].itemId,
              qty: this.orders.cartDetails[i].quantity
            })
          }
        }
      }
    }
    if (this.partialPayments == undefined) {
      this.partialPayments = ''
    }
    if (this.partialAmount == undefined) {
      this.partialAmount = 0
    }
    let appointId
    if (this.orders.appointmentId != 0 && this.orders.appointmentId != undefined) {
      appointId = this.orders.appointmentId
    } else {
      appointId = 0
    }
    let taxFlag
    if (this.taxFlag == false || this.itemTaxFlag == false) {
      taxFlag = 0
    } else {
      taxFlag = 1
    }
    // console.log(this.orders.invoicedAt)
    // return
    let invoiceDate
    if (this.orders.invoicedAt != undefined && this.orders.invoicedAt != null && this.orders.invoicedAt != '') {
      invoiceDate = this.orders.invoicedAt + ":00"
    } else {
      const currentDate = new Date();
      const localISOTime = new Date(currentDate.getTime() - (currentDate.getTimezoneOffset() * 60000)).toISOString().slice(0, 16);
      invoiceDate = localISOTime + ":00"
    }
    // console.log(invoiceDate)
    let params = new HttpParams({
      fromObject: {
        userId: this.storage.get("userId"),
        password: this.storage.get("password"),
        businessId: this.storage.get("selectedBusinessId"),
        json_pos: JSON.stringify(this.finalArr),
        total: this.orders.totalAmt.toString(),
        customerMobileNo: this.orders.mobileNo.toString(),
        customerName: this.orders.customerName.toString(),
        membership_used: this.membershipUsed.toString(),
        mode: this.paymentType,
        third_party_name: this.thirdPartyType,
        partial_payment: this.partialPayments,
        partial_amount: this.partialAmount,
        splitPay: JSON.stringify(this.paymentList),
        gender: this.orders.gender,
        appointmentId: appointId,
        dob: this.orders.dob,
        anniversary: this.orders.anniversary,
        tax_applicable: taxFlag,
        wallet_points: this.orders.walletPoints.toString() ?? '',
        promoCodeId: this.promoId,
        membership_card_id: this.cardId ?? '',
        gst_number: this.gstNum ?? '',
        isGSTInclusive: this.orders.isGstInclusive ? '1' : '0',
        invoiced_at: invoiceDate.toString()
      }
    });
    let url = ''

    if (this.voidOrderId != undefined && this.voidOrderId != '' && this.voidOrderId != null) {
      params = params.append('oldOrderId', this.voidOrderId)
      url = "https://api.uengage.in/pos/editBill"
    } else {
      url = "https://api.uengage.in/pos/closeOrder"
    }
    // console.log(this.finalArr)
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    // this.spinnerService.show();
    this.httpClient.post(url, params, httpOptions).subscribe((res: any) => {
      // console.log(res.msg)
      if (res.status == 0) {
        // this.spinnerService.hide();
        Swal.fire('', res.msg, 'error')
        return false;
      }
      else if (res.status == 1) {
        if (this.voidOrderId != undefined && this.voidOrderId != '' && this.voidOrderId != null) {
          let params = new HttpParams({
            fromObject: {
              userId: this.storage.get("userId"),
              password: this.storage.get("password"),
              business_id: this.storage.get("selectedBusinessId"),
              parent_business_id: this.parentBusinessId.toString(),
              order_id: this.voidOrderId
            }
          });
          let httpOptions = {
            headers: new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded")
          };
          let url = "https://crm.uengage.in/crm_api/void/order"
          this.httpClient.post(url, params, httpOptions).subscribe((res: any) => {
            this.storage.remove('void_order_id')
            this.modalRef.close()
            this.orderData = res.orders
            this.showPrint = 1
            this.isloading = false
            this.cd.detectChanges()
            if (this.isPrint) {
              this.print()
            }
            Swal.fire('', 'Order Completed', 'success')
            let path = "edge/order";
            this.router.navigate([path]);

          }, (err) => {
            if (err) {
              Swal.fire('', 'Something wents worng. Please Try agian', 'error');
              this.isloading = false
            }
          })
        } else {
          this.modalRef.close()
          this.orderData = res.orders
          if (!this.apiService.isFalsy(this.orderData[0].orderDetails[0].isGSTInclusive)) {
            this.orderData[0].orderDetails[0].netAmt = Number(this.orderData[0].orderDetails[0].subTotal)
            this.orderData[0].orderDetails[0].totalAmt = Number(this.orderData[0].orderDetails[0].subTotal) + Number(this.orderData[0].orderDetails[0].taxes) - Number(this.orderData[0].orderDetails[0].wallet_amount)
            this.orderData[0].orderDetails[0].subTotal = Number(this.orderData[0].orderDetails[0].subTotal) + Number(this.orderData[0].orderDetails[0].taxes) + Number(this.orderData[0].orderDetails[0].discount)
          }
          this.showPrint = 1
          this.isloading = false
          this.cd.detectChanges()
          if (this.isPrint) {
            this.print()
          } else {
            this.removeOrder(this.orders);
            this.orderData = []
          }
          Swal.fire('', 'Order Completed', 'success')
          let path = "edge/order";
          this.router.navigate([path]);
        }
      }
    }, (err) => {
      if (err) {
        Swal.fire('', 'Something wents worng. Please Try agian', 'error');
        this.isloading = false
      }
    })
  }
  openDiscountModal(modal, i) {
    //  console.log(this.orders.cartDetails[i])
    this.totalDiscount = 0
    this.selectedItemCart = this.orders.cartDetails[i]
    // console.log(this.selectedItemCart)
    // this.disData = []
    // this.disData.push({
    //   discountType: this.orders.cartDetails[i].disType,
    //   discountValue: this.orders.cartDetails[i].disValue,
    //   discountRemarks: this.orders.cartDetails[i].discRemark,
    // })
    //  console.log(this.disData)
    this.disIndex = i
    this.openModal(modal, 'lg')
  }
  openTotalDiscountModal(modal) {
    this.totalDiscount = 1
    this.openModal(modal, 'lg')
  }
  removeTotalDisc() {
    this.totalDiscount = 0
    this.orders.discount = 0
    this.orders.discountType = 0
    for (let i = 0; i < this.orders.cartDetails.length; i++) {
      this.orders.cartDetails[i].cost = Number(this.orders.cartDetails[i].cost) + Number(this.orders.cartDetails[i].discount)
      this.orders.cartDetails[i].disValue = 0
      this.orders.cartDetails[i].discount = 0
      this.cartList[i].discount = 0
    }
    if (this.pointFlag == true) {
      this.applyPoints(true)
    } else {
      this.applyPoints(false)
    }
  }
  addTotalDiscount() {
    if (this.orders.discountType == 2) { //value
      let maxDiscountValue = (this.maxDiscountAllowed / 100) * this.orders.subtotal;
      if (this.orders.discount > maxDiscountValue) {
        Swal.fire('', 'Discount value cannot be greater than ' + this.maxDiscountAllowed + '% of item value.', 'error')
        return false;
      }
      let disPer = (this.orders.discount / this.orders.subtotal) * 100
      for (let i = 0; i < this.orders.cartDetails.length; i++) {
        let cost = Number(this.orders.cartDetails[i]["costPerItem"]) * Number(this.orders.cartDetails[i]["quantity"])
        this.orders.cartDetails[i].disValue = Number(((cost * disPer) / 100).toFixed(2))
        this.orders.cartDetails[i].discount = Number(((cost * disPer) / 100).toFixed(2))
      }
      if (this.pointFlag == true) {
        this.applyPoints(true)
      } else {
        this.applyPoints(false)
      }
      this.modalRef.close()
    } else { // percentage
      if (Number(this.orders.discount) > Number(this.maxDiscountAllowed)) {
        Swal.fire('', 'Discount value cannot be greater than ' + this.maxDiscountAllowed + '% of item value.', 'error')
        return false;
      }
      for (let i = 0; i < this.orders.cartDetails.length; i++) {
        let cost = Number(this.orders.cartDetails[i]["costPerItem"]) * Number(this.orders.cartDetails[i]["quantity"])
        this.orders.cartDetails[i].disValue = Number(((cost * this.orders.discount) / 100).toFixed(2))
        this.orders.cartDetails[i].discount = Number(((cost * this.orders.discount) / 100).toFixed(2))
      }
      if (this.pointFlag == true) {
        this.applyPoints(true)
      } else {
        this.applyPoints(false)
      }
      this.modalRef.close()
    }
  }
  sendReq(modal) {
    this.discLimit = 0
    this.modalRef.close()
    this.modalRef = this.modalService.open(modal, {
      backdrop: 'static',
      backdropClass: "light-blue-backdrop",
      centered: true,
      ariaLabelledBy: "modal-basic-title",
      size: 'md'
    });
  }
  showCompany(value) {
    // console.log(this.paymentType)
    if (this.paymentType == 'split') {
      Swal.fire({
        title: "Are you sure ?",
        text: "Split Payment Data Will Be Lost.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          this.paymentType = value
          this.paymentList = []
          this.splitArea = 0
          this.cd.detectChanges()
          if (value == 'third_party') {
            this.thirdPartyData = []
            let params =
              "userId=" +
              this.storage.get("userId") +
              "&password=" +
              this.storage.get("password") +
              "&businessId=" +
              this.storage.get("selectedBusinessId");
            this.httpClient
              .get("https://api.uengage.in/pos/third_party_types?" + params, {})
              .subscribe((res: any) => {
                if (res.msg == "Success") {
                  this.thirdPartyData = res.third_party_types;
                  this.showThirdParty = 1
                  // this.openMenuItem(0)
                  this.cd.detectChanges()
                  // this.openModal(modal,'lg')
                }
              });
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.paymentType = "split"
          this.cd.detectChanges()
          Swal.fire('', "Cancelled", "error");
          // console.log(this.paymentType)
          // console.log(this.paymentList)
          // this.modalRef.close();
          //   $('#myModal .close').click();
        }
      });
    }
    else {
      if (value == 'third_party') {
        this.thirdPartyData = []
        let params =
          "userId=" +
          this.storage.get("userId") +
          "&password=" +
          this.storage.get("password") +
          "&businessId=" +
          this.storage.get("selectedBusinessId");
        this.httpClient
          .get("https://api.uengage.in/pos/third_party_types?" + params, {})
          .subscribe((res: any) => {
            if (res.msg == "Success") {
              this.thirdPartyData = res.third_party_types;
              this.showThirdParty = 1
              // this.openMenuItem(0)
              this.cd.detectChanges()
              // this.openModal(modal,'lg')
            }
          });
      } else {
        this.showThirdParty = 0
        this.paymentList = []
        this.splitArea = 0
      }
    }
  }
  splitPayment(modal) {
    this.splitArea = 0
    this.sameAmount = 1
    this.paymentList = []
    this.showThirdParty = 0
    this.paymentList.push(
      {
        mode: 'Cash',
        amount: '',
        added: 0
      },
      {
        mode: 'Card',
        amount: '',
        added: 0
      },
      {
        mode: 'UPI',
        amount: '',
        added: 0
      },
      {
        mode: 'Cheque',
        amount: '',
        added: 0
      },

    )
    if (this.advancePaymentDetails && this.advancePaymentDetails != '0') {
      this.paymentList.push({
        mode: 'Advance Payment',
        amount: '',
        added: 0
      })
    }
    // console.log(this.paymentList)
    this.modalRef = this.modalService.open(modal, {
      backdrop: 'static',
      backdropClass: "light-blue-backdrop",
      centered: false,
      ariaLabelledBy: "modal-basic-title",
      size: this.advancePaymentDetails ? 'xl' : 'lg'
    });
    // this.openModal(modal, 'lg')
  }
  closePayment() {
    this.paymentType = ''
    this.splitArea = 0
    this.paymentList = []
  }
  paymentMethod(event, i) {
    // console.log(event)
    this.paymentList[i].mode = event.itemData.paymentType
  }
  addPayment(i) {
    if (this.paymentList[i].mode == 'Advance Payment') {
      if (parseInt(this.paymentList[i].amount) > parseInt(this.advancePaymentDetails)) {
        Swal.fire('', 'Advance payment cannot be more than the available amount', 'error')
        this.paymentList[i].amount = 0
      } else {
        this.sameAmount = 0
        this.splitAmountLeft = 0
        let amount = 0;
        for (let i = 0; i < this.paymentList.length; i++) {
          amount += Number(this.paymentList[i].amount)
          // console.log(amount)
          // this.splitAmountLeft-=this.orders.roundedTotalAmt-amount
        }
        this.splitAmountLeft = this.orders.roundedTotalAmt - amount
      }
    } else {
      this.sameAmount = 0
      this.splitAmountLeft = 0
      let amount = 0;
      for (let i = 0; i < this.paymentList.length; i++) {
        amount += Number(this.paymentList[i].amount)
        // console.log(amount)
        // this.splitAmountLeft-=this.orders.roundedTotalAmt-amount
      }
      this.splitAmountLeft = this.orders.roundedTotalAmt - amount
    }
  }
  addPaymentSplit() {
    this.paymentList.push({
      mode: '',
      amount: ''
    })
  }
  savePaymentSplit() {
    this.splitArea = 1
    let amount = 0
    // console.log(this.paymentList)
    for (let i = 0; i < this.paymentList.length; i++) {
      amount += Number(this.paymentList[i].amount)
    }
    if (amount > this.orders.roundedTotalAmt) {
      Swal.fire('', 'Split payment amount cannot be greater then the payable amount!', 'error')
      return false;
    } else if (amount < this.orders.roundedTotalAmt) {
      Swal.fire('', 'Split payment amount cannot be less then the payable amount!', 'error')
      return false;
    }
    else {
      this.paymentType = "split"
      Swal.fire('', 'Bill Splited Sucessfully!', 'success')
      this.modalRef.close()
    }
  }
  loyaltyDiscount(flag) {
    this.loyaltyFlag = flag
    if (flag == true) {
      // this.cardId = this.loyaltyCard[0].card_id
      // let totaldisc = ((this.orders.subtotal * Number(this.loyaltyCard[0].discountValue)) / 100)
      // let disc = totaldisc / this.orders.cartDetails.length
      // for (let i = 0; i < this.orders.cartDetails.length; i++) {
      //   this.orders.cartDetails[i].discount = disc
      // }
      // if (this.pointFlag == true) {
      //   this.applyPoints(true)
      // } else {
      //   this.applyPoints(false)
      // }

     
      for (let i = 0; i < this.orders.cartDetails.length; i++) {
        let cost = Number(this.orders.cartDetails[i]["costPerItem"]) * Number(this.orders.cartDetails[i]["quantity"])
        this.orders.cartDetails[i].disValue = Number(((cost * this.loyaltyCard[0].discountValue) / 100).toFixed(2))
        this.orders.cartDetails[i].discount = Number(((cost * this.loyaltyCard[0].discountValue) / 100).toFixed(2))
      }
      if (this.pointFlag == true) {
        this.applyPoints(true)
      } else {
        this.applyPoints(false)
      }

      // this.orders.discountedAmount = disc
      // this.orders.totalAmt = (this.orders.roundedTotalAmt) - this.orders.discountedAmount
      // this.orders.roundedTotalAmt = Math.round(this.orders.totalAmt)
      // if (this.orders.totalAmt > this.orders.roundedTotalAmt) {
      //   this.orders.roundDiff = this.orders.totalAmt - this.orders.roundedTotalAmt
      // } else {
      //   this.orders.roundDiff = this.orders.roundedTotalAmt - this.orders.totalAmt
      // }
    } else {
      this.cardId = ''
      for (let i = 0; i < this.orders.cartDetails.length; i++) {
        this.orders.cartDetails[i].discount = 0
      }
      if (this.pointFlag == true) {
        this.applyPoints(true)
      } else {
        this.applyPoints(false)
      }
      
    }
   

  }
  partailPayment(modal, flag) {
    // console.log(this.paymentFlag)
    // let notApp=0
    if (flag == true) {
      this.partialAmount = 0
      this.openModal(modal, 'md')
      //   for(let i=0;i<this.orders.cartDetails.length;i++){
      //     if(this.orders.cartDetails[i].type!=4){
      //       notApp=1
      //       break;
      //     }
      //   }
      //  if(notApp==1){
      //   Swal.fire('','Partail Payment Is Only Available For Memberships!','error')
      //   this.paymentFlag=false
      //  }
      //  else {
      //   //  this.partialPayment=1
      //   this.paymentFlag=true
      //    this.openModal(modal,'md')
      //  }
    }
    else {
      this.partialPayments = 0
      this.partialAmount = 0
      this.paymentFlag = false
      this.orders.totalAmt = Number(this.orders.cartDetails[0].cost)
      this.orders.roundedTotalAmt = Number(this.orders.cartDetails[0].cost)
      this.cd.detectChanges()
    }
  }
  cancel() {
    this.partialAmount = 0
    this.partialPayments = 0
    this.paymentFlag = false
    this.modalRef.close()
  }
  addPartialPayment() {
    this.partialPayments = 1
    // let memAmount = Number(this.orders.cartDetails[0].cost)
    if (this.partialAmount >= this.orders.roundedTotalAmt) {
      Swal.fire('', 'Please enter amount less than the total payable amount!', 'error')
      return false
    } else if (this.partialAmount === '' || this.partialAmount === undefined || this.partialAmount === null) {
      Swal.fire('', 'Please enter partial amount', 'error')
      return false
    }
    else {
      this.pendingAmountLeft = this.orders.roundedTotalAmt - this.partialAmount
      this.orders.roundedTotalAmt = this.partialAmount
      this.cd.detectChanges()
      this.modalRef.close()
    }
  }
  viewPastService(modal) {
    this.openModal(modal, 'lg')
  }
  PastOrder(content: any) {
    this.pastOrderData = []
    let params =
      "userId=" +
      this.storage.get("userId") +
      "&password=" +
      this.storage.get("password") +
      "&businessId=" +
      this.storage.get("selectedBusinessId") + "&mobileNo=" + this.orders.mobileNo;
    let url = "https://api.uengage.in/pos/getPastService?" + params;
    this.httpClient.get(url).subscribe((data: any) => {
      this.pastOrderData = data.res;
    });
    this.openModal(content, 'lg');
  }
  addCustomisation() {
    if (this.customAmount <= 0 || isNaN(this.customAmount)) {
      this.tipErrorMsg = 'Amount must be a positive number';
      setTimeout(() => {
        this.tipErrorMsg = '';
      }, 3000);
      return;
    }
    if (this.cartList[this.customIndex].addOnCost == 0) {
      this.cartList[this.customIndex].addOnCost = this.customAmount
      this.orders.cartDetails[this.customIndex].addOnCost = this.customAmount
      // console.log(this.cartList[this.customIndex].addOnCost)
    } else {
      let amount
      // this.cartList[this.customIndex].addOnCost = this.customAmount -  this.cartList[this.customIndex].addOnCost
      if (this.cartList[this.customIndex].addOnCost > this.customAmount) {
        amount = this.cartList[this.customIndex].addOnCost - this.customAmount
      } else {
        amount = this.customAmount - this.cartList[this.customIndex].addOnCost
      }
      this.cartList[this.customIndex].addOnCost = amount
    }
    // console.log(this.cartList[this.customIndex].addOnCost)
    if (this.cartList[this.customIndex].subItems.length == 0) {
      this.cartList[this.customIndex].subItems.push({
        id: this.customDetail.id,
        sp: this.customAmount,
        name: this.customName
      })
    } else {
      this.cartList[this.customIndex].subItems[0].sp = this.customAmount
    }
    // console.log(this.cartList[this.customIndex])
    //  this.cartList[this.customIndex].costPerItem=0
    this.cartList[this.customIndex].costPerItem = Number(this.cartList[this.customIndex].costPerItem) + Number(this.cartList[this.customIndex].addOnCost)
    this.cartList[this.customIndex].cost = Number(this.cartList[this.customIndex].costPerItem) * this.cartList[this.customIndex].quantity
    // this.updateSalonCart()
    if (this.pointFlag == true) {
      this.applyPoints(true)
    } else {
      this.applyPoints(false)
    }
    this.modalRef.close()
    //  console.log(this.orders.cartDetails)
  }
  customSelect(event) {
    // console.log(event.itemData)
    this.customName = event.itemData.customName
    this.customDetail = event.itemData
    this.customAmount = event.itemData.sp
  }
  customSelectOpen(modal, i) {
    // console.log(this.cartList[i].subItems)
    if (this.cartList[i].subItems.length != 0) {
      this.customAmount = this.cartList[i].subItems[0].sp
      this.customList = this.cartList[i].subItems[0].id
    } else {
      this.customAmount = 0
    }
    this.openModal(modal, 'lg')
    this.customIndex = i
  }
  updatePrice() {
    this.orders.totalAmt = 0
    this.orders.subtotal = 0
    this.orders.discount = 0
    this.orders.tax = 0
    // console.log(this.orders.cartDetails)
    for (let i = 0; i < this.orders.cartDetails.length; i++) {
      let itemValue = 0
      let tax = 0
      let templateValue
      itemValue = Number(this.orders.cartDetails[i].costPerItem) * Number(this.orders.cartDetails[i].quantity)
      this.orders.subtotal += itemValue
      tax = ((itemValue - this.orders.cartDetails[i].discount) * this.orders.cartDetails[i].tax) / 100
      this.orders.tax += tax
      this.orders.discount += Number(this.orders.cartDetails[i].discount)
      this.orders.discountedAmount += Number(this.orders.cartDetails[i].discount)
      if (this.orders.cartDetails[i].templateCartDetails.length != 0 && this.orders.cartDetails[i].templateCartDetails.length != undefined) {
        for (let j = 0; j < this.orders.cartDetails[i].templateCartDetails.length; j++) {
          templateValue = Number(this.orders.cartDetails[i].templateCartDetails[j].costPerItem) * Number(this.orders.cartDetails[i].templateCartDetails[j].quantity)
          this.orders.subtotal += templateValue
          tax = (templateValue * this.orders.cartDetails[i].templateCartDetails[j].tax) / 100
          this.orders.tax += tax
          // this.orders.discount += Number(this.orders.cartDetails[i].discount)
        }
      }
    }
    this.orders.totalAmt = (this.orders.subtotal + this.orders.tax) - this.orders.discount
    this.orders.roundedTotalAmt = Math.round(this.orders.totalAmt)
    if (this.orders.totalAmt > this.orders.roundedTotalAmt) {
      this.orders.roundDiff = this.orders.totalAmt - this.orders.roundedTotalAmt
    } else {
      this.orders.roundDiff = this.orders.roundedTotalAmt - this.orders.totalAmt
    }
    // this.orders.totalAmt = Math.round(this.orders.totalAmt)
    // console.log(this.orders.discount)
    // this.orders.discountedAmount = this.orders.discount
    // this.adjustPayment=this.orders.totalAmt.toString()
    // console.log(this.orders)
    // console.log(this.orders.cartDetails)
    // console.log(this.cartList)
    this.cart = new cartItems();
    this.orders.itemCount = this.cartList.length;
    // console.log(this.orders)
    let orderIndex = this.ordersArray.findIndex(
      (e) => e.orderId == this.orders.orderId
    );
    this.orders.updatedAt = this.getDate();
    if (orderIndex > -1) {
      this.ordersArray[orderIndex] = this.orders;
    } else {
      this.ordersArray.push(this.orders);
    }
    // console.log(this.ordersArray)
    this.storage.set("existingOrders", this.ordersArray);
    this.getItemLength()
    this.cd.detectChanges();
  }
  sendDiscReq(modal) {
    if (this.discLimit <= this.maxDiscountAllowed) {
      Swal.fire('', 'Discount limit should be greater then current limit!', 'error')
      return false;
    }
    else {
      let params =
        "userId=" +
        this.storage.get("userId") +
        "&password=" +
        this.storage.get("password") +
        "&businessId=" +
        this.storage.get("selectedBusinessId") +
        "&discountPer=" + this.discLimit +
        "&customer_mobileNo=" + this.orders.mobileNo;
      let url = "https://api.uengage.in/pos/sendDiscountRequest?" + params;
      this.httpClient.get(url).subscribe((data: any) => {
        if (data.status == 1) {
          this.modalRef.close()
          this.openModal(modal, 'md')
        }
      });
    }
  }
  verifyOtp() {
    let params =
      "userId=" +
      this.storage.get("userId") +
      "&password=" +
      this.storage.get("password") +
      "&businessId=" +
      this.storage.get("selectedBusinessId") +
      "&otp=" + this.otp +
      "&customer_mobileNo=" + this.orders.mobileNo;
    let url = "https://api.uengage.in/pos/verifyDiscountOTP?" + params;
    this.httpClient.get(url).subscribe((data: any) => {
      if (data.status == 1) {
        Swal.fire('', data.msg, 'success')
        this.orders.discountLimit = this.discLimit
        let orders = this.storage.get("existingOrders")
        for (let i = 0; i < orders.length; i++) {
          if (orders[i].mobileNo == this.orders.mobileNo) {
            // this.orders.discountLimit = this.discLimit
            orders[i].discountLimit = this.discLimit;
          }
        }
        this.storage.set("existingOrders", orders);
        this.getDisc()
        this.modalRef.close()
      }
    });
  }
  getDisc() {
    if (this.orders.discountLimit != undefined) {
      this.maxDiscountAllowed = this.orders.discountLimit;
    } else {
      let selectedModules = this.storage
        .get("modules")
        .filter((e) => e.moduleId == 31);
      if (selectedModules[0] != undefined) {
        this.maxDiscountAllowed = selectedModules[0]["maxDiscount"];
      }
    }
    // console.log(this.maxDiscountAllowed)
  }
  createDetailForm() {
    this.detailForm = this.fb.group({
      name: ['', [Validators.required]],
      number: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      dateofbirth: [''],
      anniversary: [''],
      billDate: ['']
    })
  }
  get c() { return this.detailForm.controls; }
  searchCustomerByNumber(number) {
    if (number.length == 0) {
      let element: HTMLElement = document.getElementById("customerlistnumber") as HTMLElement;
      element.style.display = 'none';
      // let element1: HTMLElement = document.getElementById("customerlistname") as HTMLElement;
      // element1.style.display='none';
    }
    // if(number.length<3){
    //   this.detailForm.controls.name.setValue('')
    // }
    if (number.length >= 3) {
      let element: HTMLElement = document.getElementById("customerlistnumber") as HTMLElement;
      element.style.display = 'block';
      this.customernumberData = []
      let params =
        "userId=" +
        this.storage.get("userId") +
        "&password=" +
        this.storage.get("password") +
        "&businessId=" +
        this.storage.get("selectedBusinessId") +
        "&searchString=" + number;
      this.httpClient.get("https://api.uengage.in/pos/searchUser?" + params, {}).subscribe((res: any) => {
        if (res.status == 1) {
          this.customernumberData = res.user
          this.cd.detectChanges()
        }
      });
    }
  }
  selectCustomerNumber(data) {
    let element: HTMLElement = document.getElementById("customerlistnumber") as HTMLElement;
    element.style.display = 'none';
    this.customernumberData = []
    this.detailForm.controls.name.setValue(data.contactName)
    this.detailForm.controls.number.setValue(data.mobileNo)
    this.detailForm.controls.gender.setValue(data.gender)
    this.detailForm.controls.dateofbirth.setValue(data.dob)
    this.detailForm.controls.anniversary.setValue(data.anniversary)
  }
  blankNumber() {
    let element: HTMLElement = document.getElementById("customerlistnumber") as HTMLElement;
    element.style.display = 'none';
  }
  blankName() {
    let element: HTMLElement = document.getElementById("customerlistname") as HTMLElement;
    element.style.display = 'none';
  }
  searchCustomerByName(name) {
    if (name.length == 0) {
      let element: HTMLElement = document.getElementById("customerlistname") as HTMLElement;
      element.style.display = 'none';
      // let element1: HTMLElement = document.getElementById("customerlistnumber") as HTMLElement;
      // element1.style.display='none';
    }
    // if(name.length<3){
    //   this.detailForm.controls.number.setValue('')
    // }
    if (name.length >= 3) {
      let element: HTMLElement = document.getElementById("customerlistname") as HTMLElement;
      element.style.display = 'block';
      this.customerData = []
      let params =
        "userId=" +
        this.storage.get("userId") +
        "&password=" +
        this.storage.get("password") +
        "&businessId=" +
        this.storage.get("selectedBusinessId") +
        "&searchString=" + name;
      this.httpClient.get("https://api.uengage.in/pos/searchUser?" + params, {}).subscribe((res: any) => {
        if (res.status == 1) {
          this.customerData = res.user
          this.cd.detectChanges()
        }
      });
    }
  }
  selectCustomer(data) {
    let element: HTMLElement = document.getElementById("customerlistname") as HTMLElement;
    element.style.display = 'none';
    this.customerData = []
    this.detailForm.controls.name.setValue(data.contactName)
    this.detailForm.controls.number.setValue(data.mobileNo)
    this.detailForm.controls.gender.setValue(data.gender)
    this.detailForm.controls.dateofbirth.setValue(data.dob)
    this.detailForm.controls.anniversary.setValue(data.anniversary)
    // this.count = '1';
    // this.count2 = '';
  }
  addNewOrder() {
    this.submitted1 = true
    if (this.detailForm.valid) {
      let data = this.detailForm.value
      this.newOrder.orderType = "4";
      this.newOrder.source = this.source;
      this.newOrder.sourceId = this.sourceId;
      this.newOrder.customerName = data.name
      this.newOrder.mobileNo = data.number
      this.newOrder.gender = data.gender
      this.newOrder.dob = data.dateofbirth
      this.newOrder.anniversary = data.anniversary
      // this.newOrder.customerName = data.name
      this.newOrder.invoicedAt = data.billDate.replace('T', ' ');
      this.newOrder.status = "0";
      this.newOrder.businessId = this.storage.get("selectedBusinessId");
      this.newOrder.orderId = this.randomNumber();

      this.cartList = Array<cartItems>();
      this.newOrder.cartDetails = this.cartList;

      this.ordersArray.push(this.newOrder);
      this.existingOrderArray.push(this.newOrder);
      this.storage.set("existingOrders", this.ordersArray);
      this.storage.set("tax_flag", true)
      this.storage.set("mem_flag", '')
      this.orders = this.newOrder;
      this.orders.cartDetails = [];
      this.newOrder = new newOrderDetails();
      this.modalRef.close();
      Swal.fire('', 'Order Created Successfully!', 'success')
      this.cd.markForCheck();
    }
  }
  getJobCard() {
    this.jobCard = []
    for (let i = 0; i < this.orders.cartDetails.length; i++) {
      if (this.orders.cartDetails[i].workerName.length == 0) {
        this.jobCard.push({
          service: this.orders.cartDetails[i].itemName,
          staff: ''
        })
      } else {
        if (this.orders.cartDetails[i].workerName.length == 1) {
          let index = this.staffdata.findIndex(e => e.id == this.orders.cartDetails[i].workerName[0])
          this.jobCard.push({
            service: this.orders.cartDetails[i].itemName,
            staff: this.staffdata[index].stylistName
          })
        } else {
          this.jobCard.push({
            service: this.orders.cartDetails[i].itemName,
            staff: ''
          })
          let name = ''
          for (let j = 0; j < this.orders.cartDetails[i].workerName.length; j++) {
            let staffindex = this.staffdata.findIndex(e => e.id == this.orders.cartDetails[i].workerName[j])
            name += this.staffdata[staffindex].stylistName + ","
            // this.jobCard[i].staff = this.staffdata[staffindex].stylistName
          }
          this.jobCard[i].staff = name
        }
      }
    }
    if (this.jobCard.length < 12) {
      let length = 12 - this.jobCard.length
      for (let i = 0; i < length; i++) {
        this.jobCard.push({
          service: '',
          staff: ''
        })
      }
    }
    this.cd.detectChanges()
    if (this.deviceType == 'web') {
      const printContent = document.getElementById("printJobCard");
      const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
      WindowPrt.document.write(printContent.innerHTML);
      WindowPrt.document.close();
      WindowPrt.focus();
      WindowPrt.print();
      WindowPrt.close();
    } else {
      let printContents = document.getElementById('printJobCard').innerHTML;
      // console.log(printContents)
      const popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
            <title>Print Page</title>
            <style>
              /* Custom styles for printing */
            </style>
          </head>
          <body onload="window.print();">
            ${printContents}
          </body>
        </html>`
      );
      popupWin.document.close();
    }
  }
  checkTax(value) {
    this.taxFlag = value
    // this.updateSalonCart()
    // if(!this.taxFlag){

    // }
    this.orders.isTaxFlag = value;
    for (let i = 0; i < this.orders.cartDetails.length; i++) {
      this.orders.cartDetails[i].cost = Number(this.orders.cartDetails[i].cost) + Number(this.orders.cartDetails[i].discount)
    }
    if (this.pointFlag == true) {
      this.applyPoints(true)
    } else {
      this.applyPoints(false)
    }
    // if (value == false) {
    //   this.orders.totalAmt = (Number(this.orders.subtotal) - Number(this.orders.discount))
    //   this.orders.roundedTotalAmt = Math.round(this.orders.totalAmt)
    //   if (this.orders.totalAmt > this.orders.roundedTotalAmt) {
    //     this.orders.roundDiff = this.orders.totalAmt - this.orders.roundedTotalAmt
    //   } else {
    //     this.orders.roundDiff = this.orders.roundedTotalAmt - this.orders.totalAmt
    //   }
    // } else {
    //   this.orders.totalAmt = (Number(this.orders.subtotal) + Number(this.orders.tax)) - Number(this.orders.discount)
    //   this.orders.roundedTotalAmt = Math.round(this.orders.totalAmt)
    //   if (this.orders.totalAmt > this.orders.roundedTotalAmt) {
    //     this.orders.roundDiff = this.orders.totalAmt - this.orders.roundedTotalAmt
    //   } else {
    //     this.orders.roundDiff = this.orders.roundedTotalAmt - this.orders.totalAmt
    //   }
    // }
  }

  checkItemTax(val: boolean) {
    this.itemTaxFlag = val
    if (this.pointFlag == true) {
      this.applyPoints(true)
    } else {
      this.applyPoints(false)
    }

  }
  addInclusiveTax(val: boolean) {
    this.isIncluseTax = val
    // const this.orders=this.storage.get('existingOrders')

    this.afterCalculationData(val);

    // this.updateTotalDetails()


    // if (this.pointFlag == true) {
    //   this.applyPoints(true)
    // } else {
    //   this.applyPoints(false)
    // }
    this.cd.detectChanges()


  }

  updateSalonCart() {
    this.orders.totalAmt = 0
    this.orders.roundDiff = 0
    this.orders.roundedTotalAmt = 0
    this.orders.discountedAmount = 0
    this.orders.subtotal = 0
    this.orders.promoDiscount = 0
    this.orders.tax = 0
    this.orders.walletPoints = 0
    this.orders.memPerDiscount = 0
    let discount = 0;
    let pointsTotal = 0;
    let promoDis = 0
    let memTotal = 0
    let memPer = 0
    // console.log(this.orders.cartDetails)
    for (let i = 0; i < this.orders.cartDetails.length; i++) {
      let itemValue = 0
      let tax = 0
      // console.log(this.orders.cartDetails[i].costPerItem)
      itemValue = Number(this.orders.cartDetails[i].costPerItem) * Number(this.orders.cartDetails[i].quantity)
      this.orders.subtotal += itemValue
      tax = ((itemValue - this.orders.cartDetails[i].discount - this.orders.cartDetails[i].points - this.orders.cartDetails[i].membershipAmount) * this.orders.cartDetails[i].tax) / 100
      this.orders.tax += tax
      pointsTotal += Number(this.orders.cartDetails[i].points)
      memTotal += Number(this.orders.cartDetails[i].membershipAmount)
      discount += Number(this.orders.cartDetails[i].discount)
      // promoDis += Number(this.orders.cartDetails[i].promoDiscount)
      // memPer += Number(this.orders.cartDetails[i].memDis)
    }
    // console.log(memTotal)
    // console.log(discount)
    // console.log(pointsTotal)
    // console.log(this.orders)
    if (this.category_id == 4) {
      this.orders.subtotal = this.itemTaxFlag ? this.orders.subtotal : this.orders.subtotal + this.orders.tax
      this.orders.tax = this.itemTaxFlag ? this.orders.tax : 0
      if (this.taxFlag == true) {
        this.orders.totalAmt = (this.orders.subtotal + this.orders.tax) - discount - pointsTotal - memTotal
      } else {
        this.orders.totalAmt = this.orders.subtotal - discount - pointsTotal - memTotal
      }

    } else {
      this.orders.totalAmt = (this.orders.subtotal + this.orders.tax) - discount
    }
    // console.log(this.orders.totalAmt)
    this.orders.roundedTotalAmt = Math.round(this.orders.totalAmt)
    if (this.orders.totalAmt > this.orders.roundedTotalAmt) {
      this.orders.roundDiff = this.orders.totalAmt - this.orders.roundedTotalAmt
    } else {
      this.orders.roundDiff = this.orders.roundedTotalAmt - this.orders.totalAmt
    }
    // console.log(discount)
    // console.log(pointsTotal)
    this.orders.discountedAmount = discount
    this.orders.memPerDiscount = memPer
    this.orders.walletPoints = pointsTotal
    this.orders.promoDiscount = promoDis
    this.cd.detectChanges()
    // console.log(this.orders.totalAmt)
    if (this.paymentType == 'membership' && this.category_id == 4) {
      if (this.orders.subtotal <= this.membershipData[0].membershipAmount) {
        this.membershipUsed = this.orders.subtotal - this.orders.walletPoints - this.orders.discount - (this.orders.promoDiscount ?? 0)
        // console.log(this.membershipUsed)
        this.walletLeft = this.membershipData[0].membershipAmount - this.membershipUsed
        this.orders.roundedTotalAmt = 0
        this.orders.totalAmt = 0
        if (this.taxFlag == true) {
          this.membershipUsed += this.orders.tax
        }
        // this.paymentType = "membership"
        // this.disableMode = 1
        // this.taxFlag = false
        this.cd.detectChanges()
      } else {
        this.disableMode = 0
        // this.taxFlag = true
        this.walletLeft = 0
        this.membershipUsed = this.membershipData[0].membershipAmount
        let disPer = (this.membershipUsed / this.orders.subtotal) * 100
        // console.log(disPer)
        for (let i = 0; i < this.orders.cartDetails.length; i++) {
          this.orders.cartDetails[i].membershipAmount = Number(((Number(this.orders.cartDetails[i]["costPerItem"]) * disPer) / 100).toFixed(2))
        }
        this.orders.totalAmt = 0
        this.orders.roundDiff = 0
        this.orders.roundedTotalAmt = 0
        this.orders.discountedAmount = 0
        this.orders.subtotal = 0
        this.orders.promoDiscount = 0
        this.orders.tax = 0
        this.orders.walletPoints = 0
        this.orders.memPerDiscount = 0
        let discount = 0;
        let pointsTotal = 0;
        let promoDis = 0
        let memTotal = 0
        let memPer = 0
        // console.log(this.orders.cartDetails)
        for (let i = 0; i < this.orders.cartDetails.length; i++) {
          let itemValue = 0
          let tax = 0
          // console.log(this.orders.cartDetails[i].costPerItem)
          itemValue = Number(this.orders.cartDetails[i].costPerItem) * Number(this.orders.cartDetails[i].quantity)
          this.orders.subtotal += itemValue
          tax = ((itemValue - this.orders.cartDetails[i].discount - this.orders.cartDetails[i].points - this.orders.cartDetails[i].membershipAmount) * this.orders.cartDetails[i].tax) / 100
          this.orders.tax += tax
          pointsTotal += Number(this.orders.cartDetails[i].points)
          memTotal += Number(this.orders.cartDetails[i].membershipAmount)
          discount += Number(this.orders.cartDetails[i].discount)
          // promoDis += Number(this.orders.cartDetails[i].promoDiscount)
          // memPer += Number(this.orders.cartDetails[i].memDis)
        }
        // console.log(memTotal)
        // console.log(discount)
        // console.log(pointsTotal)
        // console.log(this.orders)
        if (this.category_id == 4) {
          this.orders.subtotal = this.itemTaxFlag ? this.orders.subtotal : this.orders.subtotal + this.orders.tax
          this.orders.tax = this.itemTaxFlag ? this.orders.tax : 0
          if (this.taxFlag == true) {
            this.orders.totalAmt = (this.orders.subtotal + this.orders.tax) - discount - pointsTotal - memTotal
          } else {
            this.orders.totalAmt = this.orders.subtotal - discount - pointsTotal - memTotal
          }
        } else {
          this.orders.totalAmt = (this.orders.subtotal + this.orders.tax) - discount
        }
        // console.log(this.orders.totalAmt)
        this.orders.roundedTotalAmt = Math.round(this.orders.totalAmt)
        if (this.orders.totalAmt > this.orders.roundedTotalAmt) {
          this.orders.roundDiff = this.orders.totalAmt - this.orders.roundedTotalAmt
        } else {
          this.orders.roundDiff = this.orders.roundedTotalAmt - this.orders.totalAmt
        }
        // console.log(discount)
        // console.log(pointsTotal)
        this.orders.discountedAmount = discount
        this.orders.memPerDiscount = memPer
        this.orders.walletPoints = pointsTotal
        this.orders.promoDiscount = promoDis
        this.cd.detectChanges()
        // console.log(this.orders.totalAmt)
        // if (this.paymentType == 'membership' && this.category_id == 4) {
        //   if (this.orders.totalAmt <= this.membershipData[0].membershipAmount) {
        //     // console.log("hey")
        //     this.walletLeft = this.membershipData[0].membershipAmount - this.orders.totalAmt
        //     this.membershipUsed = this.orders.totalAmt
        //     this.orders.roundedTotalAmt = 0
        //     this.orders.totalAmt = 0
        //     this.disableMode = 1
        //     this.cd.detectChanges()
        //   } else {
        //     this.walletLeft = 0
        //     this.membershipUsed = this.membershipData[0].membershipAmount
        //     let amt = this.orders.totalAmt - this.membershipData[0].membershipAmount
        //     this.orders.totalAmt = amt
        //     this.orders.roundedTotalAmt = Math.round(this.orders.totalAmt)
        //     if (this.orders.totalAmt > this.orders.roundedTotalAmt) {
        //       this.orders.roundDiff = this.orders.totalAmt - this.orders.roundedTotalAmt
        //     } else {
        //       this.orders.roundDiff = this.orders.roundedTotalAmt - this.orders.totalAmt
        //     }
        //     this.disableMode = 0
        //     this.cd.detectChanges()
        //   }
        // }

        // this.cart = new cartItems();
        // this.orders.itemCount = this.cartList.length;
        // // console.log(this.orders)
        // let orderIndex = this.ordersArray.findIndex(
        //   (e) => e.orderId == this.orders.orderId
        // );
        // this.orders.updatedAt = this.getDate();
        // if (orderIndex > -1) {
        //   this.ordersArray[orderIndex] = this.orders;
        // } else {
        //   this.ordersArray.push(this.orders);
        // }
        // // console.log(this.ordersArray)
        // this.storage.set("existingOrders", this.ordersArray);
        // this.getItemLength()
        // this.cd.detectChanges();
      }
    }
    const isGST = this.isIncluseTax ? this.isIncluseTax : null;
    this.afterCalculationData(isGST);
    this.cart = new cartItems();
    this.orders.itemCount = this.cartList.length;
    // console.log(this.orders)
    let orderIndex = this.ordersArray.findIndex(
      (e) => e.orderId == this.orders.orderId
    );
    this.orders.updatedAt = this.getDate();
    if (orderIndex > -1) {
      this.ordersArray[orderIndex] = this.orders;
    } else {
      this.ordersArray.push(this.orders);
    }
    // console.log(this.ordersArray)
    this.storage.set("existingOrders", this.ordersArray);
    this.getItemLength()
    this.cd.detectChanges();
    // }
  }
  delItem(i) {
    let index = this.selectedItem.findIndex(e => e.id == this.cartList[i].itemId)
    if (index > -1) {
      this.selectedItem[index].qty = 0
    }
    this.cartList.splice(i, 1)
    this.orders.cartDetails.splice(i, 1);
    if (this.orders.cartDetails.length == 0) {
      this.orders.posDisType = ''
      this.orders.posDisValue = 0
      this.orders.discountedAmount = 0
    }
    if (this.orders.posDisType != '' && this.orders.posDisType != null && this.orders.posDisType != undefined) {
      this.addPosDiscount()
    } else {
      this.checkDiscount()
    }
  }
  checkItem(item, modal) {
    // console.log(item)
    this.addedItem = []
    this.addedItem = item
    for (let i = 0; i < this.menuTemplates.length; i++) {
      for (let j = 0; j < this.menuTemplates[i].template.length; j++) {
        if (this.menuTemplates[i].template[j].selected == 1) {
          this.menuTemplates[i].template[j].selected = 0
        }
      }
    }
    // console.log(this.menuTemplates)
    if (item.viewType == 3) {
      this.openModal(modal, 'lg')
    } else if (item.viewType == 1 && item.templates.length != 0) {
      this.itemTemplates = []
      for (let i = 0; i < item.templates.length; i++) {
        let index = this.menuTemplates.findIndex(e => e.templateId == item.templates[i].templateId)
        if (index >= 0) {
          this.itemTemplates.push({
            templates: this.menuTemplates[index].template,
            minQty: item.templates[i].minQty,
            maxQty: item.templates[i].maxQty,
            templateName: this.menuTemplates[index].template[0].sectionName
          })
        }
      }
      // console.log(this.itemTemplates)
      this.openModal(modal, 'lg')
    }
    else if (item.viewType == null && item.templates.length != 0) {
      this.withVariant = 0
      this.itemTemplates = []
      for (let i = 0; i < item.templates.length; i++) {
        let index = this.menuTemplates.findIndex(e => e.templateId == item.templates[i].templateId)
        if (index >= 0) {
          this.itemTemplates.push({
            templates: this.menuTemplates[index].template,
            minQty: item.templates[i].minQty,
            maxQty: item.templates[i].maxQty,
            templateName: this.menuTemplates[index].template[0].sectionName
          })
        }
      }
      this.openModal(modal, 'lg')
      // console.log(this.itemTemplates)
    }
    else if (item.viewType == null && item.variant != undefined) {
      this.withVariant = 1
      this.itemTemplates = []
      for (let i = 0; i < item.variant.length; i++) {
        this.itemTemplates.push({
          variants: item.variant[i],
          templates: []
        })
      }
      this.openModal(modal, 'lg')
      // console.log(this.itemTemplates)
      this.selectTemplate(this.itemTemplates[0].variants, 0)
    }
    else {
      // normal item add to cart
      if (this.cartList.length == 0 || this.cartList == undefined || this.cartList == null) {
        item.qty = 1
        this.cart.itemId = item.id;
        this.cart.cost = 0;
        this.cart.quantity = item.qty;
        this.cart.itemName = item.name;
        this.cart.item = '';
        this.cart.costPerItem = item.price;
        this.cart.tax = item.tax;
        this.cart.exciseApplicable = 0;
        this.cart.taxPer = 0;
        this.cart.type = 0;
        this.cart.bId = this.id;
        this.cart.workerName = '';
        this.cart.discount = 0;
        this.cart.specialInstruction = '';
        this.cartList.push(this.cart);
        this.orders.cartDetails.push(this.cart);
      } else {
        let index = this.cartList.findIndex(e => e.itemId == item.id)
        if (index > -1) {
          this.cartList[index].quantity = item.qty + 1
        } else {
          item.qty = 1
          this.cart.itemId = item.id;
          this.cart.cost = 0;
          this.cart.quantity = item.qty;
          this.cart.itemName = item.name;
          this.cart.item = '';
          this.cart.bId = this.id;
          this.cart.costPerItem = item.price;
          this.cart.tax = item.tax;
          this.cart.exciseApplicable = 0;
          this.cart.taxPer = 0;
          this.cart.type = 0;
          this.cart.workerName = '';
          this.cart.discount = 0;
          this.cart.specialInstruction = '';
          this.cartList.push(this.cart);
          this.orders.cartDetails.push(this.cart);
        }
      }
      this.cd.detectChanges()
      this.updatePrice()
    }
  }
  addItemToCart() {
    if (this.withVariant == 1) {
      if (this.cartList.length == 0 || this.cartList == undefined || this.cartList == null) {
        this.itemTemplates[this.variantIndex].variants.quantity = 1
        this.cart.itemId = this.itemTemplates[this.variantIndex].variants.id;
        this.cart.cost = 0;
        this.cart.quantity = 1;
        this.cart.itemName = this.addedItem["name"] + " - " + this.itemTemplates[this.variantIndex].variants.itemName;
        this.cart.item = '';
        this.cart.costPerItem = this.itemTemplates[this.variantIndex].variants.sp;
        this.cart.tax = this.itemTemplates[this.variantIndex].variants.tax;
        this.cart.exciseApplicable = 0;
        this.cart.taxPer = 0;
        this.cart.type = 0;
        this.cart.bId = this.id;
        this.cart.workerName = '';
        this.cart.discount = 0;
        this.cart.specialInstruction = '';
        for (let i = 0; i < this.variantTemplates.length; i++) {
          for (let j = 0; j < this.variantTemplates[i].templates.length; j++) {
            if (this.variantTemplates[i].templates[j].selected == 1) {
              // console.log(this.itemTemplates[i].templates[j])
              this.cart.templateCartDetails.push({
                itemId: this.variantTemplates[i].templates[j].id,
                cost: this.variantTemplates[i].templates[j].sp,
                quantity: 1,
                itemName: this.variantTemplates[i].templates[j].itemName,
                costPerItem: this.variantTemplates[i].templates[j].sp,
                tax: this.variantTemplates[i].templates[j].tax,
                taxPer: 0
              })
            }
          }
        }
        this.cartList.push(this.cart);
        this.orders.cartDetails.push(this.cart);
        this.modalRef.close()
        // console.log(this.cartList)
      }
      else {
        // console.log(this.cartList)
        // console.log(this.itemTemplates[this.variantIndex].variants)
        // console.log(this.variantTemplates)
        let index = this.cartList.findIndex(e => e.itemId == this.itemTemplates[this.variantIndex].variants.id)
        if (index > -1) {
          if (this.cartList[index].templateCartDetails.length != 0 && this.cartList[index].templateCartDetails != undefined) {
            let cartString = this.cartList[index].itemId.toString()
            let addedItem = this.itemTemplates[this.variantIndex].variants.id.toString()
            let cartRes = ''
            let itemRes = ''
            for (let i = 0; i < this.cartList[index].templateCartDetails.length; i++) {
              cartRes = cartString.concat(this.cartList[index].templateCartDetails[i].itemId.toString())
            }
            for (let i = 0; i < this.variantTemplates.length; i++) {
              for (let j = 0; j < this.variantTemplates[i].templates.length; j++) {
                if (this.variantTemplates[i].templates[j].selected == 1) {
                  itemRes = addedItem.concat(this.variantTemplates[i].templates[j].id)
                }
              }
            }
            if (cartRes == itemRes) {
              // this.cartList[index].quantity += 1
              // if (this.cartList[index].templateCartDetails.length != 0) {
              //   for (let i = 0; i < this.cartList[index].templateCartDetails.length; i++) {
              //     this.cartList[index].templateCartDetails[i].quantity += 1
              //   }
              // }
              let cartIndex = this.orders.cartDetails.findIndex(e => e.itemId == this.itemTemplates[this.variantIndex].variants.id)
              this.orders.cartDetails[cartIndex].quantity += 1
              if (this.orders.cartDetails[cartIndex].templateCartDetails.length != 0) {
                for (let i = 0; i < this.orders.cartDetails[cartIndex].templateCartDetails.length; i++) {
                  this.orders.cartDetails[cartIndex].templateCartDetails[i].quantity += 1
                }
              }
            } else {
              this.itemTemplates[this.variantIndex].variants.quantity = 1
              this.cart.itemId = this.itemTemplates[this.variantIndex].variants.id;
              this.cart.cost = 0;
              this.cart.quantity = this.itemTemplates[this.variantIndex].variants.quantity;
              this.cart.itemName = this.addedItem["name"] + " - " + this.itemTemplates[this.variantIndex].variants.itemName;
              this.cart.item = '';
              this.cart.bId = this.id;
              this.cart.costPerItem = this.itemTemplates[this.variantIndex].variants.sp;
              this.cart.tax = this.itemTemplates[this.variantIndex].variants.tax;
              this.cart.exciseApplicable = 0;
              this.cart.taxPer = 0;
              this.cart.type = 0;
              this.cart.workerName = '';
              this.cart.discount = 0;
              this.cart.specialInstruction = '';
              for (let i = 0; i < this.variantTemplates.length; i++) {
                for (let j = 0; j < this.variantTemplates[i].templates.length; j++) {
                  if (this.variantTemplates[i].templates[j].selected == 1) {
                    // console.log(this.itemTemplates[i].templates[j])
                    this.cart.templateCartDetails.push({
                      itemId: this.variantTemplates[i].templates[j].id,
                      cost: this.variantTemplates[i].templates[j].sp,
                      quantity: 1,
                      itemName: this.variantTemplates[i].templates[j].itemName,
                      costPerItem: this.variantTemplates[i].templates[j].sp,
                      tax: this.variantTemplates[i].templates[j].tax,
                      taxPer: 0
                    })
                  }
                }
              }
              this.cartList.push(this.cart);
              this.orders.cartDetails.push(this.cart);
            }
          } else {
            this.itemTemplates[this.variantIndex].variants.quantity = 1
            this.cart.itemId = this.itemTemplates[this.variantIndex].variants.id;
            this.cart.cost = 0;
            this.cart.quantity = this.itemTemplates[this.variantIndex].variants.quantity;
            this.cart.itemName = this.addedItem["name"] + " - " + this.itemTemplates[this.variantIndex].variants.itemName;
            this.cart.item = '';
            this.cart.bId = this.id;
            this.cart.costPerItem = this.itemTemplates[this.variantIndex].variants.sp;
            this.cart.tax = this.itemTemplates[this.variantIndex].variants.tax;
            this.cart.exciseApplicable = 0;
            this.cart.taxPer = 0;
            this.cart.type = 0;
            this.cart.workerName = '';
            this.cart.discount = 0;
            this.cart.specialInstruction = '';
            for (let i = 0; i < this.variantTemplates.length; i++) {
              for (let j = 0; j < this.variantTemplates[i].templates.length; j++) {
                if (this.variantTemplates[i].templates[j].selected == 1) {
                  // console.log(this.itemTemplates[i].templates[j])
                  this.cart.templateCartDetails.push({
                    itemId: this.variantTemplates[i].templates[j].id,
                    cost: this.variantTemplates[i].templates[j].sp,
                    quantity: 1,
                    itemName: this.variantTemplates[i].templates[j].itemName,
                    costPerItem: this.variantTemplates[i].templates[j].sp,
                    tax: this.variantTemplates[i].templates[j].tax,
                    taxPer: 0
                  })
                }
              }
            }
            this.cartList.push(this.cart);
            this.orders.cartDetails.push(this.cart);
          }
        } else {
          this.itemTemplates[this.variantIndex].variants.quantity = 1
          this.cart.itemId = this.itemTemplates[this.variantIndex].variants.id;
          this.cart.cost = 0;
          this.cart.quantity = this.itemTemplates[this.variantIndex].variants.quantity;
          this.cart.itemName = this.addedItem["name"] + " - " + this.itemTemplates[this.variantIndex].variants.itemName;
          this.cart.item = '';
          this.cart.bId = this.id;
          this.cart.costPerItem = this.itemTemplates[this.variantIndex].variants.sp;
          this.cart.tax = this.itemTemplates[this.variantIndex].variants.tax;
          this.cart.exciseApplicable = 0;
          this.cart.taxPer = 0;
          this.cart.type = 0;
          this.cart.workerName = '';
          this.cart.discount = 0;
          this.cart.specialInstruction = '';
          for (let i = 0; i < this.variantTemplates.length; i++) {
            for (let j = 0; j < this.variantTemplates[i].templates.length; j++) {
              if (this.variantTemplates[i].templates[j].selected == 1) {
                // console.log(this.itemTemplates[i].templates[j])
                this.cart.templateCartDetails.push({
                  itemId: this.variantTemplates[i].templates[j].id,
                  cost: this.variantTemplates[i].templates[j].sp,
                  quantity: 1,
                  itemName: this.variantTemplates[i].templates[j].itemName,
                  costPerItem: this.variantTemplates[i].templates[j].sp,
                  tax: this.variantTemplates[i].templates[j].tax,
                  taxPer: 0
                })
              }
            }
          }
          this.cartList.push(this.cart);
          this.orders.cartDetails.push(this.cart);
        }
      }
      this.cd.detectChanges()
      this.modalRef.close()
      if (this.orders.posDisType != '' && this.orders.posDisType != null && this.orders.posDisType != undefined) {
        this.addPosDiscount()
      } else {
        this.checkDiscount()
      }
    }
    else {
      // console.log(this.itemTemplates)
      let minQty = this.itemTemplates[0].minQty
      let count = 0
      for (let i = 0; i < this.itemTemplates.length; i++) {
        for (let j = 0; j < this.itemTemplates[i].templates.length; j++) {
          if (this.itemTemplates[i].templates[j].selected == 1) {
            count += 1
          }
        }
      }
      if (Number(count) < Number(minQty)) {
        Swal.fire('', 'Please select minimum of ' + minQty + ' items', 'error')
        return
      } else {
        if (this.cartList.length == 0 || this.cartList == undefined || this.cartList == null) {
          this.addedItem["qty"] = 1
          this.cart.itemId = this.addedItem["id"];
          this.cart.cost = 0;
          this.cart.quantity = this.addedItem["qty"];
          this.cart.itemName = this.addedItem["name"];
          this.cart.item = '';
          this.cart.costPerItem = this.addedItem["price"];
          this.cart.tax = this.addedItem["tax"];
          this.cart.exciseApplicable = 0;
          this.cart.taxPer = 0;
          this.cart.type = 0;
          this.cart.bId = this.id;
          this.cart.workerName = '';
          this.cart.discount = 0;
          this.cart.specialInstruction = '';
          for (let i = 0; i < this.itemTemplates.length; i++) {
            for (let j = 0; j < this.itemTemplates[i].templates.length; j++) {
              if (this.itemTemplates[i].templates[j].selected == 1) {
                // console.log(this.itemTemplates[i].templates[j])
                this.cart.templateCartDetails.push({
                  itemId: this.itemTemplates[i].templates[j].id,
                  cost: this.itemTemplates[i].templates[j].sp,
                  quantity: 1,
                  itemName: this.itemTemplates[i].templates[j].itemName,
                  costPerItem: this.itemTemplates[i].templates[j].sp,
                  tax: this.itemTemplates[i].templates[j].tax,
                  taxPer: 0
                })
              }
            }
          }
          this.cartList.push(this.cart);
          this.orders.cartDetails.push(this.cart);
          this.modalRef.close()
          // console.log(this.cartList)
        } else {
          let index = this.cartList.findIndex(e => e.itemId == this.addedItem["id"])
          if (index > -1) {
            if (this.cartList[index].templateCartDetails != undefined && this.cartList[index].templateCartDetails.length != 0) {
              let cartString = this.cartList[index].itemId.toString()
              let addedItem = this.addedItem["id"].toString()
              let cartRes = ''
              let itemRes = ''
              for (let i = 0; i < this.cartList[index].templateCartDetails.length; i++) {
                cartRes = cartString.concat(this.cartList[index].templateCartDetails[i].itemId.toString())
              }
              for (let i = 0; i < this.itemTemplates.length; i++) {
                for (let j = 0; j < this.itemTemplates[i].templates.length; j++) {
                  if (this.itemTemplates[i].templates[j].selected == 1) {
                    itemRes = addedItem.concat(this.itemTemplates[i].templates[j].id)
                  }
                }
              }
              if (cartRes == itemRes) {
                // this.cartList[index].quantity += 1
                // if (this.cartList[index].templateCartDetails.length != 0) {
                //   for (let i = 0; i < this.cartList[index].templateCartDetails.length; i++) {
                //     this.cartList[index].templateCartDetails[i].quantity += 1
                //   }
                // }
                let cartIndex = this.orders.cartDetails.findIndex(e => e.itemId == this.addedItem["id"])
                this.orders.cartDetails[cartIndex].quantity += 1
                if (this.orders.cartDetails[cartIndex].templateCartDetails.length != 0) {
                  for (let i = 0; i < this.orders.cartDetails[cartIndex].templateCartDetails.length; i++) {
                    this.orders.cartDetails[cartIndex].templateCartDetails[i].quantity += 1
                  }
                }
              } else {
                this.addedItem["qty"] = 1
                this.cart.itemId = this.addedItem["id"];
                this.cart.cost = 0;
                this.cart.quantity = this.addedItem["qty"];
                this.cart.itemName = this.addedItem["name"];
                this.cart.item = '';
                this.cart.bId = this.id;
                this.cart.costPerItem = this.addedItem["price"];
                this.cart.tax = this.addedItem["tax"];
                this.cart.exciseApplicable = 0;
                this.cart.taxPer = 0;
                this.cart.type = 0;
                this.cart.workerName = '';
                this.cart.discount = 0;
                this.cart.specialInstruction = '';
                for (let i = 0; i < this.itemTemplates.length; i++) {
                  for (let j = 0; j < this.itemTemplates[i].templates.length; j++) {
                    if (this.itemTemplates[i].templates[j].selected == 1) {
                      // console.log(this.itemTemplates[i].templates[j])
                      this.cart.templateCartDetails.push({
                        itemId: this.itemTemplates[i].templates[j].id,
                        cost: this.itemTemplates[i].templates[j].sp,
                        quantity: 1,
                        itemName: this.itemTemplates[i].templates[j].itemName,
                        costPerItem: this.itemTemplates[i].templates[j].sp,
                        tax: this.itemTemplates[i].templates[j].tax,
                        taxPer: 0
                      })
                    }
                  }
                }
                this.cartList.push(this.cart);
                this.orders.cartDetails.push(this.cart);
              }
            }
            else {
              this.addedItem["qty"] = 1
              this.cart.itemId = this.addedItem["id"];
              this.cart.cost = 0;
              this.cart.quantity = this.addedItem["qty"];
              this.cart.itemName = this.addedItem["name"];
              this.cart.item = '';
              this.cart.bId = this.id;
              this.cart.costPerItem = this.addedItem["price"];
              this.cart.tax = this.addedItem["tax"];
              this.cart.exciseApplicable = 0;
              this.cart.taxPer = 0;
              this.cart.type = 0;
              this.cart.workerName = '';
              this.cart.discount = 0;
              this.cart.specialInstruction = '';
              for (let i = 0; i < this.itemTemplates.length; i++) {
                for (let j = 0; j < this.itemTemplates[i].templates.length; j++) {
                  if (this.itemTemplates[i].templates[j].selected == 1) {
                    // console.log(this.itemTemplates[i].templates[j])
                    this.cart.templateCartDetails.push({
                      itemId: this.itemTemplates[i].templates[j].id,
                      cost: this.itemTemplates[i].templates[j].sp,
                      quantity: 1,
                      itemName: this.itemTemplates[i].templates[j].itemName,
                      costPerItem: this.itemTemplates[i].templates[j].sp,
                      tax: this.itemTemplates[i].templates[j].tax,
                      taxPer: 0
                    })
                  }
                }
              }
              this.cartList.push(this.cart);
              this.orders.cartDetails.push(this.cart);
            }
          }
          else {
            this.addedItem["qty"] = 1
            this.cart.itemId = this.addedItem["id"];
            this.cart.cost = 0;
            this.cart.quantity = this.addedItem["qty"];
            this.cart.itemName = this.addedItem["name"];
            this.cart.item = '';
            this.cart.bId = this.id;
            this.cart.costPerItem = this.addedItem["price"];
            this.cart.tax = this.addedItem["tax"];
            this.cart.exciseApplicable = 0;
            this.cart.taxPer = 0;
            this.cart.type = 0;
            this.cart.workerName = '';
            this.cart.discount = 0;
            this.cart.specialInstruction = '';
            for (let i = 0; i < this.itemTemplates.length; i++) {
              for (let j = 0; j < this.itemTemplates[i].templates.length; j++) {
                if (this.itemTemplates[i].templates[j].selected == 1) {
                  // console.log(this.itemTemplates[i].templates[j])
                  this.cart.templateCartDetails.push({
                    itemId: this.itemTemplates[i].templates[j].id,
                    cost: this.itemTemplates[i].templates[j].sp,
                    quantity: 1,
                    itemName: this.itemTemplates[i].templates[j].itemName,
                    costPerItem: this.itemTemplates[i].templates[j].sp,
                    tax: this.itemTemplates[i].templates[j].tax,
                    taxPer: 0
                  })
                }
              }
            }
            this.cartList.push(this.cart);
            this.orders.cartDetails.push(this.cart);
          }
        }
        this.cd.detectChanges()
        this.modalRef.close()
        this.updatePrice()
      }
    }
  }
  selectTemplate(varaint, index) {
    // console.log(varaint)
    this.variantIndex = index
    this.variantTemplates = []
    if (varaint.templates.length != 0) {
      for (let i = 0; i < varaint.templates.length; i++) {
        let index = this.menuTemplates.findIndex(e => e.templateId == varaint.templates[i].templateId)
        if (index >= 0) {
          this.variantTemplates.push({
            templates: this.menuTemplates[index].template,
            minQty: varaint.templates[i].minQty,
            maxQty: varaint.templates[i].maxQty,
            templateName: this.menuTemplates[index].template[0].sectionName
          })
        }
      }
    }
    // console.log(this.variantTemplates)
  }
  saveTemplate(template) {
    // console.log(template)
    if (template.selected == undefined || template.selected == 0) {
      if (this.withVariant != 1) {
        let index = this.itemTemplates.findIndex(e => e.templateName == template.sectionName)
        // console.log(this.itemTemplates[index])
        const selectedCount = this.itemTemplates[index].templates.filter((item) => item.selected).length;
        if (selectedCount >= this.itemTemplates[index].maxQty) {
          const lastSelected = this.itemTemplates[index].templates.find((item) => item.selected);
          if (lastSelected) {
            lastSelected.selected = 0;
            template.selected = 1
          }
          // Swal.fire('','Only')
        }
        else {
          template.selected = 1
        }
      } else if (this.withVariant == 1) {
        let index = this.variantTemplates.findIndex(e => e.templateName == template.sectionName)
        // console.log(this.variantTemplates)
        const selectedCount = this.variantTemplates[index].templates.filter((item) => item.selected).length;
        // console.log(selectedCount)
        if (selectedCount >= this.variantTemplates[index].maxQty) {
          const lastSelected = this.variantTemplates[index].templates.find((item) => item.selected);
          // console.log(lastSelected)
          if (lastSelected) {
            lastSelected.selected = 0;
            template.selected = 1
          }
          // Swal.fire('','Only')
        } else {
          template.selected = 1
        }
        // console.log(this.variantTemplates)
      }

    } else {
      template.selected = 0
    }

  }
  toggleCustomizations(index: number) {
    this.showCustomizations[index] = !this.showCustomizations[index];
    // console.log(this.showCustomizations[index])
  }
  updateItemQty(data, flag, i) {
    // console.log(data.quantity)
    // console.log(this.cartList)
    if (flag == 'inc') {
      // data.quantity += 1
      let index = this.orders.cartDetails.findIndex(e => e.itemId == data.itemId)
      if (data.templateCartDetails.length != 0) {
        for (let i = 0; i < this.orders.cartDetails[index].templateCartDetails.length; i++) {
          this.orders.cartDetails[index].templateCartDetails[i].quantity += 1
        }
      }
      this.orders.cartDetails[index].quantity += 1
      if (this.orders.posDisType != '' && this.orders.posDisType != null && this.orders.posDisType != undefined) {
        this.addPosDiscount()
      } else {
        this.checkDiscount()
      }
    } else {
      // data.quantity -= 1
      let index = this.orders.cartDetails.findIndex(e => e.itemId == data.itemId)
      if (data.templateCartDetails.length != 0) {
        for (let i = 0; i < this.orders.cartDetails[index].templateCartDetails.length; i++) {
          this.orders.cartDetails[index].templateCartDetails[i].quantity -= 1
        }
      }
      this.orders.cartDetails[index].quantity -= 1
      if (this.orders.cartDetails[index].quantity == 0) {
        this.cartList.splice(i, 1)
        this.orders.cartDetails.splice(index, 1)
      }
      if (this.orders.cartDetails.length == 0) {
        this.orders.posDisType = ''
        this.orders.posDisValue = 0
        this.orders.discountedAmount = 0
      }
      if (this.orders.posDisType != '' && this.orders.posDisType != null && this.orders.posDisType != undefined) {
        this.addPosDiscount()
      } else {
        this.checkDiscount()
      }
    }
  }
  checkDiscount() {
    let discount = this.orders.discountedAmount / this.orders.cartDetails.length
    for (let i = 0; i < this.orders.cartDetails.length; i++) {
      this.orders.cartDetails[i].discount = discount
    }
    this.updatePrice()
  }
  addPosDiscount() {
    // console.log(this.orders)
    this.orders.discountedAmount = 0
    // this.orders.discount=0
    if (this.orders.posDisType == 'value') {
      this.orders.discountedAmount = this.orders.posDisValue
      this.checkDiscount()
      // let discount = this.orders.discountedAmount/this.orders.cartDetails.length
      // for(let i=0;i<this.orders.cartDetails.length;i++){
      //   this.orders.cartDetails[i].discount = discount
      // }
      // this.updatePrice()
    } else if (this.orders.posDisType == 'percentage') {
      let subtotal = 0
      for (let i = 0; i < this.orders.cartDetails.length; i++) {
        subtotal += Number(this.orders.cartDetails[i].costPerItem) * Number(this.orders.cartDetails[i].quantity)
      }
      let value = (subtotal * this.orders.posDisValue) / 100
      this.orders.discountedAmount = value
      this.checkDiscount()
      // let discount = this.orders.discountedAmount/this.orders.cartDetails.length
      // for(let i=0;i<this.orders.cartDetails.length;i++){
      //   this.orders.cartDetails[i].discount = discount
      // }
      // this.updatePrice()
    }
    this.modalRef.close()
  }
  opendiscountModalpos(modal) {
    // this.orders.posDisType = ''
    // this.orders.posDisValue = 0
    this.orders.discountedAmount = 0
    this.openModal(modal, 'md')
  }
  changePaymentStatus(i) {
    if (this.paymentList[i].added == 1) {
      this.paymentList[i].added = 0
      this.paymentList[i].amount = ''
    } else {
      this.paymentList[i].added = 1
    }
    // if (i == 4) {
    //   this.addPayment(i)
    //   this.paymentList[i].amount = this.advancePaymentDetails
    // }
    this.cd.detectChanges()
  }
  gstDetail(modal, flag) {
    if (flag == true) {
      this.openModal(modal, 'sm')
    }
    else {
      this.gstNum = ''
      // this.gstMobile=''
      // this.billTo=''
      this.gstFlag = false
      this.cd.detectChanges()
    }
  }
  cancelGst() {
    // this.gstMobile = ''
    this.gstNum = ''
    // this.billTo=''
    this.gstFlag = false
    this.modalRef.close()
  }
  saveGst() {
    Swal.fire('', 'GST details saved!', 'success')
    this.modalRef.close()
  }
  getItemLength() {
    // console.log("here")
    this.totalQty = 0
    for (let i = 0; i < this.orders.cartDetails.length; i++) {
      this.totalQty += this.orders.cartDetails[i].quantity
    }
    this.cd.detectChanges()
  }
  selectCustomerPos(data) {
    let element: HTMLElement = document.getElementById("customerlistname") as HTMLElement;
    element.style.display = 'none';
    this.customerData = []
    this.customerName = data.contactName
    this.customerNumber = data.mobileNo
    this.orders.mobileNo = data.mobileNo
    this.orders.customerName = data.contactName
  }
  selectCustomerNumberPos(data) {
    let element: HTMLElement = document.getElementById("customerlistnumber") as HTMLElement;
    element.style.display = 'none';
    this.customernumberData = []
    this.customerName = data.contactName
    this.customerNumber = data.mobileNo
    this.orders.mobileNo = data.mobileNo
    this.orders.customerName = data.contactName
  }
  closeName() {
    let element: HTMLElement = document.getElementById("customerlistname") as HTMLElement;
    element.style.display = 'none';
  }
  closeNumber() {
    let element: HTMLElement = document.getElementById("customerlistnumber") as HTMLElement;
    element.style.display = 'none';
  }
  applyPoints(check) {
    this.orders.walletPoints = 0
    if (check == true) {
      // console.log(this.orders.subtotal)
      // console.log(this.loyaltyRules)
      if (this.orders.subtotal >= this.loyaltyRules.minimum_order_value) {
        let usePoints = (this.loyaltyRules.discount_percentage * this.orders.subtotal) / 100
        if (usePoints > this.loyaltyRules.max_discount_amount) {
          if (this.loyaltyRules.available_point < usePoints) {
            this.orders.walletPoints = this.loyaltyRules.available_point
          } else {
            this.orders.walletPoints = this.loyaltyRules.max_discount_amount
          }
        } else {
          if (this.loyaltyRules.available_point < usePoints) {
            this.orders.walletPoints = this.loyaltyRules.available_point
          } else {
            this.orders.walletPoints = usePoints
          }
        }
        let disPer = (this.orders.walletPoints / this.orders.subtotal) * 100
        // console.log(disPer)
        for (let i = 0; i < this.orders.cartDetails.length; i++) {
          this.orders.cartDetails[i].points = Number(((Number(this.orders.cartDetails[i]["costPerItem"]) * disPer) / 100).toFixed(2))
        }
        // let points = this.orders.walletPoints / this.orders.cartDetails.length
        // for (let i = 0; i < this.orders.cartDetails.length; i++) {
        //   this.orders.cartDetails[i].points = points
        // }
        this.pointFlag = true
        this.updateSalonCart()
      } else {
        this.pointFlag = false
        this.cd.detectChanges()
        this.toastr.error(`Minimum bill value to use wallet points is ₹${this.loyaltyRules.minimum_order_value}`, 'Error', {
          positionClass: 'toast-bottom-right'
        })
        return
      }
    } else {
      this.orders.walletPoints = 0
      this.pointFlag = false
      for (let i = 0; i < this.orders.cartDetails.length; i++) {
        // console.log(this.orders.cartDetails[i].points)
        this.orders.cartDetails[i].points = 0
      }
      this.updateSalonCart()
    }
  }
  isObjectEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }
  updateServicePrice(index, price, data) {
    this.cartList[index].cost = price
    this.orders.cartDetails[index].cost = price * Number(data.quantity)
    this.orders.cartDetails[index].costPerItem = price
    if (this.pointFlag == true) {
      this.applyPoints(true)
    } else {
      this.applyPoints(false)
    }
  }
  listClick2(newValue, index) {
    this.sectionIndex = index
    this.selectedItem = []
    for (let i = 0; i < newValue.items.length; i++) {
      if (newValue.items[i].variants == undefined) {
        this.selectedItem.push({
          id: newValue.items[i].id,
          name: newValue.items[i].itemName,
          tax: newValue.items[i].tax,
          price: newValue.items[i].sp_without_taxes,
          item: newValue.items[i].itemName + "     -     " + "₹" + newValue.items[i].sp,
          qty: 0,
          type: newValue.items[i].type,
          vegNonvegBoth: newValue.items[i].vegNonvegBoth,
          viewType: newValue.items[i].viewType,
          wizardSteps: newValue.items[i].wizardSteps,
          templateId: newValue.items[i].templateId,
          templates: newValue.items[i].templates,
        })
      } else {
        for (let j = 0; j < newValue.items[i].variants.length; j++) {
          this.selectedItem.push({
            id: newValue.items[i].variants[j].id,
            name: newValue.items[i].variants[j].itemName,
            tax: newValue.items[i].variants[j].tax,
            price: newValue.items[i].variants[j].sp_without_taxes,
            type: newValue.items[i].variants[j].type,
            item: newValue.items[i].variants[j].itemName + "     -     " + "₹" + newValue.items[i].variants[j].sp,
            qty: 0,
            vegNonvegBoth: newValue.items[i].variants[j].vegNonvegBoth,
            viewType: newValue.items[i].variants[j].viewType,
            wizardSteps: newValue.items[i].variants[j].wizardSteps,
            templateId: newValue.items[i].variants[j].templateId,
            templates: newValue.items[i].variants[j].templates,
            variant: newValue.items[i].variants[j].variants,
          })
        }
      }
    }
    if (this.cartList.length != 0) {
      for (let i = 0; i < this.cartList.length; i++) {
        for (let j = 0; j < this.selectedItem.length; j++) {
          if (this.selectedItem[j].id == this.cartList[i].itemId) {
            this.selectedItem[j].qty = this.cartList[i].quantity
          }
        }
      }
    }
    this.cd.detectChanges()
  }
  addService3(itemData, modal) {
    this.selectedItem2 = itemData
    this.selectedItem2 = {
      id: itemData.id,
      name: itemData.name,
      tax: itemData.tax,
      price: itemData.price,
      type: itemData.type,
      item: itemData.item,
      qty: itemData.qty,
      vegNonvegBoth: itemData.vegNonvegBoth,
      viewType: itemData.viewType,
      wizardSteps: itemData.wizardSteps,
      templateId: itemData.templateId,
      templates: itemData.templates
    };
    let index = this.cartList.findIndex(e => e.itemId == itemData.id)
    if (index > -1) {
      this.cartList[index].quantity += 1
      if (this.orders.posDisType != '' && this.orders.posDisType != null && this.orders.posDisType != undefined) {
        this.addPosDiscount()
      } else {
        this.checkDiscount()
      }
    } else {
      this.addStaffModal(modal);
    }
  }
  addStaffModal(modal) {
    this.addStaffShow = false;
    this.openModal2(modal, 'md')
  }
  openModal2(content, modalSize) {
    this.selectedStaff = ''
    this.modalRef = this.modalService.open(content, {
      windowClass: "custom-modal-class",
      backdrop: 'static',
      size: modalSize,
      backdropClass: "light-blue-backdrop",
      centered: true,
      ariaLabelledBy: "modal-basic-title",
    });
  }
  closeAddStaff() {
    this.modalRef.close()
    // console.log(this.selectedItem2)
    this.addService2(this.selectedItem2);
  }
  addStylist2(event) {
    if (event.value != null && event.value != "") {
      this.addStaffShow = true;
    }
    let stylistNamesForDesiredIds
    this.staffdata.forEach(stylist => {
      if (stylist.id == event.value) {
        stylistNamesForDesiredIds = stylist.stylistName;
      }
    });
    this.cartList[this.customIndex2].workerName = stylistNamesForDesiredIds
    this.orders.cartDetails[this.customIndex2].workerName = stylistNamesForDesiredIds
    this.cartList[this.customIndex2].workerId = event.value
    this.orders.cartDetails[this.customIndex2].workerId = event.value
    let orderIndex = this.ordersArray.findIndex(
      (e) => e.orderId == this.orders.orderId
    );
    this.orders.updatedAt = this.getDate();
    if (orderIndex > -1) {
      this.ordersArray[orderIndex] = this.orders;
    } else {
      this.ordersArray.push(this.orders);
    }
    // console.log(this.ordersArray)
    this.storage.set("existingOrders", this.ordersArray);
    this.cd.detectChanges();
    // console.log(this.orders)
  }
  addStylist3(event) {
    // console.log(event)
    let stylistNames = []
    this.staffdata.forEach(stylist => {
      for (let i = 0; i < event.value.length; i++) {
        if (stylist.id == event.value[i]) {
          // console.log(stylist.stylistName)
          // let name = stylist.stylistName.split("")
          stylistNames.push(stylist.stylistName)
        }
      }
    });
    this.customWorkerName = stylistNames.join(",")
    // console.log(this.customWorkerName)
    this.customWorkerId = event.value
    if (this.customWorkerName != null && this.customWorkerName != undefined && this.customWorkerId != null && this.customWorkerId != undefined) {
      this.addStaffShow = true;
    }
  }
  updateItemQty2(data, flag, i) {
    if (flag == 'inc') {
      // data.quantity += 1
      let index = this.cartList.findIndex(e => e.itemId == data.itemId)
      // console.log(this.cartList[index].quantity)
      this.cartList[index].quantity += 1
      this.qtyChange(this.cartList[index], index)
    } else {
      let index = this.cartList.findIndex(e => e.itemId == data.itemId)
      this.cartList[index].quantity -= 1
      this.qtyChange(this.cartList[index], index)
      // if (this.cartList[index].quantity == 0) {
      //   this.cartList.splice(i, 1)
      //   this.orders.cartDetails.splice(index, 1)
      // }
      // if (this.orders.cartDetails.length == 0) {
      //   this.orders.posDisType = ''
      //   this.orders.posDisValue = 0
      //   this.orders.discountedAmount = 0
      // }
      // if (this.orders.posDisType != '' && this.orders.posDisType != null && this.orders.posDisType != undefined) {
      //   this.addPosDiscount()
      // } else {
      //   this.checkDiscount()
      // }
    }
  }
  addService2(itemData) {
    // let length = this.cartList.length;
    // console.log(this.cartList)
    // console.log(itemData)
    let index = this.cartList.findIndex(e => e.itemId == itemData.itemId)
    // console.log(index)
    if (index < 0) {
      this.addNewItem()
      this.cartList.forEach((currentItem, index) => {
        if (currentItem.packingMappingId === 0) {
          this.customIndex2 = index
          this.customPackingMappingId = currentItem.packingMappingId
        }
      });
      // console.log(this.customIndex2)
      // console.log(this.customPackingMappingId)
      if (this.customPackingMappingId == 0) {
        this.orders.subtotal = 0
        this.orders.tax = 0
        this.orders.totalAmt = 0
        this.orders.discount = 0
        this.cartList[this.customIndex2].cost = itemData.price
        this.cartList[this.customIndex2].bId = this.id;
        this.cartList[this.customIndex2].costPerItem = itemData.price
        this.cartList[this.customIndex2].discount = 0
        this.cartList[this.customIndex2].itemId = itemData.itemId
        this.cartList[this.customIndex2].itemName = itemData.name
        this.cartList[this.customIndex2].quantity = 1
        this.cartList[this.customIndex2].tax = itemData.tax
        this.cartList[this.customIndex2].workerName = this.customWorkerName
        this.cartList[this.customIndex2].workerId = this.customWorkerId
        this.cartList[this.customIndex2].item = itemData.item;
        this.cartList[this.customIndex2].type = itemData.type;
        this.cartList[this.customIndex2].packingMappingId = 0;
        this.cartList[this.customIndex2].packageName = ''

        this.orders.cartDetails[this.customIndex2].cost = itemData.price
        this.orders.cartDetails[this.customIndex2].costPerItem = itemData.price
        this.orders.cartDetails[this.customIndex2].discount = 0
        this.orders.cartDetails[this.customIndex2].itemId = itemData.id
        this.orders.cartDetails[this.customIndex2].itemName = itemData.name
        this.orders.cartDetails[this.customIndex2].quantity = 1
        this.orders.cartDetails[this.customIndex2].tax = itemData.tax
        this.orders.cartDetails[this.customIndex2].workerName = this.customWorkerName
        this.orders.cartDetails[this.customIndex2].workerId = this.customWorkerId
        this.orders.cartDetails[this.customIndex2].item = itemData.item;
        this.orders.cartDetails[this.customIndex2].type = itemData.type;
        this.orders.cartDetails[this.customIndex2].packingMappingId = 0
        this.orders.cartDetails[this.customIndex2].packageName = ''
        if (this.pointFlag == true) {
          this.applyPoints(true)
        } else {
          this.applyPoints(false)
        }
        // if (this.paymentType == "membership") {
        //   this.completeExistingOrder('MS', '', true)
        // }
      }
    } else {
      this.cartList[index].workerName = this.customWorkerName
      this.cartList[index].workerId = this.customWorkerId
      this.orders.cartDetails[index].workerName = this.customWorkerName
      this.orders.cartDetails[index].workerId = this.customWorkerId
    }
  }
  staffOpen(modal, index) {
    this.openModal2(modal, 'md')
    this.selectedStaff = this.cartList[index].workerId
    this.selectedItem2 = this.cartList[index]
    this.cd.detectChanges()
    // console.log(this.selectedStaff)
    this.addStaffShow = true
  }
  pendingPayout(modal) {
    this.modalRef = this.modalService.open(modal, {
      windowClass: "custom-modal-class",
      backdrop: 'static',
      size: 'md',
      backdropClass: "light-blue-backdrop",
      centered: true,
      ariaLabelledBy: "modal-basic-title",
    });
  }
  addNotesModel(modal) {
    this.note = ''
    this.modalRef = this.modalService.open(modal, {
      size: 'lg'
    })
  }
  saveNotes() {
    var params = new HttpParams()
      .set("userId", this.storage.get("userId"))
      .set("password", this.storage.get("password"))
      .set("business_id", this.storage.get("selectedBusinessId"))
      .set("parent_business_id", this.parentBusinessId.toString())
      .set("mobile_number", this.orders.mobileNo.toString())
      .set("notes", this.note)
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let url = "https://crm.uengage.in/crm_api/add/customer/notes";
    this.httpClient.post(url, params, httpOptions).subscribe((res: any) => {
      if (res.status) {
        Swal.fire('', res.message, 'success')
        this.modalRef.close()
        this.cd.detectChanges()
      }
    });
  }

  getCustomerAdvancePayments(param) {
    this.httpClient
      .get(this.appComp.base_url + "arc/getAdvanePayment?" + param, {})
      .subscribe((res: any) => {
        if (res.status) {
          this.advancePaymentDetails = res.pending_amount ? res.pending_amount : null
          this.cd.detectChanges()
        } else {
          this.advancePaymentDetails = '0'
        }
      });
  }

  //Advance Payment 

  createAdvancePayForm() {
    this.advancePaymentForm = this.fb.group({
      // reminder_id: [''],
      // item_name: [{ value: '',}],
      customerName: ['', Validators.required],
      mobile_no: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      remarks: [''],
      amountReceived: ['', Validators.required],
      modeArr: ['', Validators.required],
      mode: [''],
    })
  }

  openAdvancePayment(modal, size) {
    this.advancePaymentForm.get('mobile_no').setValue(this.orders.mobileNo);
    // this.advancePaymentForm.get('mobile_no').disable();

    this.advancePaymentForm.get('customerName').setValue(this.orders.customerName);
    // this.advancePaymentForm.get('customerName').disable();

    this.modalRef = this.modalService.open(modal, {
      size: size
    })
  }
  selectOne(event, getArr: string, SetArr: string, key: string, formName: any) {

    const formArr = formName.get(getArr).value;
    const output = formArr.map(item => item[key]).join(',');
    formName.get(SetArr).setValue(output);
  }
  deSelect(event, getArr: string, SetArr: string, key: string, formName: any) {
    const formArr = formName.get(getArr).value;
    const output = formArr.map(item => item[key]).join(',');
    formName.get(SetArr).setValue(output);
  }
  onSelectAll(event, getArr: string, SetArr: string, key: string, formName: any) {
    const formArr = event;
    const output = formArr.map(item => item[key]).join(',');
    formName.get(SetArr).setValue(output);
  }
  onDeSelectAll(event, getArr: string, SetArr: string, formName: any) {
    const formArr = event;
    const output = formArr.map(item => item.id).join(',');
    formName.get(SetArr).setValue(output);
  }
  submitPayment(submitType) {
    const paymentDetails: Transaction = this.advancePaymentForm.value
    if (this.advancePaymentForm.invalid) {
      this.advancePaymentForm.markAllAsTouched();
    } else {
      const setAPIParams = `userId=${this.storage.get("userId")}&password=${this.storage.get("password")}&businessId=${this.storage.get("selectedBusinessId")}`;
      const formParams = `${setAPIParams}&amountReceived=${paymentDetails.amountReceived}&customerName=${paymentDetails.customerName}&mobile_no=${paymentDetails.mobile_no}&mode=${paymentDetails.mode}&remarks=${paymentDetails.remarks}`
      this.advancePaymentService.createPaymentsList(formParams).subscribe((res: any) => {
        if (res.status_code == 200) {
          Swal.fire('', res.message, 'success')
          // this.getPaymentsList()
          let params = setAPIParams +
            "&mobileNo=" + this.orders.mobileNo;
          this.getCustomerAdvancePayments(params)
          this.modalRef.close()
        }

      }, (err) => {
        Swal.fire('', 'Something went worng, Please try again', 'error')
      })

    }
  }
  refreshContact() {
    let element: HTMLElement = document.getElementById("customerlistnumber1") as HTMLElement;
    element.style.display = 'none';
    this.customernumberData = []
    this.customerData = []
  }
  refreshName() {
    let element: HTMLElement = document.getElementById("customerlistname1") as HTMLElement;
    element.style.display = 'none';
    this.customerData = []
    this.customernumberData = []
  }
  selectCustomerBill(data) {
    let element: HTMLElement = document.getElementById("customerlistname1") as HTMLElement;
    element.style.display = 'none';
    this.customerData = []
    this.orders.customerName = data.contactName
    this.orders.mobileNo = data.mobileNo
    this.membershipData = []
    this.packageData = []
    this.packageDetailsArr = []

    let params =
      "userId=" +
      this.storage.get("userId") +
      "&password=" +
      this.storage.get("password") +
      "&businessId=" +
      this.storage.get("selectedBusinessId") +
      "&mobileNo=" + this.orders.mobileNo;
    this.getCustomerAdvancePayments(params)
    this.httpClient
      .get(this.appComp.base_url + "pos/getBillingDetails?" + params, {})
      .subscribe((res: any) => {
        if (res.status == 1) {
          this.membershipData = res.membership
          if (this.membershipData.length == 0) {
            this.showMem = 0
          } else {
            if (this.membershipData[0].membershipAmount == 0) {
              this.showMem = 0
            } else {
              this.showMem = 1
            }
          }
          this.packageData = res.package
          this.plength = this.packageData.length
          // console.log(this.packageData)
          // for(let i=0;i<this.paymentOption.length;i++){
          //   if(this.paymentOption[i].name=='Membership'){
          //     this.paymentOption[i].memAmount = this.membershipData[0].membershipAmount
          //   }
          // }
          for (let i = 0; i < this.packageData.length; i++) {
            // console.log("hey")
            if (this.packageDetailsArr.length == 0) {
              this.packageDetailsArr.push({
                pId: this.packageData[i].packageid,
                pName: this.packageData[i].packageName,
                items: []
              });
              this.packageDetailsArr[0]['items'].push({
                itemName: this.packageData[i].itemName,
                itemId: this.packageData[i].itemId,
                // packageId: this.packageData[i].packageid,
                packageName: this.packageData[i].packageName,
                packingMappingId: this.packageData[i].packingMappingId,
                redeemed: 0
              })
            } else {
              let index = this.packageDetailsArr.findIndex(e => e.pName == this.packageData[i].packageName);
              if (index > -1) {
                this.packageDetailsArr[index]['items'].push({
                  itemName: this.packageData[i].itemName,
                  itemId: this.packageData[i].itemId,
                  // packageId: this.packageData[i].packageid,
                  packageName: this.packageData[i].packageName,
                  packingMappingId: this.packageData[i].packingMappingId,
                  redeemed: 0
                })
              } else {
                this.packageDetailsArr.push({
                  pId: this.packageData[i].packageid,
                  pName: this.packageData[i].packageName,
                  items: []
                });
                this.packageDetailsArr[this.packageDetailsArr.length - 1]['items'].push({
                  itemName: this.packageData[i].itemName,
                  itemId: this.packageData[i].itemId,
                  // packageId: this.packageData[i].packageid,
                  packageName: this.packageData[i].packageName,
                  packingMappingId: this.packageData[i].packingMappingId,
                  redeemed: 0
                })
              }
            }

            // this.packageDetailsArr.push({
            //   itemName: this.packageData[i].itemName,
            //   itemId: this.packageData[i].itemId,
            //   // packageId: this.packageData[i].packageid,
            //   packageName: this.packageData[i].packageName,
            //   packingMappingId: this.packageData[i].packingMappingId,
            //   redeemed: 0
            // })
          }
          // console.log(this.packageDetailsArr)
          // console.log(this.cartList)
          for (let i = 0; i < this.cartList.length; i++) {
            for (let j = 0; j < this.packageDetailsArr.length; j++) {
              for (let k = 0; k < this.packageDetailsArr[j].items.length; k++) {
                if (this.cartList[i].packingMappingId == this.packageDetailsArr[j].items[k].packingMappingId) {
                  this.packageDetailsArr[j].items[k].redeemed = 1;
                }
              }
            }
          }
          // console.log(this.packageDetailsArr)
          this.cd.detectChanges()
        } else {
          Swal.fire('', res.msg, 'error')
        }
      });
    var formData = new FormData();
    formData.append("userId", this.storage.get("userId"));
    formData.append("businessId", this.storage.get("selectedBusinessId"));
    formData.append("password", this.storage.get("password"));
    formData.append("parentBusinessId", this.parentBusinessId.toString());
    formData.append("mobile_no", this.orders.mobileNo.toString());
    this.httpClient.post('https://api.uengage.in/membershipCards/customerMembershipCardListing', formData)
      .subscribe((res: any) => {
        if (res.status) {
          if (res.data.length != 0) {
            this.loyaltyCard = res.data
          }
        } else {
          // Swal.fire('', res.message, 'error')
        }
      });
    if (this.orders.mobileNo != null && this.orders.mobileNo != undefined && this.orders.mobileNo != '') {
      let param =
        "userId=" +
        this.storage.get("userId") +
        "&password=" +
        this.storage.get("password") +
        "&businessId=" +
        this.storage.get("selectedBusinessId") +
        "&customerMobileNo=" + this.orders.mobileNo;
      this.httpClient
        .get(this.appComp.base_url + "addoapi/getPendingPayments?" + param, {})
        .subscribe((res: any) => {
          if (res.status == 1) {
            if (res.list.length != 0) {
              this.pendingPayoutAmount = res.list[0].pending_amount
              if (this.pendingPayoutAmount != 0) {
                let element: HTMLElement = document.getElementById("pending_payout") as HTMLElement;
                element.click()
              }
              this.cd.detectChanges()
            } else {
              this.pendingPayoutAmount = 0
            }
          } else {
            Swal.fire('', res.msg, 'error')
          }
        });
      this.getCustomerId()
      this.getLoyalty()
    }
    // this.getOffers()
    if (this.orders.walletPoints != 0 && this.orders.walletPoints != undefined && this.orders.walletPoints != null) {
      this.pointFlag = true
    }
  }
  searchCustomerByNameBill(name) {
    if (name.length == 0) {
      let element: HTMLElement = document.getElementById("customerlistname1") as HTMLElement;
      element.style.display = 'none';
    }
    if (name.length >= 3) {
      let element: HTMLElement = document.getElementById("customerlistname1") as HTMLElement;
      element.style.display = 'block';
      this.customerData = []
      let params =
        "userId=" +
        this.storage.get("userId") +
        "&password=" +
        this.storage.get("password") +
        "&businessId=" +
        this.storage.get("selectedBusinessId") +
        "&searchString=" + name;
      this.httpClient.get("https://api.uengage.in/pos/searchUser?" + params, {}).subscribe((res: any) => {
        if (res.status == 1) {
          this.customerData = res.user
          this.cd.detectChanges()
        }
      });
    }
  }
  searchCustomerByNumberBill(number) {
    if (number.length == 0) {
      let element: HTMLElement = document.getElementById("customerlistnumber1") as HTMLElement;
      element.style.display = 'none';
      // let element1: HTMLElement = document.getElementById("customerlistname") as HTMLElement;
      // element1.style.display='none';
    }
    // if(number.length<3){
    //   this.detailForm.controls.name.setValue('')
    // }
    if (number.length >= 3) {
      let element: HTMLElement = document.getElementById("customerlistnumber1") as HTMLElement;
      element.style.display = 'block';
      this.customernumberData = []
      let params =
        "userId=" +
        this.storage.get("userId") +
        "&password=" +
        this.storage.get("password") +
        "&businessId=" +
        this.storage.get("selectedBusinessId") +
        "&searchString=" + number;
      this.httpClient.get("https://api.uengage.in/pos/searchUser?" + params, {}).subscribe((res: any) => {
        if (res.status == 1) {
          this.customernumberData = res.user
          this.cd.detectChanges()
        }
      });
    }
  }
  selectCustomerNumberBill(data) {
    let element: HTMLElement = document.getElementById("customerlistnumber1") as HTMLElement;
    element.style.display = 'none';
    this.customernumberData = []
    this.orders.customerName = data.contactName
    this.orders.mobileNo = data.mobileNo
    this.membershipData = []
    this.packageData = []
    this.packageDetailsArr = []

    let params =
      "userId=" +
      this.storage.get("userId") +
      "&password=" +
      this.storage.get("password") +
      "&businessId=" +
      this.storage.get("selectedBusinessId") +
      "&mobileNo=" + this.orders.mobileNo;
    this.getCustomerAdvancePayments(params)
    this.httpClient
      .get(this.appComp.base_url + "pos/getBillingDetails?" + params, {})
      .subscribe((res: any) => {
        if (res.status == 1) {
          this.membershipData = res.membership
          if (this.membershipData.length == 0) {
            this.showMem = 0
          } else {
            if (this.membershipData[0].membershipAmount == 0) {
              this.showMem = 0
            } else {
              this.showMem = 1
            }
          }
          this.packageData = res.package
          this.plength = this.packageData.length
          // console.log(this.packageData)
          // for(let i=0;i<this.paymentOption.length;i++){
          //   if(this.paymentOption[i].name=='Membership'){
          //     this.paymentOption[i].memAmount = this.membershipData[0].membershipAmount
          //   }
          // }
          for (let i = 0; i < this.packageData.length; i++) {
            // console.log("hey")
            if (this.packageDetailsArr.length == 0) {
              this.packageDetailsArr.push({
                pId: this.packageData[i].packageid,
                pName: this.packageData[i].packageName,
                items: []
              });
              this.packageDetailsArr[0]['items'].push({
                itemName: this.packageData[i].itemName,
                itemId: this.packageData[i].itemId,
                // packageId: this.packageData[i].packageid,
                packageName: this.packageData[i].packageName,
                packingMappingId: this.packageData[i].packingMappingId,
                redeemed: 0
              })
            } else {
              let index = this.packageDetailsArr.findIndex(e => e.pName == this.packageData[i].packageName);
              if (index > -1) {
                this.packageDetailsArr[index]['items'].push({
                  itemName: this.packageData[i].itemName,
                  itemId: this.packageData[i].itemId,
                  // packageId: this.packageData[i].packageid,
                  packageName: this.packageData[i].packageName,
                  packingMappingId: this.packageData[i].packingMappingId,
                  redeemed: 0
                })
              } else {
                this.packageDetailsArr.push({
                  pId: this.packageData[i].packageid,
                  pName: this.packageData[i].packageName,
                  items: []
                });
                this.packageDetailsArr[this.packageDetailsArr.length - 1]['items'].push({
                  itemName: this.packageData[i].itemName,
                  itemId: this.packageData[i].itemId,
                  // packageId: this.packageData[i].packageid,
                  packageName: this.packageData[i].packageName,
                  packingMappingId: this.packageData[i].packingMappingId,
                  redeemed: 0
                })
              }
            }

            // this.packageDetailsArr.push({
            //   itemName: this.packageData[i].itemName,
            //   itemId: this.packageData[i].itemId,
            //   // packageId: this.packageData[i].packageid,
            //   packageName: this.packageData[i].packageName,
            //   packingMappingId: this.packageData[i].packingMappingId,
            //   redeemed: 0
            // })
          }
          // console.log(this.packageDetailsArr)
          // console.log(this.cartList)
          for (let i = 0; i < this.cartList.length; i++) {
            for (let j = 0; j < this.packageDetailsArr.length; j++) {
              for (let k = 0; k < this.packageDetailsArr[j].items.length; k++) {
                if (this.cartList[i].packingMappingId == this.packageDetailsArr[j].items[k].packingMappingId) {
                  this.packageDetailsArr[j].items[k].redeemed = 1;
                }
              }
            }
          }
          // console.log(this.packageDetailsArr)
          this.cd.detectChanges()
        } else {
          Swal.fire('', res.msg, 'error')
        }
      });
    var formData = new FormData();
    formData.append("userId", this.storage.get("userId"));
    formData.append("businessId", this.storage.get("selectedBusinessId"));
    formData.append("password", this.storage.get("password"));
    formData.append("parentBusinessId", this.parentBusinessId.toString());
    formData.append("mobile_no", this.orders.mobileNo.toString());
    this.httpClient.post('https://api.uengage.in/membershipCards/customerMembershipCardListing', formData)
      .subscribe((res: any) => {
        if (res.status) {
          if (res.data.length != 0) {
            this.loyaltyCard = res.data
          }
        } else {
          // Swal.fire('', res.message, 'error')
        }
      });
    if (this.orders.mobileNo != null && this.orders.mobileNo != undefined && this.orders.mobileNo != '') {
      let param =
        "userId=" +
        this.storage.get("userId") +
        "&password=" +
        this.storage.get("password") +
        "&businessId=" +
        this.storage.get("selectedBusinessId") +
        "&customerMobileNo=" + this.orders.mobileNo;
      this.httpClient
        .get(this.appComp.base_url + "addoapi/getPendingPayments?" + param, {})
        .subscribe((res: any) => {
          if (res.status == 1) {
            if (res.list.length != 0) {
              this.pendingPayoutAmount = res.list[0].pending_amount
              if (this.pendingPayoutAmount != 0) {
                let element: HTMLElement = document.getElementById("pending_payout") as HTMLElement;
                element.click()
              }
              this.cd.detectChanges()
            } else {
              this.pendingPayoutAmount = 0
            }
          } else {
            Swal.fire('', res.msg, 'error')
          }
        });
      this.getCustomerId()
      this.getLoyalty()
    }
    // this.getOffers()
    if (this.orders.walletPoints != 0 && this.orders.walletPoints != undefined && this.orders.walletPoints != null) {
      this.pointFlag = true
    }
  }
  getStaff(itemId) {
    for (let i = 0; i < this.finalArr.length; i++) {
      for (let j = 0; j < this.finalArr[i].items.length; j++) {
        let item = this.finalArr[i].items[j]
        if (item.itemId == itemId) {
          let workers = item.stylistName.split(',')
          if (workers.length == 1) {
            let index = this.staffdata.findIndex(e => e.id == workers)
            return this.staffdata[index].stylistName
          } else {
            let name = ''
            for (let k = 0; k < workers.length; k++) {
              let staffindex = this.staffdata.findIndex(e => e.id == workers[k])
              name += this.staffdata[staffindex].stylistName + ","
              // this.jobCard[i].staff = this.staffdata[staffindex].stylistName
            }
            return name
          }
        }
      }
    }
  }


  formulaCalculationWithDisc(BasePrice: number, Tax: number, Discount: number, itemDisc: string, DiscountType: number, isGSTInclude: boolean) {

    let ActualBasePrice = BasePrice
    let ActualTax = Tax
    let ActualDiscount = +Discount || 0
    let TaxPrice = 0
    // if (isGSTInclude == false) {
    //   ActualBasePrice = ActualBasePrice * (1 * (1 + ActualTax / 100))
    // }
    // if (DiscountType == 1 ) {
    //   ActualDiscount = (ActualBasePrice * ActualDiscount) / 100

    // }
    ActualBasePrice = ActualBasePrice - ActualDiscount

    if (isGSTInclude) {
      const GST = ActualTax
      ActualBasePrice = ActualBasePrice * (1 / (1 + GST / 100))
    }


    TaxPrice = ActualBasePrice * ActualTax / 100
    // const finalPrice = this.calculateFinalPrice(BasePrice, ActualDiscount, ActualTax)
    const finalPrice = ActualBasePrice + TaxPrice
    return {
      basePrice: Number(BasePrice.toFixed(2)),
      netAmt: Number(ActualBasePrice.toFixed(2)),
      finalBasePrice: Number(finalPrice.toFixed(2)),
      finalDiscountPrice: Number(ActualDiscount.toFixed(2)),
      finalTaxPrice: Number(TaxPrice.toFixed(2)),
      taxPercentage: ActualTax
    };

  }

  // calculateFinalPrice(BP: number, DiscAmt: number, Tax: number) {
  //   let netPrice = BP - DiscAmt * (1 / (1 + Tax / 100));
  //   let finalPrice = netPrice + netPrice * (Tax / 100);
  //   return finalPrice;
  // }


  afterCalculationData(isGST: any) {
    this.orders.netAmt = 0
    for (let i = 0; i < this.cartList.length; i++) {
      let discountPercentage = 0
      // if (this.orders.discountType) {
      //   discountPercentage = this.orders.discountType == 1 ? this.orders.discount : this.orders.cartDetails[i].discount
      // } else {
      discountPercentage = this.orders.cartDetails[i].discount ? this.orders.cartDetails[i].discount : 0
      // }
      let baseVAlue = Number(this.cartList[i].costPerItem) * Number(this.cartList[i].quantity)
      let isGSTValid = this.orders.cartDetails[i].isGSTInclusive ? this.orders.cartDetails[i].isGSTInclusive : isGST

      if (isGST == false && isGST !== null) {
        isGSTValid = false
      }
      if (this.memUsed) {
        isGSTValid = false
      }
      const validTax = this.taxFlag ? this.orders.cartDetails[i].tax : 0
      const calculationDetails = this.formulaCalculationWithDisc(baseVAlue, validTax, discountPercentage, this.cartList[i].disType, this.orders.discountType, isGSTValid)
      if (i > 0) {

        this.orders.cartDetails[i].costPerItem = Number((calculationDetails.basePrice / this.cartList[i].quantity).toFixed(2))
        this.orders.totalAmt += calculationDetails.finalBasePrice
        this.orders.tax += calculationDetails.finalTaxPrice
        this.orders.roundedTotalAmt += calculationDetails.finalBasePrice
        this.orders.subtotal += calculationDetails.basePrice
        this.orders.cartDetails[i].cost = calculationDetails.basePrice - this.orders.cartDetails[i].discount;
        this.orders.cartDetails[i].isGSTInclusive = this.isIncluseTax
        this.orders.isGstInclusive = this.isIncluseTax
        this.orders.cartDetails[i].discount = Number(this.orders.cartDetails[i].discount.toFixed(2))
        this.orders.netAmt += calculationDetails.netAmt

        this.cartList[i].costPerItem = Number((calculationDetails.basePrice / this.cartList[i].quantity).toFixed(2))
        this.cartList[i].cost = calculationDetails.basePrice - this.orders.cartDetails[i].discount
        this.cartList[i].isGSTInclusive = this.isIncluseTax
        this.cartList[i].discount = Number(this.orders.cartDetails[i].discount.toFixed(2))



      } else {
        this.orders.cartDetails[i].costPerItem = Number((calculationDetails.basePrice / this.cartList[i].quantity).toFixed(2))
        this.orders.totalAmt = calculationDetails.finalBasePrice
        this.orders.tax = calculationDetails.finalTaxPrice
        this.orders.roundedTotalAmt = calculationDetails.finalBasePrice
        this.orders.subtotal = calculationDetails.basePrice
        this.orders.cartDetails[i].cost = calculationDetails.basePrice - this.orders.cartDetails[i].discount;
        this.orders.cartDetails[i].isGSTInclusive = this.isIncluseTax
        this.orders.isGstInclusive = this.isIncluseTax
        this.orders.cartDetails[i].discount = Number(this.orders.cartDetails[i].discount.toFixed(2))
        this.orders.netAmt += calculationDetails.netAmt

        this.cartList[i].costPerItem = Number((calculationDetails.basePrice / this.cartList[i].quantity).toFixed(2))
        this.cartList[i].cost = calculationDetails.basePrice - this.orders.cartDetails[i].discount
        this.cartList[i].isGSTInclusive = this.isIncluseTax
        this.cartList[i].discount = Number(this.orders.cartDetails[i].discount.toFixed(2))
      }

    }

    const totalValues = this.pointFlag ? Number(this.orders.totalAmt) - Number(this.orders.walletPoints) : this.orders.totalAmt
    this.orders.totalAmt = this.memUsed ? 0 : totalValues
    this.orders.roundedTotalAmt = this.memUsed ? 0 : this.pointFlag ? Math.round(Number(this.orders.roundedTotalAmt) - Number(this.orders.walletPoints)) : Math.round(this.orders.roundedTotalAmt)
    this.orders.roundDiff = Number((this.orders.totalAmt - this.orders.roundedTotalAmt).toFixed(2))
    if (this.memUsed) {
      this.membershipUsed = totalValues
      this.walletLeft = this.membershipData[0].membershipAmount - this.membershipUsed
    }

    let orderIndex = this.ordersArray.findIndex(
      (e) => e.orderId == this.orders.orderId
    );
    this.orders.updatedAt = this.getDate();
    if (orderIndex > -1) {
      this.ordersArray[orderIndex] = this.orders;
    } else {
      this.ordersArray.push(this.orders);
    }
    this.storage.set("existingOrders", this.ordersArray);
    this.memUsed = false
    this.cd.detectChanges();
  }

  settleMemAmount() {
    if (this.settleAmount > this.pendingPayoutAmount) {
      Swal.fire('', 'Settled Amount Cannot Be Greater Then The Pending Amount!', 'error')
      return false;
    }
    else if (this.settleAmount == '' || this.settleVia == '') {
      Swal.fire('', 'Please provide all details!', 'error')
      return false;
    }
    else {
      let params =
        "userId=" +
        this.storage.get("userId") +
        "&password=" +
        this.storage.get("password") +
        "&businessId=" +
        this.storage.get("selectedBusinessId") +
        "&partial_payment_id=" + this.payoutId +
        "&amountPaid=" + this.settleAmount +
        "&paidvia=" + this.settleVia;
      this.httpClient
        .get("https://api.uengage.in/addoapi/payPartialPayment?" + params, {})
        .subscribe((res: any) => {
          if (res.status == 1) {
            this.getPendingPayouts()
            Swal.fire('', res.msg, 'success')
            this.modalRef.close()
          } else {
            Swal.fire('', res.msg, 'error')
            this.modalRef.close()
          }
        });
    }
  }

  getPendingPayouts() {
    let param =
      "userId=" +
      this.storage.get("userId") +
      "&password=" +
      this.storage.get("password") +
      "&businessId=" +
      this.storage.get("selectedBusinessId") +
      "&customerMobileNo=" + this.orders.mobileNo;
    this.httpClient
      .get("https://api.uengage.in/addoapi/getPendingPayments?" + param, {})
      .subscribe((res: any) => {
        if (res.status == 1) {
          console.log(res);
          this.pendingPayoutAmount = res.list[0].pending_amount
          this.cd.detectChanges()
        }
      })
  }

}
