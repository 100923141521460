<nav class="navbar navbar-expand-lg navbar-dark bg-white" style="height: 6em;">
  <div class="row px-2" style="flex: 1;">
    <div class="col-6 mt-1 px-2 px-sm-3">
      <a routerLink="/">
        <img alt="" src="../../../../../assets/media/logos/new_uengage.png" style="width: 14em;" />
      </a>
    </div>
    <div class="col-6 px-2 px-sm-3" style="text-align: right !important;">
      <div ngbDropdown class="d-inline-block dropdown mt-3 ml-2" style="cursor: pointer;">
        <div class="border btn btn-icon align-items-center btn-lg px-3 w-md-auto mobWidth bg-white"
          style="border-radius: 20px;" id="kt_quick_user_toggle" id="dropdownBasic1" ngbDropdownToggle>
          <i class="far fa-user text-dark"></i>
          <span class="text-dark opacity-70 font-weight-bold font-size-base  d-md-inline mx-3">Hey&nbsp;User</span>
          <span>
            <i class="fas fa-chevron-down text-dark" style="font-size: 15px;"></i>
          </span>
        </div>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="
            background-color: #fff;
            box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
            width: 26em;
          ">
          <div class="m-dropdown__inner">
            <div class="m-dropdown__header m--align-center" #mProfileDropdown style="margin-bottom:10px">
              <div class="m-card-user m-card-user--skin-dark">
                <div class="m-card-user__details text-center">
                  <span class="m-card-user__name m--font-weight-500 ml-2"
                    style="color: black; font-size: 18px;word-break: break-word;">
                    {{ userName }}
                  </span>

                </div>
              </div>
            </div>
            <div class="m-dropdown__body mt-2">
              <div class="m-dropdown__content">
                <div class="row w-100 m-0">
                  <div class="col-12">
                    <a (click)="logout()" class="btn btn-light-danger btn-sm mt-3 text-center w-100">
                      <i class="fas fa-power-off"></i>&nbsp;Logout
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<div class="col-sm-10 col-xl-5 col-lg-7 col-md-9 col-12 portal" style="margin-top: 2em; margin-left: auto; margin-right: auto;">
  <kt-portlet>
    <kt-portlet-header>
      <ng-container ktPortletTitle>
        <div class="m-portlet__head-title row mt-3 w-100 align-items-center">
          <div class="col-8 text-left mt-1">
            <h3 class="m-portlet__head-text font-weight-bolder m-0">Select Your Business</h3>
          </div>
          <div class="col-4 text-right">
            <button (click)="refresh()" class="btn btn-icon p-0 border rounded-circle"
              style="border-color: #202020 !important;">
              <i class="fas fa-sync-alt text-dark-75"></i>
            </button>
          </div>
        </div>
      </ng-container>
    </kt-portlet-header>
    <kt-portlet-body>
      <ng-container mPortletBody>
        <div class="row w-100 m-0">
          <div class="form-group w-100 mb-0">
            <div class="input-icon">
              <input type="text" class="form-control border" placeholder="Search By Name" [(ngModel)]="searchText"
                style="border-radius: 20px;" />
              <span><i class="flaticon2-search-1 icon-md"></i></span>
            </div>
          </div>
        </div>
        <div *ngFor="let details of businessAvailable | localityCityFilter: searchText"
          class="m-widget3__header row py-3 pb-3 pt-8 pt-sm-3 w-100 m-0 mt-4 border rounded-lg align-items-center"
          style="position: relative;" (click)="selectBusinessId(details.businessId,details.currency)">
          <!-- <div class="col-1 px-2 d-sm-none d-flex align-items-center justify-content-center">
            <div class="radio-list">
              <label class="radio justify-content-center radio-outline radio-info"
                  style="display: inline-flex;">
                  <input type="radio" name="select"
                  [checked]="details.businessId==selectedId"
                      style="display: none;" />
                  <span class="m-0"></span>
              </label>
          </div>          
        </div> -->
          <div class="col-lg-2 col-sm-2 col-3 px-2 " *ngIf="details.logo != null">
            <div class="w-100 border rounded-lg bg-light p-2 d-flex align-items-center justify-content-center">
              <img class="w-100 img-fluid" style="border-radius: 10px;" src="https://api.uengage.in/images/addo/logos/{{
              details.logo
            }}" alt="" />
            </div>
          </div>
          <div class="col-lg-2 col-sm-3 col-3 text-left" *ngIf="details.logo == null" style="place-items: center;
          display: flex;">
            <div class="squares">
              <div class="icon1 rounded-lg align-items-center justify-content-center">
                <p class="mb-0" style="font-size: 3em;color: #a3a3a3;">{{getChar(details.name) | uppercase}}</p>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-6 col-8 px-2 mobile-text">
            <div class="w-100 d-flex align-items-center justify-content-start">
              <img class="img-fluid mr-2" src="../../../../../assets/media/business-selector/icon_business-outline.svg"
                alt="">
              <h6 class="font-weight-bolder mb-0" style="line-height: 1.3;">
                {{ details.name }}
              </h6>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-start">
              <img class="img-fluid mr-2" src="../../../../../assets/media/business-selector/location.svg" alt="">
              <p class="my-1 font-weight-light" style="line-height: 1.3;">
                {{ details.locality }}, {{ details.city }}
              </p>
            </div>
            <div *ngIf="details.parentId==0" class="w-100 d-flex align-items-center justify-content-start">
              <img class="img-fluid mr-2" src="../../../../../assets/media/business-selector/details.svg" alt="">
              <p class="mb-0 text-dark-75 font-weight-light" style="line-height: 1.3;">For
                managing admin level
                functionalities</p>
            </div>
          </div>
          <div class="m-widget3__status m--font-info col-lg-3 col-md-3 col-4 text-right d-none d-sm-block"
            style="margin-top: 1.4em;">
            <button class="btn btn-sm btn-base-primary w-100" type="button" *ngIf="details.businessId!=selectedId">
              Select
            </button>
            <button type="button" class="btn btn-sm spinner spinner-dark spinner-right btn-base-primary w-100"
              *ngIf="details.businessId==selectedId">
              Select
            </button>
          </div>
          <div class="top-right-div pb-1 px-3 px-sm-4 pt-0 pt-sm-1"
            [ngStyle]="{ 'background-color': details.parentId == 0 ? '#F8D99F' : '#D2F0A2' }">
            <p *ngIf="details.parentId == 0" class="mb-0 font-weight-bold" style="white-space: nowrap;">
              Corporate Business
            </p>
            <p *ngIf="details.parentId != 0" class="mb-0 font-weight-bold" style="white-space: nowrap;">
              Child Business
            </p>
          </div>
        </div>
      </ng-container>
    </kt-portlet-body>
  </kt-portlet>
</div>
<!-- <kt-footer></kt-footer> -->
<div class="footer bg-white p-4 d-flex flex-lg-column" style="margin-top: 55px;" id="kt_footer"
  [ngClass]="footerClasses">
  <div [ngClass]="footerContainerClasses"
    class="d-flex flex-column flex-md-row align-items-center justify-content-between mobFooter container-fluid">
    <!--begin::Copyright-->
    <div class="text-dark order-2 order-md-1">
      <span style="color: #717579;font-size: 0.9em;">uEngage services Pvt Ltd</span><br>
      <span style="color: #717579;font-size: 0.8em;">Made with ❤️ by uEngage</span>
    </div>
    <!--end::Copyright-->

    <!--begin::Nav-->
    <div class="nav nav-dark order-1 order-md-2">
      <a href="https://api.uengage.in/" target="_blank" class="nav-link pr-3 pl-0">Privacy</a>

      <a href="https://api.uengage.in/contact-uengage" target="_blank" class="nav-link pl-3 pr-0">Contact</a>
    </div>
  </div>
</div>