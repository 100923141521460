// Angular
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  Inject,
  ViewChild,
  ElementRef, Renderer2
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Meta, Title } from '@angular/platform-browser';
// RxJS
import { Observable, Subject } from "rxjs";
import { finalize, takeUntil, tap } from "rxjs/operators";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Store
import { Store } from "@ngrx/store";
import { AppState } from "../../../../core/reducers";
// Auth
import { AuthNoticeService, AuthService, Login } from "../../../../core/auth";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from 'ngx-toastr';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from "angularx-social-login";
import { CookieService } from "ngx-cookie-service";
import { OwlOptions } from "ngx-owl-carousel-o";
import { trigger, state, style, transition, animate } from "@angular/animations";
/**
 * ! Just example => Should be removed in development
 */
const DEMO_PARAMS = {
  EMAIL: "",
  PASSWORD: "",
};

@Component({
  selector: "kt-login",
  templateUrl: "./login.component.html",
  styleUrls: ["login.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('span1') span1: ElementRef;
  @ViewChild('span2') span2: ElementRef;
  @ViewChild('span3') span3: ElementRef;
  // Public params
  loginForm: FormGroup;
  loading = false;
  isLoggedIn$: Observable<boolean>;
  errors: any = [];
  images: Array<string>;
  private unsubscribe: Subject<any>;
  submitted = false
  private returnUrl: any;
  deviceInfo: any;
  showWarning: number;
  modal = 'warningModal'
  warningShow: number;
  emailId = ''
  otpValidate: number;
  otp = ''
  fieldTextType: boolean = false;
  cookieValue: string;
  firstTime: number;
  emailLogin: number;
  mobileLogin = 0;
  passwordForm: FormGroup
  submitted1 = false
  otpSent: number;
  invalid: number;
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    center: false,
    dots: false,
    autoplayTimeout: 2000,
    autoHeight: false,
    autoWidth: false,
    responsive: {
      0: {
        items: 6,
      },
      600: {
        items: 6,
      },
      1000: {
        items: 6,
      }
    }
  }
  placeholder: string;
  loginValue: any;
  isOTPSend: boolean = false;
  // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  /**
   * Component constructor
   *
   * @param router: Router
   * @param auth: AuthService
   * @param authNoticeService: AuthNoticeService
   * @param translate: TranslateService
   * @param store: Store<AppState>
   * @param fb: FormBuilder
   * @param cdr
   * @param route
   */
  constructor(
    private router: Router, private renderer: Renderer2,
    private auth: AuthService,
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private store: Store<AppState>, private toastr: ToastrService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute, private authService: SocialAuthService,
    public httpClient: HttpClient,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private deviceService: DeviceDetectorService, private cookieService: CookieService,
    private modalRef: NgbActiveModal, private modalService: NgbModal, public meta: Meta, public title: Title,
  ) {
    this.unsubscribe = new Subject();
    // (this.cookieService.get('user_visit'))
    if (this.cookieService.get('user_visit') == undefined || this.cookieService.get('user_visit') == '' || this.cookieService.get('user_visit') == null) {
      this.cookieService.set('user_visit', 'First Time', { expires: 30, sameSite: 'Lax' });
      this.firstTime = 1
    } else {
      this.firstTime = 0
      // ("not first time user")
    }
    // this.cookieValue = this.cookieService.get('Test');
    // (this.cookieValue)
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */


  ngAfterViewInit() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent
    )) {

    } else {
      // this.animateSpans();
    }
  }
  animateSpans() {
    const spans = [this.span1.nativeElement, this.span2.nativeElement, this.span3.nativeElement];
    let currentIndex = 0;

    setInterval(() => {
      // Remove animation classes from all spans
      spans.forEach((span, index) => {
        this.renderer.removeClass(span, 'highlight-active');
        this.renderer.addClass(span, 'highlight-inactive');
      });

      // Apply animation to the current span
      this.renderer.removeClass(spans[currentIndex], 'highlight-inactive');
      this.renderer.addClass(spans[currentIndex], 'highlight-active');

      // Increment the index or reset to 0 if it reaches the end
      currentIndex = (currentIndex + 1) % spans.length;
    }, 1000); // Adjust the interval as needed (e.g., 1000ms = 1 second)
  }
  ngOnInit(): void {
    this.authService.authState.subscribe((user) => {
      // (user)
      // this.user = user;
      // this.loggedIn = (user != null);
    });
    // this.animateSpans();
    this.title.setTitle('Digitizing Consumer Businesses');
    this.meta.updateTag({ name: 'description', content: 'Login to Your Uengage Account and Manage Your Business Digitally' });
    this.meta.updateTag({ property: 'og:image', content: 'https://www.uengage.io/assets/uen/img/uengage-mascot-banner.png' });
    this.meta.updateTag({ property: 'og:title', content: 'Digitizing Consumer Businesses' });
    this.meta.updateTag({ property: 'og:description', content: 'Login to Your Uengage Account and Manage Your Business Digitally' });
    this.meta.addTag({ property: 'og:type', content: 'image:png' });
    // this.initLoginForm();
    this.createAccForm()
    // redirect back to the returnUrl before login
    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params.returnUrl || "/";
    });
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (this.deviceInfo.os_version == 'windows-7' && this.deviceInfo.browser == 'chrome') {
      this.warningShow = 1
    } else {
      this.warningShow = 0
    }
  }
  createAccForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(/^[\w.-]+@[\w.-]+\.\w+$|^\d{10}$/)]],
    })
    this.passwordForm = this.fb.group({
      password: ['', [Validators.required]],
    })
  }
  get a() { return this.loginForm.controls; }
  get b() { return this.passwordForm.controls; }

  openLarge(modal) {
    this.modalRef = this.modalService.open(modal, {
      size: "lg",
    });
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.authNoticeService.setNotice(null);
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
  }

  /**
   * Form initalization
   * Default params, validators
   */
  onOtpChange(otp: string): void {
    this.passwordForm.get('password')?.setValue(otp);
  }
  submit() {
    this.submitted1 = true
    if (this.passwordForm.valid && this.loginForm.valid) {
      if (this.mobileLogin == 1) {
        let data = this.passwordForm.value
        this.loading = true;
        var params = new HttpParams()
          .set("mobile_no", this.loginValue)
          .set("otp", data.password)
        let httpOptions = {
          headers: new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        };
        this.httpClient.post("https://api.uengage.in/onboarding/validate_web_otp", params, httpOptions).subscribe((res: any) => {
          if (res.status == 1) {
            this.loading = false
            this.storage.set("AssignedBusiness", res.msg);
            this.storage.set("userId", res.msg[0].userId);
            this.storage.set("userName", this.loginValue);
            // this.storage.set("password", data.password);
            this.storage.set("password", res.msg[0].misc);
            this.toastr.success(res.displayMessage, 'Success', {
              positionClass: 'toast-bottom-right'
            })
            // this.authNoticeService.setNotice(res.displayMessage, "success");
            let data1 = res.onboarding.bDetails
            // (res.onboarding)
            //this.router.navigate(["businessSelector"]);
            if (data1 != undefined) {
              if (data1.onboard_productId == 2) {
                this.router.navigate(["businessSelector"]);
              } else if (data1.onboard_productId == 3) {
                if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                  let data = res.onboarding.bDetails
                  this.storage.set("onboardBusiness", data)
                  this.storage.set("productId", data.onboard_productId);
                  this.storage.set("businessName", data.name);
                  this.router.navigate(["complete-flash-profile"]);
                } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                  let data = res.onboarding.bDetails
                  this.storage.set("onboardBusiness", data)
                  this.router.navigate(["approval-pending"]);
                } else {
                  this.router.navigate(["businessSelector"]);
                }
              } else {
                if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                  let data = res.onboarding.bDetails
                  this.storage.set("onboardBusiness", data)
                  this.storage.set("productId", data.onboard_productId);
                  this.storage.set("businessName", data.name);
                  this.router.navigate(["complete-edge-profile"]);
                } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                  let data = res.onboarding.bDetails
                  this.storage.set("onboardBusiness", data)
                  this.router.navigate(["approval-pending"]);
                } else {
                  this.router.navigate(["businessSelector"]);
                }
              }
            } else {
              // if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
              //   let data = res.onboarding.bDetails
              //   this.storage.set("productId", data.onboard_productId);
              //   this.storage.set("businessName", data.name);
              //   this.router.navigate(["complete-profile"]);
              // } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
              //   this.router.navigate(["approval-pending"]);
              // } else {
              this.router.navigate(["businessSelector"]);
              // }
            }
          } else {
            this.toastr.error(res.msg, 'Error', {
              positionClass: 'toast-bottom-right'
            })
          }
          this.cdr.detectChanges()
        })
      }
      else {
        let data = this.passwordForm.value
        let password = data.password.trim()
        this.loading = true;
        let params =
          "userName=" +
          this.loginValue +
          "&password=" +
          password;
        this.httpClient
          .get("https://api.uengage.in/ueapi/login/addo/?" + params, {})
          .subscribe(
            (res: any) => {
              if (typeof res !== "undefined") {
                if (res.status == 1) {
                  this.storage.set("AssignedBusiness", res.msg);
                  this.storage.set("userId", res.msg[0].userId);
                  this.storage.set("userName", this.loginValue);
                  this.storage.set("password", password);
                  this.toastr.success(res.displayMessage, 'Success', {
                    positionClass: 'toast-bottom-right'
                  })
                  // this.authNoticeService.setNotice(res.displayMessage, "success");
                  let data1 = res.onboarding.bDetails
                  // (res.onboarding)
                  //this.router.navigate(["businessSelector"]);
                  if (data1 != undefined) {
                    if (data1.onboard_productId == 2) {
                      this.router.navigate(["businessSelector"]);
                    } else if (data1.onboard_productId == 3) {
                      // console.log("here")
                      if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                        let data = res.onboarding.bDetails
                        this.storage.set("onboardBusiness", data)
                        this.storage.set("productId", data.onboard_productId);
                        this.storage.set("businessName", data.name);
                        // console.log("navigate")
                        this.router.navigate(["complete-flash-profile"]);
                      } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                        let data = res.onboarding.bDetails
                        this.storage.set("onboardBusiness", data)
                        this.router.navigate(["approval-pending"]);
                      } else {
                        this.router.navigate(["businessSelector"]);
                      }
                    } else {
                      if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                        let data = res.onboarding.bDetails
                        this.storage.set("onboardBusiness", data)
                        this.storage.set("productId", data.onboard_productId);
                        this.storage.set("businessName", data.name);
                        this.router.navigate(["complete-edge-profile"]);
                      } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                        let data = res.onboarding.bDetails
                        this.storage.set("onboardBusiness", data)
                        this.router.navigate(["approval-pending"]);
                      } else {
                        this.router.navigate(["businessSelector"]);
                      }
                    }
                  } else {
                    // if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                    //   let data = res.onboarding.bDetails
                    //   this.storage.set("productId", data.onboard_productId);
                    //   this.storage.set("businessName", data.name);
                    //   this.router.navigate(["complete-profile"]);
                    // } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                    //   this.router.navigate(["approval-pending"]);
                    // } else {
                    this.router.navigate(["businessSelector"]);
                    // }
                  }
                } else {
                  this.toastr.error(res.displayMessage, 'Error', {
                    positionClass: 'toast-bottom-right'
                  })
                  // this.authNoticeService.setNotice(res.displayMessage, "error");
                }
              } else {
                // this.toastr.success(res.displayMessage, 'Success', {
                //   positionClass: 'toast-bottom-right'
                // })
                // this.authNoticeService.setNotice(
                //   this.translate.instant("AUTH.VALIDATION.INVALID_LOGIN"),
                //   "error"
                // );
              }
              this.loading = false;
              // this.cdr.detectChanges();
            },
            (error) => {
              this.authNoticeService.setNotice(
                this.translate.instant("AUTH.VALIDATION.INVALID_LOGIN"),
                "error"
              );
            }
          );
      }
    } else {
      this.loginForm.markAllAsTouched()
      this.passwordForm.markAllAsTouched()
      // console.log('invalid')
    }


  }

  /**
   * Form Submit
   */

  /**
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to valitors name
   */
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.loginForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
  signUp() {
    window.open("https://play.google.com/store/apps/details?id=com.uen.addo", "_blank")
    // this.router.navigateByUrl('/signup')
  }
  signInWithGoogle() {
    let socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    this.authService.signIn(socialPlatformProvider).then(
      (userData: any) => {
        if (userData != null && userData != undefined) {
          let params =
            "email=" +
            userData.email +
            "&accessToken=" + userData.idToken;
          this.httpClient.get("https://api.uengage.in/ueapi/login_google?" + params, {}).subscribe((res: any) => {
            if (typeof res !== "undefined") {
              if (res.status == 1) {
                this.storage.set("AssignedBusiness", res.msg);
                this.storage.set("userId", res.msg[0].userId);
                this.storage.set("userName", res.msg[0].userName);
                this.storage.set("password", res.msg[0].misc);
                let data1 = res.onboarding.bDetails
                if (data1 != undefined) {
                  if (data1.onboard_productId == 2) {
                    this.router.navigate(["businessSelector"]);
                  } else if (data1.onboard_productId == 3) {
                    if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                      let data = res.onboarding.bDetails
                      this.storage.set("onboardBusiness", data)
                      this.storage.set("productId", data.onboard_productId);
                      this.storage.set("businessName", data.name);
                      this.router.navigate(["complete-flash-profile"]);
                    } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                      let data = res.onboarding.bDetails
                      this.storage.set("onboardBusiness", data)
                      this.router.navigate(["approval-pending"]);
                    } else {
                      this.router.navigate(["businessSelector"]);
                    }
                  } else {
                    if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                      let data = res.onboarding.bDetails
                      this.storage.set("onboardBusiness", data)
                      this.storage.set("productId", data.onboard_productId);
                      this.storage.set("businessName", data.name);
                      this.router.navigate(["complete-edge-profile"]);
                    } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                      let data = res.onboarding.bDetails
                      this.storage.set("onboardBusiness", data)
                      this.router.navigate(["approval-pending"]);
                    } else {
                      this.router.navigate(["businessSelector"]);
                    }
                  }
                } else {
                  this.router.navigate(["businessSelector"]);
                }
              } else {
              }
            } else {
            }
          });
        }
        //  (userData);
      })
  }
  // signInWithGoogle() {
  //   // Google's OAuth 2.0 endpoint for requesting an access token
  //   var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

  //   var form = document.createElement('form');
  //   form.setAttribute('method', 'GET'); // Send as a GET request.
  //   form.setAttribute('action', oauth2Endpoint);

  //   // Parameters to pass to OAuth 2.0 endpoint.
  //   var params = {
  //     'client_id': "363296902958-58t2u17b7bkca6kokmq8daq2olhoervu.apps.googleusercontent.com",
  //     'redirect_uri': "http://localhost:4200",
  //     'scope': 'https://www.googleapis.com/auth/userinfo.email',
  //     'state': 'try_sample_request',
  //     'include_granted_scopes': 'true',
  //     'response_type': 'token'
  //   };

  //   // Add form parameters as hidden input values.
  //   for (var p in params) {
  //     var input = document.createElement('input');
  //     input.setAttribute('type', 'hidden');
  //     input.setAttribute('name', p);
  //     input.setAttribute('value', params[p]);
  //     form.appendChild(input);
  //   }

  //   // Add form to page and submit it to open the OAuth 2.0 endpoint.
  //   document.body.appendChild(form);
  //   form.submit();
  // }
  signInWithFB(): void {
    let socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    this.authService.signIn(socialPlatformProvider).then(
      (userData: any) => {
        // (userData)
        if (userData != null && userData != undefined) {
          let params =
            "email=" +
            userData.email +
            "&accessToken=" + userData.authToken;
          this.httpClient.get("https://api.uengage.in/ueapi/login_fb?" + params, {}).subscribe((res: any) => {
            if (typeof res !== "undefined") {
              if (res.status == 1) {
                this.storage.set("AssignedBusiness", res.msg);
                this.storage.set("userId", res.msg[0].userId);
                this.storage.set("userName", res.msg[0].userName);
                this.storage.set("password", res.msg[0].misc);
                let data1 = res.onboarding.bDetails
                if (data1 != undefined) {
                  if (data1.onboard_productId == 2) {
                    this.router.navigate(["businessSelector"]);
                  } else if (data1.onboard_productId == 3) {
                    if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                      let data = res.onboarding.bDetails
                      this.storage.set("onboardBusiness", data)
                      this.storage.set("productId", data.onboard_productId);
                      this.storage.set("businessName", data.name);
                      this.router.navigate(["complete-flash-profile"]);
                    } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                      let data = res.onboarding.bDetails
                      this.storage.set("onboardBusiness", data)
                      this.router.navigate(["approval-pending"]);
                    } else {
                      this.router.navigate(["businessSelector"]);
                    }
                  } else {
                    if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 0) {
                      let data = res.onboarding.bDetails
                      this.storage.set("onboardBusiness", data)
                      this.storage.set("productId", data.onboard_productId);
                      this.storage.set("businessName", data.name);
                      this.router.navigate(["complete-edge-profile"]);
                    } else if (res.onboarding.flag == 1 && res.onboarding.pending_approval == 1) {
                      let data = res.onboarding.bDetails
                      this.storage.set("onboardBusiness", data)
                      this.router.navigate(["approval-pending"]);
                    } else {
                      this.router.navigate(["businessSelector"]);
                    }
                  }
                } else {
                  this.router.navigate(["businessSelector"]);
                }
              } else {
              }
            } else {
            }
          });
        }
        // this.sendToRestApiMethod(userData.token);
      }
    );
    // this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }
  forget(modal) {
    this.emailId = ''
    this.otp = ''
    this.otpValidate = 0
    let data = this.loginForm.value
    if (data.email != null || data.email != undefined || data.email != '') {
      this.emailId = data.email
    }
    this.modalRef = this.modalService.open(modal, {
      windowClass: "custom-modal-class",
      backdrop: 'static',
      backdropClass: "light-blue-backdrop",
      centered: true,
      ariaLabelledBy: "modal-basic-title",
    });
  }
  loginWithMob() {
    this.submitted = true
    if (this.loginForm.valid) {

    }
  }
  getOtpAgain() {
    let data = this.loginForm.value
    var params = new HttpParams()
      .set("mobile_no", data.email)
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    this.httpClient.post("https://api.uengage.in/onboarding/web_login_with_mobile", params, httpOptions).subscribe((res: any) => {
      if (res.status == 1) {
        this.toastr.success(res.message, 'Success', {
          positionClass: 'toast-bottom-right'
        })
        // this.otpSent=1
      } else {
        this.toastr.error(res.message, 'Error', {
          positionClass: 'toast-bottom-right'
        })
        // this.otpSent=0
      }
      this.cdr.detectChanges()
    })
  }
  getOtpLogin() {
    // let data = this.passwordForm.value
    if (this.loginValue != null && this.loginValue != undefined && this.loginValue != '') {
      var params = new HttpParams()
        .set("mobile_no", this.loginValue)
      let httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      };
      this.httpClient.post("https://api.uengage.in/onboarding/web_login_with_mobile", params, httpOptions).subscribe((res: any) => {
        if (res.status == 1) {
          this.toastr.success(res.message, 'Success', {
            positionClass: 'toast-bottom-right'
          })
          this.otpSent = 1
        } else {
          this.toastr.error(res.message, 'Error', {
            positionClass: 'toast-bottom-right'
          })
          this.otpSent = 0
        }
        this.cdr.detectChanges()
      })
    } else {
      this.toastr.error('Please enter mobile number', 'Error', {
        positionClass: 'toast-bottom-right'
      })
    }
  }
  getOtp() {
    if (this.loginValue != '') {
      let params =
        "email=" +
        this.loginValue;
      this.httpClient.get("https://api.uengage.in/addoapi/OTPlogin?" + params).subscribe((data: any) => {
        if (data.status == 1) {
          this.otpValidate = 1
          this.toastr.success(data.msg, 'Success', {
            positionClass: 'toast-bottom-right'
          })
        } else {
          this.toastr.error(data.msg, 'Error', {
            positionClass: 'toast-bottom-right'
          })
          this.otpValidate = 0
        }
      });
    } else {
      this.toastr.error('Please enter email id', 'Error', {
        positionClass: 'toast-bottom-right'
      })
    }
  }
  reqAgain() {
    this.getOtp()
  }
  verifyOtp() {
    let params =
      "email=" +
      this.emailId +
      "&otp=" + this.otp;
    this.httpClient.get("https://api.uengage.in/addoapi/validateOTP?" + params).subscribe((data: any) => {
      if (data.status == 1) {
        if (typeof data !== "undefined") {
          if (data.status == 1) {
            this.storage.set("AssignedBusiness", data.msg);
            this.storage.set("userId", data.msg[0].userId);
            this.storage.set("userName", this.emailId);
            this.storage.set("password", data.msg[0].misc);
            this.toastr.success(data.displayMessage, 'Success', {
              positionClass: 'toast-bottom-right'
            })
            this.modalRef.close()
            this.router.navigate(["businessSelector"]);
          } else {
            this.toastr.error(data.displayMessage, 'Error', {
              positionClass: 'toast-bottom-right'
            })
            // this.authNoticeService.setNotice(res.displayMessage, "error");
          }
        } else {
          // this.toastr.success(res.displayMessage, 'Success', {
          //   positionClass: 'toast-bottom-right'
          // })
          // this.authNoticeService.setNotice(
          //   this.translate.instant("AUTH.VALIDATION.INVALID_LOGIN"),
          //   "error"
          // );
        }
      } else {
        this.toastr.error(data.displayMessage, 'Error', {
          positionClass: 'toast-bottom-right'
        })
      }
    });
  }
  changePasswordVisibility() {
    this.fieldTextType = !this.fieldTextType;
  }
  checkInput() {
    this.submitted = true
    if (this.loginForm.valid) {
      let data = this.loginForm.value
      // console.log(data)
      const emailPattern = /^[\w\.-]+@[\w\.-]+\.\w+$/;
      const mobilePattern = /^\d{10}$/;
      if (emailPattern.test(data.email) || mobilePattern.test(data.email)) {
        this.loginValue = data.email
        this.invalid = 0
        this.isOTPSend = false
        this.passwordForm.get('password').setValue('')
        if (emailPattern.test(data.email)) {
          this.mobileLogin = 0
          this.placeholder = 'Enter password'

        } else if (mobilePattern.test(data.email)) {
          this.mobileLogin = 1
          this.placeholder = 'Enter OTP'

          ///this.getOtpLogin()
        }
      } else {

        this.invalid = 1
      }
      this.cdr.detectChanges()
      // console.log(this.invalid)
    } else {
      // console.log("here")
    }
  }
  sendOTP() {
    this.isOTPSend = true
    this.getOtpLogin()
  }
  checkValue() {
    let data = this.loginForm.value
    if (data.email.length == 0) {
      this.mobileLogin = -1
      this.otpSent = 0
      this.loginForm.controls.password.setValue('')
    }
  }
  backToLogin() {
    this.mobileLogin = -1
    this.fieldTextType = false
    this.passwordForm.controls.password.setValue('')
  }
}
