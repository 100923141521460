// import { Subscription } from "rxjs/Subscription";
// Angular
// import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
// Layout
import {
  LayoutConfigService,
  SplashScreenService,
  TranslationService,
} from "./core/_base/layout";
import { Location } from '@angular/common';
// import { DOCUMENT } from '@angular/common';
// language list
import { locale as enLang } from "./core/_config/i18n/en";
import { locale as chLang } from "./core/_config/i18n/ch";
import { locale as esLang } from "./core/_config/i18n/es";
import { locale as jpLang } from "./core/_config/i18n/jp";
import { locale as deLang } from "./core/_config/i18n/de";
import { locale as frLang } from "./core/_config/i18n/fr";
import { Subscription } from "rxjs/internal/Subscription";
import { ModalDismissReasons, NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Meta, Title } from "@angular/platform-browser";
import { filter } from "rxjs/operators";
import { DOCUMENT, formatDate } from "@angular/common";
import { CookieService } from "ngx-cookie-service";
import Swal from "sweetalert2";
import { MultiTabDetection } from "multi-tab-detection";
import moment from "moment";
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";
// import { SocketService } from "./socket.service";
import { LoaderService } from "./loader.service";
import { Subject } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { lte } from "lodash";
//import { WebSocketService } from "./web-socket.service";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "body[kt-root]",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  // Public properties
  title = "Metronic";
  loader: boolean;
  base_url = "https://api.uengage.in/";
  rider_base_url = "https://riderapi.uengage.in/";
  deviceInfo = null;
  modal = 'warningModal'
  routeArr = [
    {
      "/Dashboard/table-management": "/edge/table-management"
    },
    {
      "/Dashboard/appointments": "/edge/appointment"
    },
    {
      "/Dashboard/package-management": "/edge/package-management"
    },
    {
      "/Dashboard/expense-management": "/edge/expense-management"
    },
    {
      "/Dashboard/membership-management": "/edge/membership-management"
    },
    {
      "/Dashboard/flash-summary": "/flash/flash-summary"
    },
    {
      "/Dashboard/crm-sales": "/admin/crm-sales"
    },
    {
      "/Dashboard/onboarding-approval": "/admin/onboarding-approval"
    },
    {
      "/Dashboard/flash-admin": "/admin/flash-admin"
    },
    {
      "/Dashboard/outlet-audit": "/edge/outlet-audit"
    },
    {
      "/Dashboard/crm-admin": "/admin/crm-admin"
    },
    {
      "/Dashboard/outlet-downtime": "/edge/outlet-downtime"
    },
    {
      "/Dashboard/rider-management": "/flash/rider-management"
    },
    {
      "/Dashboard/prebuy-management": "/edge/prebuy-management"
    },
    {
      "/Dashboard/image-master": "/admin/image-master"
    },
    {
      "/Dashboard/finance-management": "/admin/finance-management"
    },
    {
      "/Dashboard/flash-configuration": "/flash/flash-configuration"
    },
    {
      "/Dashboard/events": "/edge/events"
    },
    {
      "/Dashboard/reservation": "/edge/reservation"
    },
    {
      "/Dashboard/customer-support": "/edge/customer-support"
    },
    {
      "/Dashboard/owner-access": "/admin/owner-access"
    },
    {
      "/Dashboard/customer-interaction": "/prism/customer-interaction"
    },
    {
      "/Dashboard/inventory-management": "/edge/inventory-management"
    },
    {
      "/Dashboard/maps-locality": "/edge/maps-locality"
    },
    {
      "/Dashboard/marketingAutomation": "/prism/marketingAutomation"
    },
    {
      "/Dashboard/segments": "/prism/campaigns/segments"
    },
    {
      "/Dashboard/campaigns": "/prism/campaigns"
    },
    {
      "/Dashboard/nps-module": "/prism/nps-module"
    },
    {
      "/Dashboard/mess-flow": "/edge/mess-flow"
    },
    {
      "/Dashboard/web_config": "/corporate/web_config"
    },
    {
      "/edge/web_config": "/corporate/web_config"
    },
    {
      "/Dashboard/staffManagement": "/edge/staffManagement"
    },
    {
      "/Dashboard/feed-management": "/corporate/feed-management"
    },
    {
      "/edge/feed-management": "/corporate/feed-management"
    },
    {
      "/Dashboard/enquiry-form": "/edge/enquiry-form"
    },
    {
      "/Dashboard/menuManagement": "/edge/menu-management"
    },
    {
      "/Dashboard/serviceManagement": "/edge/serviceManagement"
    },
    {
      "/Dashboard/request-inventory": "/edge/request-inventory"
    },
    {
      "/Dashboard/bulk-inventory-update": "/edge/bulk-inventory-update"
    },
    {
      "/Dashboard/dashboard": "/edge/dashboard"
    },
    {
      "/Dashboard/flash-dashboard": "/flash/flash-dashboard"
    },
    {
      "/Dashboard/imageManagement": "/edge/imageManagement"
    },
    {
      "/Dashboard/pending-payout": "/edge/pending-payout"
    },
    {
      "/Dashboard/payments": "/edge/payments"
    },
    {
      "/Dashboard/promoManagement": "/edge/promoManagement"
    },
    {
      "/Dashboard/loyaltyDashboard": "/prism/loyaltyDashboard"
    },
    {
      "/Dashboard/crm-customers": "/prism/crm-customers"
    },
    {
      "/Dashboard/reviews-rating": "/prism/reviews-rating"
    },
    {
      "/Dashboard/sales-admin": "/admin/sales-admin"
    },
    {
      "/Dashboard/automated-campaigns": "/prism/automated-campaigns"
    },
    {
      "/Dashboard/storeManagement": "/corporate/storeManagement"
    },
    {
      "/edge/storeManagement": "/corporate/storeManagement"
    },
    {
      "/Dashboard/manage-documentation": "/edge/manage-documentation"
    },
    {
      "/Dashboard/blog-manager": "/wla/blog-manager"
    },
    {
      "/edge/blog-manager": "/wla/blog-manager"
    },
    {
      "/Dashboard/invoices": "/edge/invoices"
    },
    {
      "/Dashboard/leadGenerationForm": "/edge/leadGenerationForm"
    },
    {
      "/Dashboard/reports": "/edge/reports"
    },
    {
      "/Dashboard/order": "/edge/order"
    },
    {
      "/Dashboard/order-support": "/admin/order-support"
    },
    {
      "/Dashboard/flash-task": "/flash/flash-task"
    },
    {
      "/edge/on-call-support": "/corporate/on-call-support"
    },
    {
      "/admin/super-admin": "/admin/super-admin"
    },
  ]
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  closeResult: string;
  multiTabs: number;

  /**
   * Component constructor
   *
   * @param translationService: TranslationService
   * @param router: Router
   * @param layoutConfigService: LayoutConfigService
   * @param splashScreenService: SplashScreenService
   */
  isLoading: Subject<boolean> = this.loaderService.isLoading;
  parentBusinessId: any;
  categoryId: any;
  Amount = '';
  disableBtn: number;
  taxShow: number;
  subTotal: any;
  value: any;
  edit: number;
  cdr: any;
  constructor(
    // public socketService: SocketService,
    private loaderService: LoaderService,
    private location: Location,
    private translationService: TranslationService,
    private router: Router, private cd: ChangeDetectorRef,
    private layoutConfigService: LayoutConfigService, private elementRef: ElementRef,
    private splashScreenService: SplashScreenService, private modalRef: NgbActiveModal,
    private activatedRoute: ActivatedRoute, private http: HttpClient,
    private modalService: NgbModal, public meta: Meta, public title1: Title, private cookieService: CookieService,
    @Inject(DOCUMENT) private _document: HTMLDocument, @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) // private webSocketService: WebSocketService
  {
    // (router.url)
    // router.events.filter(event => event instanceof NavigationEnd)
    // .subscribe(event => 
    //  {
    //     // this.currentRoute = event.url;          
    //     (event);
    //  });
    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
  ngOnInit(): void {
    // console.log("hey")
    // this.modalRef.shown.subscribe(() => {
    //   this.elementRef.nativeElement.querySelector('.modal-dialog').classList.add('show');
    // });
    let url = this.location.path()
    for (let obj of this.routeArr) {
      // (obj)
      for (let route of Object.keys(obj)) {
        // (route)
        if (url == route) {
          window.location.href = obj[route]
        } else {

        }
        // ("value-" + obj[prop]);
        // ("key-" + [prop]);
      }
    }
    var multiTabDetection = new MultiTabDetection();
    multiTabDetection.NewTabDetectedEvent.subscribe({
      next: (totalNumberOfTabsOpened) => {
        // (totalNumberOfTabsOpened)
        if (totalNumberOfTabsOpened > 1) {
          // (this._document.getElementById('appFavicon'))
          this._document.getElementById('appFavicon').setAttribute('href', '../assets/media/logos/error.png');
          // localStorage.clear()
          this.multiTabs = 1
          // (this.multiTabs)
          this.cd.detectChanges()
        }
      }
    });
    // Broadcast that you're opening a page.
    //  localStorage.openpages = Date.now();
    //  window.addEventListener('storage', function (e) {
    //      if(e.key == "openpages") {
    //          // Listen if anybody else is opening the same page!
    //          localStorage.page_available = Date.now();
    //      }
    //      if(e.key == "page_available") {
    //          Swal.fire({
    //           text:'uEngage Dashboard is open in another window. Please use the first window.'
    //          })
    //      }
    //  }, false);

    // let currentdate = formatDate(new Date(), 'HH:mm a', 'en');
    // if(currentdate=='10:00 AM') {
    //   if(this.cookieService.get('latestModule')!=undefined){

    //   } else {
    //     this.cookieService.set('latestModule', '1', { expires: 1, sameSite: 'Lax' });
    //   }
    // } else {
    //   if(this.cookieService.get('latestModule')!=undefined){

    //   } else {
    //     this.cookieService.set('latestModule', '1', { expires: 1, sameSite: 'Lax' });
    //   }
    // }
    // (currentdate)
    // ('i am called')
    // (this.router.url)
    // if(this.router.url=='/'){
    //   this.title1.setTitle('uEngage Dashboard');
    //   this.meta.updateTag({ name: 'description', content: '' }); 
    //   this.meta.addTag({ property: 'og:title', content: 'uEngage Dashboard' }); 
    //   this.meta.addTag({ property: 'og:image', content: 'https://www.uengage.io/assets/uen/img/uengage-mascot-banner.png' }); 
    //   this.meta.addTag({ property: 'og:type', content: 'image:png' }); 
    // }

    //  subscribes to the router change event
    this.router.events.pipe(filter(event => event instanceof NavigationEnd),).subscribe(() => {
      //traverse through the ActivatedRoute tree to get to the current route.
      var rt = this.getChild(this.activatedRoute)
      //Once we have the ActivateRoute of the currently loaded component, we subscribe to the data property to read the Route Data.
      rt.data.subscribe(data => {
        //(data);
        this.title1.setTitle(data.title)
        if (data.ogTitle) {
          this.meta.updateTag({ property: 'og:title', content: data.ogTitle })
        } else {
          this.meta.updateTag({ property: 'og:title', content: 'uEngage Dashboard' })
        }
        this.cd.detectChanges()
        // if (data.title) {
        //   this.meta.updateTag({ name: 'title', content: data.title })
        // } else {
        //   this.meta.removeTag("name='title'")
        // }
      })
    })
    this.loader = this.layoutConfigService.getConfig("page-loader.type");
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add("page-loaded");
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
    if (this.storage.get("AssignedBusiness") != undefined) {
      let selectedBusinessDetails = this.storage
        .get("AssignedBusiness")
        .filter((e) => e.businessId == this.storage.get("selectedBusinessId"));
      this.parentBusinessId = selectedBusinessDetails[0]["parentId"];
      this.categoryId = selectedBusinessDetails[0]["category_id"];
      let validity = selectedBusinessDetails[0]["validity_date"]
      if (validity != null) {
        let valid_till = validity.split(" ")
        valid_till = valid_till[0]
        let endDate = formatDate(valid_till, 'yyyy-MM-dd', 'en')
        let startDate = formatDate(new Date(), 'yyyy-MM-dd', 'en')
        let daysLeft = moment(endDate).diff(moment(startDate), 'days');
        if (daysLeft < 0) {
          document.getElementById("warningButton").click();
        }
      }

    }

    // if(this.categoryId!=4){
    //   this.getBalance()
    // }
  }



  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
  close() {
    this.multiTabs = 0
    let isBuzz = this.storage.get("isBuzz");
    let isGuide = this.storage.get("isGuide");
    localStorage.clear();
    this.storage.set("isBuzz", isBuzz);
    this.storage.set("isGuide", isGuide);
    let path = "";
    this.router.navigate([path]);
    // localStorage.clear();
    // let path = "";
    // this.router.navigate([path]);
  }
  openWarningLarge(modal) {
    this.modalRef = this.modalService
      .open(modal, {
        backdrop: 'static',
        backdropClass: "light-blue-backdrop",
        centered: true,
        keyboard: false,
        ariaLabelledBy: "modal-basic-title",
        size: 'md'
      })
  }
  openWalletLarge(modal) {
    this.modalRef = this.modalService
      .open(modal, {
        backdrop: 'static',
        backdropClass: "light-blue-backdrop",
        centered: true,
        keyboard: false,
        ariaLabelledBy: "modal-basic-title",
        size: 'md',
      })
  }
  logout() {
    this.modalRef.close()
    let isBuzz = this.storage.get("isBuzz");
    let isGuide = this.storage.get("isGuide");
    localStorage.clear();
    this.storage.set("isBuzz", isBuzz);
    this.storage.set("isGuide", isGuide);
    let path = "";
    this.router.navigate([path]);
  }
  getBalance() {
    let walletBalance
    let params = new HttpParams({
      fromObject: {
        userId: this.storage.get("userId"),
        password: this.storage.get('password'),
        business_id: this.storage.get('selectedBusinessId'),
        parent_business_id: this.parentBusinessId
      }
    })
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    let url = "https://crm.uengage.in/crm_api/get/wallet/balance";
    this.http.post(url, params, httpOptions).subscribe((res: any) => {
      if (res.result.wallet_balance != undefined) {
        walletBalance = res.result.wallet_balance;
      } else {
        walletBalance = 0
      }
      if (walletBalance < 500) {
        document.getElementById("walletButton").click();
      }
    })
  }
  recharge(modal) {
    this.modalRef.close()
    this.modalService.open(modal, {
      windowClass: "custom-modal-class",
      backdrop: 'static',
      backdropClass: "light-blue-backdrop",
      centered: true,
      ariaLabelledBy: "modal-basic-title",
    });
  }
  calculateTax() {
    if (this.Amount == '') {
      this.disableBtn = 1
    } else if (Number(this.Amount) < 1000) {
      this.disableBtn = 1
      Swal.fire('', 'Recharge amount cannot be less than 1000.', 'error')
      return false
    }
    else {
      this.disableBtn = 0
      this.taxShow = 1
      this.subTotal = this.Amount
      // this.finalAmount = Number(((amount * 100) / 118).toFixed(2));
      // this.taxAmount = this.subTotal - this.finalAmount
    }
    // this.taxAmount = 0
    // this.subTotal = 0
    // this.finalAmount = 0
    // if (value == 1) {
    //   this.taxShow = 1
    //   this.subTotal = this.AddBalanceForm.value.Amount
    //   let amount = (this.AddBalanceForm.value.Amount*this.tax)/100
    //   this.finalAmount = this.AddBalanceForm.value.Amount + amount
    //   // this.finalAmount = this.AddBalanceForm.value.Amount/1.18
    //   // this.taxAmount = this.subTotal -  this.finalAmount
    //   this.taxAmount = amount
    // } else {
    //   this.taxShow = -1
    //   this.finalAmount = this.AddBalanceForm.value.Amount
    // }
  }
  updateMoney(value) {
    this.value = value
    if (value != -1) {
      this.Amount = value
      // this.AddBalanceForm.controls.Amount.setValue(value)
      // this.AddBalanceForm.value.Amount = value
      this.calculateTax()

      this.edit = 0
      this.cdr.detectChanges()
    } else {
      this.Amount = ''
      // this.AddBalanceForm.controls.Amount.setValue('')
      this.taxShow = 0
      this.edit = 1
      this.cdr.detectChanges()
    }
  }
  UpdateData() {
    // this.submitted = true;
    if (this.Amount != '') {
      let params =
        "userId=" + this.storage.get('userId') +
        "&password=" + this.storage.get('password') +
        "&businessId=" + this.storage.get('selectedBusinessId') +
        "&amount=" + this.Amount;
      this.http.get("https://api.uengage.in/wallet_recharge/createOrder?" + params).subscribe((data: any) => {
        if (data.status == 1) {
          this.modalRef.close()
          //  this.orderId = data.order_id
          //  this.storage.set("paymentOrderId",this.orderId)
          window.location.href = data.payment_link
          //  this.showLoader=1
        } else {
          Swal.fire('', data.msg, 'error')
          //  this.showLoader=0
        }
      })
    }
  }
}
