// import { CommonModule } from "@angular/common";
// Angular
import {
  BrowserModule, Meta
} from "@angular/platform-browser";
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
// import { GestureConfig } from "@angular/material/core";
import { OverlayModule } from "@angular/cdk/overlay";
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { ToastrModule } from 'ngx-toastr';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { CountdownModule } from 'ngx-countdown';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DatePipe } from '@angular/common';
// import {PopoverModule} from 
// import { FilterPipe } from "ngx-filter-pipe";
// import { FilterPipe } from "../app/views/theme/search-pipe/filter.pipe";
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from 'angularx-social-login';
// Angular in memory
import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";
import { ConnectionServiceModule } from 'ng-connection-service';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import timeGrigPlugin from '@fullcalendar/timegrid';
import { DateTimePickerModule } from "@syncfusion/ej2-angular-calendars";
import { TimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
// import {sign, SignOptions} from "jsonwebtoken"
// import { DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService } from '@syncfusion/ej2-angular-schedule';
// Perfect Scroll bar
FullCalendarModule.registerPlugins([
  interactionPlugin,
  dayGridPlugin,
  timeGrigPlugin
]);
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";
// SVG inline
import { InlineSVGModule } from "ng-inline-svg";
// Env
import { environment } from "../environments/environment";
// Hammer JS
import "hammerjs";
// NGX Permissions
import { NgxPermissionsModule } from "ngx-permissions";
import { CanDeactivateGuard } from '../../src/app/can-deactivate/can-deactivate.guard';
// NGRX
// import { DataTablesModule } from 'angular-datatables';
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
// State
import { metaReducers, reducers } from "./core/reducers";
// Components
import { AppComponent } from "./app.component";
// Modules
import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";
import { ThemeModule } from "./views/theme/theme.module";
// Partials
import { PartialsModule } from "./views/partials/partials.module";
import { TooltipModule } from 'ng2-tooltip-directive';
// Layout Services
import {
  DataTableService,
  FakeApiService,
  KtDialogService,
  LayoutConfigService,
  LayoutRefService,
  MenuAsideService,
  MenuConfigService,
  MenuHorizontalService,
  PageConfigService,
  SplashScreenService,
  SubheaderService,
} from "./core/_base/layout";

// Auth
import { AuthModule } from "./views/pages/auth/auth.module";
import { AuthService } from "./core/auth";
// CRUD
import {
  HttpUtilsService,
  LayoutUtilsService,
  TypesUtilsService,
} from "./core/_base/crud";
// Config
import { LayoutConfig } from "./core/_config/layout.config";

// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from "ngx-highlightjs";
import xml from "highlight.js/lib/languages/xml";
import json from "highlight.js/lib/languages/json";
import scss from "highlight.js/lib/languages/scss";
import typescript from "highlight.js/lib/languages/typescript";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ColorPickerModule } from '@syncfusion/ej2-angular-inputs';
// import { MessagingService } from "./messaging.service";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireModule } from "@angular/fire";
import { ServiceWorkerModule } from "@angular/service-worker";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgCircleProgressModule } from 'ng-circle-progress';
// import { Ng2DeviceDetectorModule } from "ng2-device-detector";
import { ScheduleModule } from '@syncfusion/ej2-angular-schedule';
// import {PopoverModule} from "ngx-smart-popover";
// import { QRCodeModule } from "angularx-qrcode";
import { QRCodeModule } from 'angular2-qrcode';
// import { NgxPopperModule } from 'ngx-popper';
import { NgxPopper } from 'angular-popper';
import { PipeModule } from "./pipe/pipe.module";
import { HashLocationStrategy } from "@angular/common";
// import { SocketService } from "./socket.service";
import { SpinnerComponent } from './spinner/spinner.component';
import { LoadingInterceptor } from "./loading.interceptor";
import { OrderCardComponent } from "./views/pages/order-card/order-card.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgOtpInputModule } from "ng-otp-input";
import { NgxDatatableModule } from "@tusharghoshbd/ngx-datatable";
import { HttpMethodInterceptor } from "./interceptors/http-method.interceptor";
import { NgxSimpleTextEditorModule } from 'ngx-simple-text-editor';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
// import { OrderCardComponent } from "./views/pages/order-card/order-card.component";
// import { RouterModule } from "@angular/router";
// import { AppRoutingModule } from "./app-routing.module";
// import {PopoverModule} from "ngx-popover";
// import { AdminWalletLedgerComponent } from './views/pages/lazy/admin-wallet-ledger/admin-wallet-ledger.component';
// import { ReviewsratingsComponent } from './reviewsratings/reviewsratings.component';
// import { OutletInventoryComponent } from './outlet-inventory/outlet-inventory.component';
// import { OrderModule } from "ngx-order-pipe";
// import { SalonInventoryComponent } from './salon-inventory/salon-inventory.component';
//import { environment } from "../environments/environment";

// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelSpeed: 0.5,
  swipeEasing: true,
  minScrollbarLength: 40,
  maxScrollbarLength: 300,
};
const googleLoginOptions = {
  scope: 'profile email',
  plugin_name: 'sample_login'//this can be any string
};
const fbLoginOptions = {
  enable_profile_selector: true,
  return_scopes: true,
  scope: 'email,public_profile',
  auth_type: 'rerequest',
  version: 'v3.2'
};
export function playerFactory() {
  return player;
}
export function initializeLayoutConfig(appConfig: LayoutConfigService) {
  // initialize app by loading default demo layout config
  return () => {
    if (appConfig.getConfig() === null) {
      appConfig.loadConfigs(new LayoutConfig().configs);
    }
  };
}
/**
 * Import specific languages to avoid importing everything
 * The following will lazy load highlight.js core script (~9.6KB) + the selected languages bundle (each lang. ~1kb)
 */
export function getHighlightLanguages() {
  return [
    { name: "typescript", func: typescript },
    { name: "scss", func: scss },
    { name: "xml", func: xml },
    { name: "json", func: json },
  ];
}
// const config = new AuthServiceConfig([
//   {
//     id: FacebookLoginProvider.PROVIDER_ID,
//     provider: new FacebookLoginProvider('561602290896109'),
//   },
// ]);

// export function provideConfig() {
//   return config;
// }
@NgModule({
  declarations: [AppComponent, SpinnerComponent],
  imports: [
    // HashLocationStrategy RouterModule.forRoot(appRoutes, { useHash: true },
    PipeModule,
    // DataTablesModule,
    NgxDatatableModule,
    CarouselModule,
  
    BrowserAnimationsModule,
    // TypeaheadModule,
    DateRangePickerModule,
    // CommonModule,
    BrowserModule,
    PickerModule,
    AppRoutingModule,
    // NgxTagsInputModule,
    //NgxPopperModule.forRoot(),
    // PopoverModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    DropDownListModule,
    ScheduleModule,
    LottieModule.forRoot({ player: playerFactory }),
    MultiSelectModule,
    NgxJsonViewerModule,
    ColorPickerModule,
    NgCircleProgressModule.forRoot(),
    GooglePlaceModule,
    environment.isMockEnabled
      ? HttpClientInMemoryWebApiModule.forRoot(FakeApiService, {
        passThruUnknownUrl: true,
        dataEncapsulation: false,
      })
      : [],
    NgxPermissionsModule.forRoot(),
    NgxPopper,
    HighlightModule,
    SocialLoginModule,
    FullCalendarModule,
    PartialsModule,
    CountdownModule,
    ConnectionServiceModule,
    CoreModule,
    OverlayModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({ stateKey: "router" }),
    StoreDevtoolsModule.instrument(),
    AuthModule.forRoot(),
    TranslateModule.forRoot(),
    //Ng2DeviceDetectorModule.forRoot(),
    MatProgressSpinnerModule,
    DateTimePickerModule,
    InlineSVGModule.forRoot(),
    ThemeModule,
    FormsModule,
    QRCodeModule,
    ReactiveFormsModule,
    // OrderModule,
    TooltipModule,
    TimePickerModule,
    DatePickerModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AutocompleteLibModule,
    NgOtpInputModule,
    // // ServiceWorkerModule.register("./firebase-messaging-sw.js"),
    AngularFireModule.initializeApp(environment.firebase),
    // sign, SignOptions
  ],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpMethodInterceptor,
      multi: true,
    },
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
    },
    Meta,
    {

      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          // {
          //   id: GoogleLoginProvider.PROVIDER_ID,
          //   provider: new GoogleLoginProvider(
          //     '624796833023-clhjgupm0pu6vgga7k5i5bsfp6qp6egh.apps.googleusercontent.com'
          //   ),
          // },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('297696905697269'),
          },
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('363296902958-58t2u17b7bkca6kokmq8daq2olhoervu.apps.googleusercontent.com', googleLoginOptions)
          }
        ],
      } as SocialAuthServiceConfig,
    },
    AuthService,
    // SocketService,
    LayoutConfigService,
    LayoutRefService,
    MenuConfigService,
    PageConfigService,
    KtDialogService,
    CanDeactivateGuard,
    DataTableService,
    SplashScreenService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    // {
    //   provide: HAMMER_GESTURE_CONFIG,
    //   useClass: GestureConfig,
    // },
    {
      // layout config initializer
      provide: APP_INITIALIZER,
      useFactory: initializeLayoutConfig,
      deps: [LayoutConfigService],
      multi: true,
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        languages: getHighlightLanguages,
      },
    },
    // template services
    SubheaderService,
    MenuHorizontalService,
    MenuAsideService,
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService
    // MessagingService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
