// Angular
import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, FormGroup, ReactiveFormsModule } from "@angular/forms";
// import { HttpClientModule } from "@angular/common/http";
// Partials
import { PartialsModule } from "../partials/partials.module";
import 'emoji-picker-element';
// Pages
import { CoreModule } from "../../core/core.module";
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { StorageServiceModule } from "angular-webstorage-service";
import { NgxSpinnerModule } from "ngx-spinner";
import { ImageCropperModule } from "ngx-image-cropper";
import { FilterPipeModule } from "ngx-filter-pipe";
import { ConfirmationPopoverModule } from "angular-confirmation-popover";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { NgxPaginationModule } from "ngx-pagination";
import { DataTableModule } from "angular-6-datatable";
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { NgxEmojiPickerModule } from "ngx-emoji-picker";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { EditorModule } from "@tinymce/tinymce-angular";
import { ChartsModule } from "ng2-charts";
import { NgxPrintModule } from "ngx-print";
import { RouterModule } from "@angular/router";
import { NgMonthPickerModule } from 'ng-month-picker';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

// import { NgHttpLoaderModule } from "ng-http-loader";
import { TruncatePipe } from '../../limitTo.pipe';
import { ColorPickerModule } from '@syncfusion/ej2-angular-inputs';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import timeGrigPlugin from '@fullcalendar/timegrid';
// import { DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService } from '@syncfusion/ej2-angular-schedule';
FullCalendarModule.registerPlugins([
  interactionPlugin,
  dayGridPlugin,
  timeGrigPlugin
]);
import {
  NgbModule,
  NgbModal,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";
import { MatTableModule } from "@angular/material/table";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatCardModule } from '@angular/material/card';
import { MessagingService } from "../../messaging.service";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireModule } from "@angular/fire";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../../../environments/environment";
import { NgApexchartsModule } from "ng-apexcharts";
import { InlineSVGModule } from "ng-inline-svg";
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
// import { MatSelectModule } from "@angular/material/select";
// import { BusinessSelectorComponent } from "./lazy/businessSelector/businessSelector.component";
// import { onlineOrderComponent } from "./lazy/onlineOrder/onlineOrder.component";
// import { UpdateInventoryComponent } from "./lazy/update-inventory/update-inventory.component";
// import { OrderInfoComponent } from "./lazy/orderInfo/orderInfo.component";
// import { PrintOnlineComponent } from "./lazy/printOnlineOrder/printOnlineOrder.component";
// import { RequestDetailsComponent } from "./lazy/request-details/request-details.component";
// import { RequestInventoryComponent } from "./lazy/request-inventory/request-inventory.component";
// import { RequestManagementComponent } from "./lazy/request-management/request-management.component";
// import { RequestItemComponent } from "./lazy/request-item/request-item.component";
// import { RiderManagementComponent } from "./lazy/riderManagement/riderManagement.component";
// import { MessFlowComponent } from "./lazy/mess-flow/mess-flow.component";
// import { RiderPastOrders } from "./lazy/riderPastOrders/riderPastOrders.component";
// import { MenuManagementComponent } from "./lazy/menuManagement/menuManagement.component";
// import { MenuItemManagementComponent } from "./lazy/menuItemManagement/menuItemManagement.component";
// import { MapModuleComponent } from "./lazy/map-module/map-module.component";
// import { WebConfigComponent } from "./lazy/web-config/web-config.component";
// import { MarketPlaceComponent } from "./lazy/market-place/market-place.component";
// import { SafetyMeasureComponent } from "./lazy/safetyMeasure/safetyMeasure.component";
// import { CampaignManagementComponent } from "./lazy/campaign-management/campaign-management.component";
// import { BulkInventoryUpdateComponent } from "./lazy/bulkInventoryUpdate/bulkInventoryUpdate.component";
// import { DashboardComponent } from "./dashboard/dashboard.component";
// import { ImageManagementComponent } from "./lazy/imageManagement/imageManagement.component";
// import { PaymentComponent } from "./lazy/payments/payments.component";
// import { Payment_BreakupComponent } from "./lazy/payment_breakup/payment_breakup.component";
// import { PrintComponent } from "./lazy/print/print.component";
// import { PromoManagementComponent } from "./lazy/promoManagement/promoManagement.component";
// import { LogoutComponent } from "./lazy/logout/logout.component";
// import { PromoDetailsComponent } from "./lazy/promoDetails/promoDetails.component";
// import { UserProfileManagement } from "./lazy/userProfileManagement/userProfile.component";
// import { DetailedUserProfile } from "./lazy/detailedUserProfile/detailedUserProfile.component";
// import { LoyaltyComponent } from "./lazy/loyalty/loyalty.component";
// import { PushNotificationComponent } from "./lazy/pushNotification/pushNotification.component";
// import { StoreManagementComponent } from "./lazy/storeManagement/storeManagement.component";
// import { DeliveryLogisticsComponent } from "./lazy/deliveryLogistics/deliveryLogistics.component";
// import { StoreInfoComponent } from "./lazy/storeInfo/storeInfo.component";
// import { ViewStoreComponent } from "./lazy/viewStore/viewStore.component";
// import { BannerManagementComponent } from "./lazy/bannerManagement/bannerManagement.component";
// import { DocumentationManagerComponent } from "./lazy/documentationManager/documentationManager.component";
// import { AddDocumentComponent } from "./lazy/addDocumentation/addDocumentation.component";
// import { BlogManagerComponent } from "./lazy/blogManager/blogManager.component";
// import { AddBlogComponent } from "./lazy/addBlog/addBlog.component";
// import { InvoiceComponent } from "./lazy/invoices/invoices.component";
// import { ShowDocumentComponent } from "./lazy/showDocumentation/showDocumentation.component";
// import { LeadGenerationFormComponent } from "./lazy/leadGenerationForm/leadGenerationForm.component";
// import { ViewLeadComponent } from "./lazy/viewLead/viewLead.component";
// import { AddLeadFieldsComponent } from "./lazy/addLeadFormFields/addLeadFields.component";
// import { FeedManagementComponent } from "./lazy/feedManagement/feedManagement.component";
// import { ReportsComponent } from "./lazy/reporting/reports.component";
// import { OrderComponent } from "./lazy/orders/order.component";
// import { POSComponent } from "./lazy/pos/pos.component";
// import { CrmComponent } from "./lazy/crm/crm.component";
// import { SegmentsComponent } from "./lazy/segments/segments.component";
// import { NpsModuleComponent } from "./lazy/nps-module/nps-module.component";
// import { OnboardingComponent } from "./lazy/onboarding/onboarding.component";
// import { ReportComponent } from "./lazy/cutomer_review/report.component";
import { NgCircleProgressModule } from "ng-circle-progress";
import { TooltipModule } from "ng2-tooltip-directive";
// import { Ng2DeviceDetectorModule } from "ng2-device-detector";
import { ScheduleModule } from "@syncfusion/ej2-angular-schedule";
import { DateTimePickerModule, TimePickerModule, DatePickerModule, DateRangePickerModule } from "@syncfusion/ej2-angular-calendars";

import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from "angularx-social-login";
// import { NgxPopperModule } from 'ngx-popper';
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoadingInterceptor } from "src/app/loading.interceptor";
import { FlashDboardComponent } from './flash-dashboard/flash-dboard/flash-dboard.component';
// import { FlashFooterComponent } from './flash-footer/flash-footer.component';
// import { FlashHeadComponent } from './flash-head/flash-head.component';
// import { LiveRiderMainComponent } from './live-rider-main/live-rider-main.component';
// import { OrderCardComponent } from "./order-card/order-card.component";
// import { OrderCardComponent } from './lazy/order-card/order-card.component';
// import {PopoverModule} from "ngx-smart-popover";
// import { OrderModule } from "ngx-order-pipe";

// import { ToastrModule } from 'ngx-toastr';
// import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// import { ViewStoreComponent } from "./components/viewStore/viewStore.component";

// import {NgxChildProcessModule} from 'ngx-childprocess';
const googleLoginOptions = {
  scope: 'profile email',
  plugin_name: 'sample_login'//this can be any string
};
const fbLoginOptions = {
  enable_profile_selector: true,
  return_scopes: true,
  scope: 'email,public_profile',
  auth_type: 'rerequest',
  version: 'v3.2'
};
@NgModule({
  declarations: [
    // OrderCardComponent,
    // BusinessSelectorComponent,
    // onlineOrderComponent,
    // UpdateInventoryComponent,
    // OrderInfoComponent,
    // PrintOnlineComponent,
    // RequestDetailsComponent,
    // RequestInventoryComponent,
    // RequestManagementComponent,
    // RequestItemComponent,
    // RiderManagementComponent,
    // MessFlowComponent,
    // RiderPastOrders,
    // MenuManagementComponent,
    // MenuItemManagementComponent,
    // MapModuleComponent,
    // WebConfigComponent,
    // MarketPlaceComponent,
    // SafetyMeasureComponent,
    // CampaignManagementComponent,
    // BulkInventoryUpdateComponent,
    // DashboardComponent,
    // ImageManagementComponent,
    // PaymentComponent,
    // Payment_BreakupComponent,
    // PrintComponent,
    // PromoManagementComponent,
    // LogoutComponent,
    // PromoDetailsComponent,
    // UserProfileManagement,
    // DetailedUserProfile,
    // LoyaltyComponent,
    // PushNotificationComponent,
    // StoreManagementComponent,
    // DeliveryLogisticsComponent,
    // StoreInfoComponent,
    // ViewStoreComponent,
    // BannerManagementComponent,
    // DocumentationManagerComponent,
    // AddDocumentComponent,
    // BlogManagerComponent,
    // AddBlogComponent,
    // ShowDocumentComponent,
    // InvoiceComponent,
    // LeadGenerationFormComponent,
    // ViewLeadComponent,
    // AddLeadFieldsComponent,
    // FeedManagementComponent,
    // ReportsComponent,
    // OrderComponent,
    // POSComponent,
    // CrmComponent,
    // SegmentsComponent,
    TruncatePipe,
    // FlashDboardComponent,
    // FlashFooterComponent,
    // FlashHeadComponent,
    // LiveRiderMainComponent,
    // OrderCardComponent,
    // ReportComponent,
    // NpsModuleComponent,
    // OnboardingComponent
    // ColorPickerModule
  ],
  exports: [NgbModule],
  imports: [
    CommonModule,
    // HttpClientModule,
    FormsModule,
    TooltipModule,
    ScheduleModule,
    CoreModule,
    PartialsModule,
    StorageServiceModule,
    NgxSpinnerModule,
    ImageCropperModule,
    FilterPipeModule,
    NgCircleProgressModule.forRoot(),
    // NgHttpLoaderModule,
    DropDownListModule,
    MultiSelectModule,
    //NgxPopperModule.forRoot(),
    // PopoverModule,
    // ToastrModule.forRoot(),
    // BrowserAnimationsModule,
    NgbModule,
    PickerModule,
    MatTableModule,
    MatGridListModule,
    // OrderModule,
    MatCardModule,
    MatSlideToggleModule,
    FullCalendarModule,
    NgxPaginationModule,
    DatePickerModule,
    DataTableModule,
    NgxEmojiPickerModule,
    ReactiveFormsModule,
    SocialLoginModule,
    Ng2SearchPipeModule,
    // HttpClientModule,
    AngularEditorModule,
    NgMonthPickerModule,
    EditorModule,
    ColorPickerModule,
    //Ng2DeviceDetectorModule.forRoot(),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: "danger", // set defaults here
    }),

    NgMultiSelectDropDownModule.forRoot(),
    // BusinessSelectorModule,
    // OnlineOrderModule,
    DateTimePickerModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    // ServiceWorkerModule.register("./firebase-messaging-sw.js"),
    // AngularFireModule.initializeApp(environment.firebase),
    NgApexchartsModule,
    TimePickerModule,
    InlineSVGModule,
    ChartsModule,
    // MatSelectModule,
    NgxPrintModule,
    RouterModule,
    AutocompleteLibModule,
    DateRangePickerModule
  ],
  providers: [NgbModal, NgbActiveModal, MessagingService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          // {
          //   id: GoogleLoginProvider.PROVIDER_ID,
          //   provider: new GoogleLoginProvider(
          //     '624796833023-clhjgupm0pu6vgga7k5i5bsfp6qp6egh.apps.googleusercontent.com'
          //   ),
          // },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('297696905697269'),
          },
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('363296902958-58t2u17b7bkca6kokmq8daq2olhoervu.apps.googleusercontent.com', googleLoginOptions)
          }
        ],
      } as SocialAuthServiceConfig,
    },
    // {
    //   provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
    // },
  ],
})
export class PagesModule {
  static forRoot(): ModuleWithProviders<PagesModule> {
    return {
      ngModule: PagesModule
    };
  }
}
