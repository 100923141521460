<div class="row w-100 justify-content-center p-5" *ngIf="warningShow==1">
  <div class="alert alert-custom alert-light-danger fade show mb-5" role="alert">
    <div class="alert-icon"><i class="flaticon-warning"></i></div>
    <div class="alert-text">Due to some chrome restrictions, {{deviceInfo.os_version | uppercase}} is not compatible.
      Please upgrade to WINDOWS-10 or use MOZILLA FIREFOX</div>
    <!-- <div class="alert-close">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true"><i class="ki ki-close"></i></span>
        </button>>
    </div> -->
  </div>
</div>



<div style="background: linear-gradient(174.83deg, #F8FFED -27.01%, #78B70B 83.05%);">
  <div class="container">
    <div class=" row justify-content-center align-items-center " style="height: 100dvh; overflow: hidden;">
      <div class="row col-12 p-3 justify-content-center align-items-center">
        <div class="col-xl-5 col-lg-6 col-md-6 col-sm-10 bg-white rounded-lg">
          <div class="row justify-content-between align-items-center p-5">
            <div class="col-4">
              <img class="img-fluid" src="../../../../../assets/media/logos/uEngage-Logo.png" alt="uEngage-logo">
            </div>
            <div class="col-6 text-right">
              <p class="cursor-pointer mb-0 font-weight-bolder" (click)="signUp()">New User? <span
                  style="color: #8CC42A !important;" class="link-tag-secondary">Sign Up</span>
              </p>
            </div>
            <div class="col-12 mt-md-10 mt-3 mb-md-5 mb-3 ">
              <h3 class="text-black-secondary primary-heading font-weight-bolder ">
                👋Hey! Welcome
              </h3>

            </div>
            <div class="col-12">

              <form [formGroup]="loginForm" (ngSubmit)="checkInput()" autocomplete="on">
                <label class="mb-2">Login via Email or Mobile Number:</label>
                <div class="input-group input-group-solid p-1 border border-1" style="background: none;"
                  [ngClass]="{ 'border-danger': a.email?.errors && a.email?.touched}">

                  <input type="email" (blur)="checkInput()" class="form-control" placeholder="Email/ Mobile Number"
                    formControlName="email" />
                  <div class="input-group-append" *ngIf="mobileLogin==1 && !isOTPSend" style="cursor:pointer"
                    (click)="sendOTP()">
                    <span class="input-group-text"><button class="btn link-tag p-0">Send OTP</button></span>
                  </div>

                </div>
                <ng-container>
                  <div *ngIf="loginForm.get('email')?.errors && loginForm.get('email')?.touched">
                    <small class="text-danger" *ngIf="loginForm.get('email')?.errors?.required">Required.</small>
                    <small class="text-danger" *ngIf="loginForm.get('email')?.errors?.pattern">Input Invalid.</small>
                  </div>
                </ng-container>
                <input type="password" class="form-control" placeholder="Email/ Mobile Number" style="display: none;" />

              </form>
              <form [formGroup]="passwordForm" (ngSubmit)="submit()" autocomplete="on">
                <div class="mb-2">
                  <label class="mt-md-5 mt-3 mb-2">{{mobileLogin==1 ? 'Enter OTP' :'Enter Password'}}</label>

                  <ng-container *ngIf="mobileLogin==1">

                    <ng-otp-input id="otpWrapper" [formControlName]="password" (onInputChange)="onOtpChange($event)"
                      [config]="{ length: 6 }"></ng-otp-input>
                    <ng-container>
                      <div *ngIf="passwordForm.get('password')?.errors && passwordForm.get('password')?.touched">
                        <small class="text-danger"
                          *ngIf="passwordForm.get('password')?.errors?.required">Required.</small>

                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="mobileLogin==0">

                    <div class="input-group input-group-solid p-1  border border-1" style="background: none;"
                      [ngClass]="{ 'border-danger':  b.password?.errors && b.password?.touched }">
                      <!-- <div class="input-group-prepend">
                    <span class="input-group-text"><img src="../../../../../assets/media/misc/Password.png"
                        style="width: 2em;"></span>
                  </div> -->
                      <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" placeholder="Password"
                        formControlName="password" />
                      <div class="input-group-append" style="cursor:pointer" (click)="changePasswordVisibility()">
                        <span class="input-group-text"><i class="fa" [ngClass]="{
                          'fa-eye-slash': !fieldTextType,
                          'fa-eye': fieldTextType
                        }" style="color: #8CC42A;"></i></span>
                      </div>
                      <!-- <div *ngIf="submitted1 && b.password.errors" class="invalid-feedback">
                      <div *ngIf="b.password.errors.required">Field is required</div>
                    </div> -->
                    </div>
                    <ng-container>
                      <div *ngIf="passwordForm.get('password')?.errors && passwordForm.get('password')?.touched">
                        <small class="text-danger"
                          *ngIf="passwordForm.get('password')?.errors?.required">Required.</small>

                      </div>
                    </ng-container>
                  </ng-container>

                </div>
                <div class="row w-100 m-0">
                  <a class="btn btn-link-dark font-weight-bold" type="button" *ngIf="mobileLogin==0"
                    (click)="forget(otpModal)" style="cursor: pointer;font-weight: 600;color: #8CC42A;font-size: 0.9em;">FORGOT
                    PASSWORD?</a>
                  <span *ngIf="mobileLogin == 1"
                    [ngStyle]="{'visibility': mobileLogin == 1 && !isOTPSend ? 'hidden' : 'visible'}"
                    class="text-muted" style="font-size: 0.9em;">An OTP has been sent to the number
                    {{loginValue}}.
                    Please enter it to proceed <a class="btn btn-link-dark font-weight-bold" type="button"
                      (click)="getOtpAgain()" style="cursor: pointer;font-weight: 600;color: #8CC42A;">REQUEST
                      AGAIN</a></span>
                </div>

                <button type="submit" class="btn mt-5 font-weight-bolder mb-3 btn-basic-primary w-100"
                  [ngClass]="{'spinner spinner-right spinner-md spinner-light': loading , 'disablebutton': warningShow==1 }">LOGIN</button>


              </form>
              <div class="row justify-content-center align-items-center p-3">
                <div class=" col-9 my-md-5 my-3 border-bottom position-relative">
                  <span class="p-2 bg-white "
                    style="position: absolute; left: 47%; top: -15px; color: #bbbbbb;">or</span>
                </div>
              </div>

              <div class="social-media-block justify-content-center">
                <div class="google-block mr-3">
                  <a class="btn google-button" (click)="signInWithGoogle()"><img alt="Facebook Icon"
                      src="../../../../../assets/media/misc/Google.png">Google </a>
                </div>
                <div class="facebook-block">
                  <a class="btn google-button" (click)="signInWithFB()">
                    <img alt="Facebook Icon" src="../../../../../assets/media/prism-images/Group4399.png">
                    Facebook </a>
                </div>
              </div>

            </div>

          </div>
        </div>
        <div class="col-xl-5 col-lg-6 col-md-6 col-sm-10 px-md-0 py-md-5 py-0">
          <div class="p-md-7 pt-5 pb-3 px-3  border-class" style="background: #FFFFFF80;">
            <h2 class="font-weight-bolder secondary-heading text-center mb-md-3 mb-0">Revolutionizing Hyperlocal
              Businesses</h2>
            <owl-carousel-o [options]="customOptions">
              <ng-template carouselSlide>
                <div class="custom-carousel-images">
                  <img src="https://api.uengage.in/assets/uen_io/images/the-burger-club-nw.png"
                    style="  max-width: 100px;" />
                </div>
              </ng-template>

              <ng-template carouselSlide>
                <div class="custom-carousel-images mx-2">
                  <img src="https://api.uengage.in/assets/uen_io/images/the-obroma-nw.png"
                    style="  max-width: 100px;" />
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="custom-carousel-images mx-2">
                  <img src="https://api.uengage.in/assets/uen_io/images/tossin-nw.png" style="  max-width: 100px;" />
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="custom-carousel-images mx-2">
                  <img src="https://api.uengage.in/assets/uen_io/images/lapinoz-nw.png" style="  max-width: 100px;" />
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="custom-carousel-images mx-2">
                  <img src="https://api.uengage.in/assets/uen_io/images/burger-farm-nw.png"
                    style="  max-width: 100px;" />
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="custom-carousel-images">
                  <img src="https://api.uengage.in/assets/uen_io/images/thewholesale-nw.png"
                    style="  max-width: 100px;" />
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="custom-carousel-images">
                  <img src="https://api.uengage.in/assets/uen_io/images/1441-nw.png" style="  max-width: 100px;" />
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="custom-carousel-images">
                  <img src="https://api.uengage.in/assets/uen_io/images/chelvies-nw.png" style="  max-width: 100px;" />
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="custom-carousel-images">
                  <img src="https://api.uengage.in/assets/uen_io/images/u-campus.png" style="  max-width: 100px;" />
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="custom-carousel-images mx-2">
                  <img src="https://www.uengage.in/assets/uen_io/images/aasife-biryani.png"
                    style="  max-width: 100px;" />
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="custom-carousel-images">
                  <img src="https://api.uengage.in/assets/uen_io/images/canadian-pizza.png"
                    style="  max-width: 100px;" />
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="custom-carousel-images">
                  <img src="https://api.uengage.in/assets/uen_io/images/qicgo-nw.png" style="  max-width: 100px;" />
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="custom-carousel-images">
                  <img src="https://api.uengage.in/assets/uen_io/images/biryani-zone.png" style="  max-width: 100px;" />
                </div>
              </ng-template>
            </owl-carousel-o>
            <div class="row justify-content-center align-items-center">
              <h6 class="text-grey-color my-md-3 my-0 mb-3">Trusted by more than 10000+ Buisness</h6>
              <div class="col-xl-9 row justify-content-center align-items-center col-md-12 col-12 text-center">
                <div class="col-md-12 col-sm-6 col-6  p-1 ">
                  <div class="col-12 p-md-3 p-2 mb-md-3 mb-0  rounded-lg"
                    style="border: 1px solid #FFFFFF; background: #FFFFFF4D;">
                    <h3 class="product-title">
                      <img class="product-img" src="../../../../../assets/media/logos/Flash-icon.svg" alt="flash-icon">
                      uEngage <span class="font-weight-bolder">Flash</span>
                    </h3>
                    <p class="mb-0 d-md-block d-none product-subtitle">Complete delivery management
                      & order tracking solution</p>
                  </div>

                </div>
                <div class="col-md-12 col-sm-6 col-6  p-1 ">
                  <div class="col-12 p-md-3 p-2  rounded-lg mb-md-3 mb-0"
                    style="border: 1px solid #FFFFFF; background: #FFFFFF4D;">
                    <h3 class="product-title">
                      <img class="product-img" src="../../../../../assets/media/logos/Edge-icon.svg" alt="edge-icon">
                      uEngage <span class="font-weight-bolder">Edge</span>
                    </h3>
                    <p class="mb-0 d-md-block d-none product-subtitle">Zero commission ordering
                      Apps & Websites</p>
                  </div>

                </div>
                <div class="col-md-12 col-sm-6 col-6  p-1">
                  <div class="col-12 p-md-3 p-2  rounded-lg mb-md-3 mb-0"
                    style="border: 1px solid #FFFFFF; background: #FFFFFF4D;">
                    <h3 class="product-title">
                      <img class="product-img" src="../../../../../assets/media/logos/Prism-icon.svg" alt="prism-icon">
                      uEngage <span class="font-weight-bolder">Prism</span>
                    </h3>
                    <p class="mb-0 d-md-block d-none product-subtitle">Advanced customer marketing
                      and automation platform</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>





<ng-template #otpModal let-modal>
  <div class="modal-body" style="height: auto;">
    <div class="row w-100 m-0 justify-content-end">
      <i class="fas fa-times" (click)="modal.close()" style="cursor: pointer;"></i>
    </div>
    <div class="row w-100 m-0 justify-content-center">
      <i class="flaticon2-hourglass-1" style="font-size: 8em;color: #8CC42A;"></i>
    </div>
    <div class="row w-100 m-0 mt-5 justify-content-center" style="display: block;text-align: center;">
      <h3 class="font-weight-bold">Verification</h3>
      <span class="text-muted">We will send you a One Time Password on your email id</span>
    </div>
    <div class="row w-100 m-0 mt-10">
      <div class="input-group input-group-solid p-1">
        <div class="input-group-prepend"><span class="input-group-text"><img
              src="../../../../../assets/media/misc/mail-Icon.png" style="width: 2em;"></span>
        </div>
        <input type="text" class="form-control" placeholder="Email" [(ngModel)]="emailId" [readonly]="otpValidate==1" />
      </div>
      <div class="input-group input-group-solid p-1 mt-3" *ngIf="otpValidate==1">
        <div class="input-group-prepend"><span class="input-group-text"><img
              src="../../../../../assets/media/misc/OTP-Icon.png" style="width: 2em;"></span>
        </div>
        <input type="text" class="form-control" placeholder="OTP" [(ngModel)]="otp" />
      </div>
    </div>
    <div class="row w-100 m-0 mt-5">
      <button class="btn w-100" type="button" (click)="getOtp()" style="background-color: #8cc42a;color:white;"
        *ngIf="otpValidate!=1">GET OTP</button>
      <button class="btn w-100" type="button" (click)="verifyOtp()" style="background-color: #8cc42a;color:white;"
        *ngIf="otpValidate==1">VERIFY</button>
    </div>
    <div class="row w-100 m-0 mt-2 justify-content-center" *ngIf="otpValidate==1">
      <span class="text-muted">Didn't recieve the OTP? <a style="color:#8cc42a;cursor: pointer;" (click)="reqAgain()"
          class="font-weight-bold">Request again</a></span>
    </div>
  </div>
</ng-template>