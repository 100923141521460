import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import Swal from 'sweetalert2';

@Component({
  selector: 'kt-approval-pending',
  templateUrl: './approval-pending.component.html',
  styleUrls: ['./approval-pending.component.scss']
})
export class ApprovalPendingComponent implements OnInit {
  currentStatus: any;
  businessName: any;
  locality: any;
  city: any;
  userName: any;
  bId: any;
  avatar: string;
  logs: any[];
  comments = ''
  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService, public httpClient: HttpClient, private cd: ChangeDetectorRef, private router: Router, private modalService: NgbModal, private modalRef: NgbActiveModal,) { }

  ngOnInit(): void {
    // let selectedBusiness = this.storage.get("AssignedBusiness")
    let bDetails
    if (this.storage.get("onboardBusiness") != undefined && this.storage.get("onboardBusiness") != '' && this.storage.get("onboardBusiness") != null) {
      bDetails = this.storage.get("onboardBusiness")
    } else {
      bDetails = this.storage.get("AssignedBusiness");
    }
    // .filter((e) => e.businessId == this.storage.get("selectedBusinessId"));
    this.businessName = bDetails.name;
    this.locality = bDetails.locality;
    this.city = bDetails.city;
    this.bId = bDetails.businessId;
    this.avatar =
      "https://api.uengage.in/images/addo/logos/" + bDetails.logo;
    this.userName = this.storage.get("userName");
    this.getStatus()
  }
  getStatus() {
    let params = "userId=" + this.storage.get("userId") +
      "&password=" + this.storage.get("password") +
      "&businessId=" + this.bId;
    this.httpClient.get("https://api.uengage.in/onboarding/getApprovalStatus?" + params).subscribe((res: any) => {
      if (res.status == 1) {
        this.currentStatus = res.request_approval
        if (this.currentStatus == -2) {
          this.getRejectReason()
        }
        this.cd.detectChanges()
      }
    })
  }
  getRejectReason() {
    this.logs = []
    let params = "userId=" + this.storage.get("userId") +
      "&password=" + this.storage.get("password") +
      "&businessId=" + this.bId;
    this.httpClient.get("https://api.uengage.in/onboarding/getRejectedReason?" + params).subscribe((res: any) => {
      if (res.status == 1) {
        this.logs = res.logs
        this.cd.detectChanges()
      }
    })
  }
  public logout() {
    // this.storage.remove("userName");
    // this.storage.remove("password");
    // this.storage.remove("userId");
    // this.storage.remove("selectedBusinessId");
    let isBuzz = this.storage.get("isBuzz");
    let isGuide = this.storage.get("isGuide");
    localStorage.clear();
    this.storage.set("isBuzz", isBuzz);
    this.storage.set("isGuide", isGuide);
    let path = "";
    this.router.navigate([path]);
  }
  viewDetails() {
    this.router.navigateByUrl("/complete-edge-profile")
  }
  comment(modal) {
    this.open(modal)
  }
  open(content: any) {
    this.modalRef = this.modalService.open(content, {
      size: 'lg'
    });
  }
  submitApplication() {
    if (this.comments == '') {
      Swal.fire('', 'Enter comments!', 'error')
      return false
    } else {
      let params =
        "userId=" + this.storage.get("userId") +
        "&password=" + this.storage.get("password") +
        "&businessId=" + this.bId +
        "&comments=" + this.comments;
      this.httpClient.get("https://api.uengage.in/onboarding/completeOnboarding?" + params).subscribe((res: any) => {
        if (res.status == 1) {
          Swal.fire('', res.msg, 'success')
          setTimeout(function () {
            window.location.href = 'https://app.uengage.in/approval-pending';
            // this.router.navigateByUrl("/login");
          }, 2000)
        } else {
          Swal.fire('', res.msg, 'error')
        }
      })
    }
  }
}
