import { Injectable, Inject } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { mergeMapTo } from "rxjs/operators";
import { take } from "rxjs/operators";
// import { BehaviorSubject } from "rxjs";
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";
import { HttpClient } from "@angular/common/http";
import { SetStorageServiceService } from "./core/_base/layout/services/set-storage-service.service";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
// import { AsideLeftComponent } from "./views/theme/aside/aside-left.component";

declare var audio: any;

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  base_url = "https://api.uengage.in/";

  constructor(
    private angularFireDB: AngularFireDatabase,
    public httpClient: HttpClient,
    private angularFireAuth: AngularFireAuth,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private angularFireMessaging: AngularFireMessaging,
    private _storageService: SetStorageServiceService,
  ) {
    this.angularFireMessaging.messages.subscribe((_messaging:AngularFireMessaging) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(userId, token) {
    let params =
      "userId=" +
      this.storage.get("userId") +
      "&password=" +
      this.storage.get("password") +
      "&businessId=" +
      this.storage.get("selectedBusinessId") +
      "&deviceId=&fcmId=" +
      token +
      "&mobileNo=&os=web";
    this.httpClient
      .get(this.base_url + "notification/uengage?" + params, {})
      .subscribe((res: any) => { });
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.updateToken(userId, token);
        //this.storage.set("notificationPermission", "granted");
        this._storageService.notificationPermission = "granted";
      },
      (err) => {
        //this.storage.set("notificationPermission", "not-granted");
        this._storageService.notificationPermission = "not-granted";
        console.error("Enable to get permission to notify.", err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      var title = JSON.parse(payload.data.notification).title;
      var body = JSON.parse(payload.data.notification).body;
      var businessId = payload.data.businessId;
      var url = "https://app.uengage.in/Dashboard/onlineOrder/" + businessId;

      const notificationTitle = title;
      const notificationOptions = {
        body: body,
        click_action: url,
        icon: "assets/images/logo-sm.png",
        sound: "assets/audio/audio.mp3",
        requireInteraction: true,
      };

      navigator.serviceWorker.controller.postMessage("New Order Receive ");
      new Notification(notificationTitle, notificationOptions);
    });
  }

  playaudio() {
    let obj: any = document.getElementById("playAudio");
    obj.play();
  }
  // refresh(){
  //   ("hey")
  //   this.aside.ngOnInit()
  // }
}
